import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CaretUpIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M17.4,15c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.2,0.3-0.4,0.3c-0.3,0-0.5-0.1-0.5-0.4c-0.1-0.2-0.7-1-1.5-2
		c-0.2-0.2-0.4-0.5-0.6-0.8c-0.3-0.4-0.6-0.8-0.9-1.1c-0.5-0.6-1-1.1-1.4-1.4c-0.2,0.2-0.7,0.8-1.7,1.9c-0.3,0.3-1,1.3-1.6,2
		c-0.4,0.3-0.7,0.6-0.7,0.7c-0.2,0.3-0.4,0.5-0.6,0.7c-0.1,0.3-0.4,0.3-0.6,0.2s-0.3-0.4-0.2-0.7l0.1-0.1c0.1-0.2,0.3-0.4,0.5-0.7
		c0.1-0.1,0.3-0.4,0.6-0.8c0.6-0.7,1.3-1.7,1.6-2c2.1-2.5,2.2-2.5,2.5-2.5c0.1,0,0.2,0,0.3,0.1c0.6,0.5,1.2,1.1,1.9,1.8
		c0.3,0.3,0.6,0.8,0.9,1.2c0.2,0.3,0.4,0.6,0.6,0.8C17.1,14.2,17.4,14.7,17.4,15z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CaretUpIcon;
