import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const JoyIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12.1,9.4c0-1.7-2.4-5.3-4.4-7.2C7.7,2.1,7.6,2,7.6,2C7.3,1.7,7,1.5,6.7,1.3C6.5,1.2,6.2,1.2,6,1.4C5.9,1.6,5.9,1.7,6,1.9
		C5.9,2.1,5.9,2.4,6.1,2.5c0.3,0.2,0.6,0.3,0.9,0.4c1.8,1.8,4.1,5.3,4.1,6.5c0,0.1,0,0.1,0,0.2c0,2.1-0.9,5.1-1.9,5.3
		C8.7,15,7.9,14,7.1,12.2c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c-0.3-0.2-0.7-0.2-1-0.2c-0.1,0-0.2,0-0.3,0
		c-0.3,0-0.5,0.2-0.5,0.4c0,0.3,0.2,0.5,0.4,0.5c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.4,0c0.9,2.1,1.9,3.1,2.9,3.1c0.1,0,0.2,0,0.3,0
		c1.9-0.4,2.7-4.6,2.7-6.3C12.2,9.5,12.1,9.4,12.1,9.4z"
      fill="currentColor"
    />
    <path
      d="M18.5,1.1c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.3-0.2-0.5-0.2c-0.3,0.1-0.4,0.3-0.3,0.6c0,0.3-0.4,1.4-0.9,2.6c0-0.1,0-0.1,0-0.2
		c-0.3-1-0.8-1.6-1.5-1.9c-0.8-0.3-1.7-0.1-2.7,0.5c-0.2,0.1-0.3,0.4-0.2,0.7c0.1,0.2,0.4,0.3,0.7,0.2c0.4-0.3,1.2-0.7,1.8-0.4
		c0.4,0.2,0.7,0.6,0.9,1.3c0.2,0.8,0.1,1.5-0.4,2c-0.5,0.5-1.3,0.7-2,0.5c-0.5-0.1-1.3-0.5-1.6-1.8c-0.1-0.3-0.3-0.4-0.6-0.3
		c-0.3,0.1-0.4,0.3-0.3,0.6C11,6.4,11.9,7.3,13,7.6c0.3,0.1,0.5,0.1,0.8,0.1c0.4,0,0.8-0.1,1.2-0.3c-0.2,0.5-0.4,1-0.5,1.3
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,2.4-0.6,4-1.3,5.6c-0.8,1.9-1.7,4.1-1.7,7.9c0,0.2,0.1,0.4,0.3,0.5
		c0,0,0,0,0,0l0.7,0.2c0.1,0,0.1,0,0.2,0c0.2,0,0.4-0.1,0.5-0.3c0.1-0.3-0.1-0.5-0.3-0.6l-0.4-0.1c0.1-3.4,0.8-5.3,1.6-7.2
		c0.7-1.7,1.4-3.3,1.4-5.9c0.2-0.5,0.6-1.5,1.1-2.5c0.8-1.8,1.4-3,1.7-3.8c0.1,0,0.2-0.1,0.2-0.2l0.1-0.1c0.2-0.2,0.3-0.5,0.4-0.8
		C18.9,1.4,18.7,1.1,18.5,1.1z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default JoyIcon;
