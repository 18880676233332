import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import useTitle from 'react-use/lib/useTitle';
import Paper from '@mui/material/Paper';

import PageBox from 'src/common/components/PageBox';
import AppBar from 'src/common/components/styled/AppBar';
import ProfileEditorForm from './forms/ProfileEditorForm';

const Profile = () => {
  const { t } = useTranslation();

  useTitle(`${t('profile.pageTitle')} - ${t('base.defaultTitle')}`);

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar
          component={Paper}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(3px)',
            mb: 2,
            mt: 2,
            p: 1,
            justifyContent: 'space-between',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" component="div" sx={{ mr: 1 }}>
              {t('profile.pageTitle')}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <PageBox>
        <ProfileEditorForm />
      </PageBox>
    </>
  );
};

export default Profile;
