import { MouseEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { find, map } from 'lodash-es';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useTitle from 'react-use/lib/useTitle';

import { useAuth } from 'src/auth/useAuth';
import PageBox from 'src/common/components/PageBox';
import TechnicalTypeCard from 'src/common/components/TechnicalTypeCard';
import ViewSwitch from 'src/common/components/ViewSwitch';
import AppBar from 'src/common/components/styled/AppBar';
import { PERSISTENT_KEY_VIEW } from 'src/common/constants/persistentStorageKeys';
import { FormTechnicalType } from 'src/common/forms/TechnicalTypeEditorForm';
import TechnicalTypeEditorModal from 'src/common/modals/TechnicalTypeEditorModal';
import TechnicalTypesTable from 'src/common/tables/TechnicalTypesTable';
import { TechnicalType } from 'src/interfaces/TechnicalType';
import { GRID_VIEW, TABLE_VIEW, View } from 'src/interfaces/View';
import systemOptionsStore from 'src/stores/systemOptionsStore';

const Competitions = () => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(`${t('system.competitions.pageTitle')} - ${t('base.defaultTitle')}`);

  const {
    competitions,
    deleteCompetition: doDeleteCompetition,
    deleteAllCompetitions: doDeleteAllCompetitions,
    fetchCompetitions: doFetchCompetitions,
    isLoading,
    saveCompetition: doSaveCompetition,
  } = systemOptionsStore(systemOptionsState => systemOptionsState);
  const { isSuperAdmin } = useAuth();

  const lsView = localStorage.getItem(PERSISTENT_KEY_VIEW);
  const [view, setView] = useState<View>((lsView as View) || GRID_VIEW);
  const [competitionEditorModalOpen, setCompetitionEditorModalOpen] = useState(false);
  const [selectedCompetition, setSelectedCompetition] = useState<TechnicalType | undefined>(
    undefined,
  );

  useEffect(() => {
    if (view) {
      localStorage.setItem(PERSISTENT_KEY_VIEW, view);
    } else {
      localStorage.removeItem(PERSISTENT_KEY_VIEW);
    }
  }, [view]);

  const handleOpenCompetitionEditorModal = (id?: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    const thisTrack = find(competitions, { id });
    setSelectedCompetition(thisTrack);
    setCompetitionEditorModalOpen(true);
  };

  const handleCloseCompetitionsEditorModal = () => {
    setSelectedCompetition(undefined);
    setCompetitionEditorModalOpen(false);
  };

  const handleDeleteAllCompetitions = async (e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('system.competitions.deleteAllConfirm'),
        description: t('system.competitions.deleteDescription'),
        confirmationText: t('system.competitions.confirmationText'),
        cancellationText: t('system.competitions.cancellationText'),
      });
      await doDeleteAllCompetitions()
        .then(() => {
          enqueueSnackbar(t('system.competitions.deleteSuccessfulMessage'), {
            variant: 'success',
          });
        })
        .catch(
          ({
            response: {
              data: { errorCode, message },
            },
          }) => {
            enqueueSnackbar(errorCode ? t(`competitions.errors.${errorCode}`) : message, {
              variant: 'error',
            });
          },
        );
    } catch (error) {
      enqueueSnackbar(t('system.competitions.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const handleDeleteCompetition = async (id: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('system.competitions.deleteConfirm'),
        description: t('system.competitions.deleteDescription'),
        confirmationText: t('system.competitions.confirmationText'),
        cancellationText: t('system.competitions.cancellationText'),
      });
      await doDeleteCompetition(id);

      enqueueSnackbar(t('system.competitions.deleteSuccessfulMessage'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('system.competitions.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const saveCompetition = async (competition: FormTechnicalType, id?: string) => {
    await doSaveCompetition(competition, id);
    doFetchCompetitions();
    enqueueSnackbar(t('system.competitions.saveCompetitionSuccess'), {
      variant: 'success',
    });
    handleCloseCompetitionsEditorModal();
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar
          component={Paper}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(3px)',
            mb: 2,
            mt: 2,
            p: 1,
            justifyContent: 'space-between',
          }}
        >
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs="auto">
              <Typography variant="h6" component="div" sx={{ mr: 1 }}>
                {t('system.competitions.pageTitle')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenCompetitionEditorModal()}
                startIcon={<AddIcon />}
              >
                {t('system.competitions.new')}
              </Button>
              {isSuperAdmin ? (
                <Tooltip title={t('system.competitions.deleteAllCompetitions')}>
                  <IconButton onClick={handleDeleteAllCompetitions} color="error" sx={{ ml: 1 }}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <ViewSwitch view={view} onSetView={setView} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <PageBox isLoading={isLoading}>
        {!!competitions.length && (
          <>
            {view === GRID_VIEW && (
              <Grid container spacing={2}>
                {map(competitions, competition => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={competition.id}>
                    <TechnicalTypeCard
                      item={competition}
                      onDelete={handleDeleteCompetition}
                      onEdit={handleOpenCompetitionEditorModal}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {view === TABLE_VIEW && (
              <TechnicalTypesTable
                items={competitions}
                onDelete={handleDeleteCompetition}
                onEdit={handleOpenCompetitionEditorModal}
              />
            )}
          </>
        )}
      </PageBox>

      {competitionEditorModalOpen && (
        <TechnicalTypeEditorModal
          id="competition-editor"
          open={competitionEditorModalOpen}
          handleClose={handleCloseCompetitionsEditorModal}
          handleSave={saveCompetition}
          item={selectedCompetition}
          withIcon
        />
      )}
    </>
  );
};

export default Competitions;
