import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LocationIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12.3,17.9c-0.1,0-0.2,0-0.3-0.1c-2.2-2.1-4.7-7.4-3.8-11c0.5-1.8,1.7-2.9,3.6-3.4c1.5-0.4,2.8,0,3.8,1.1
	c1.3,1.4,1.9,3.7,1.4,5.6c-0.5,1.6-1.4,3.1-2.3,4.6c-0.5,0.8-1,1.6-1.4,2.4c-0.1,0.2-0.3,0.3-0.5,0.2c0.1,0.2,0,0.4-0.1,0.5
	C12.6,17.9,12.5,17.9,12.3,17.9z M12.9,4.3c-0.3,0-0.5,0-0.8,0.1c-1.5,0.4-2.5,1.3-2.9,2.7c-0.8,3.1,1.4,7.8,3.3,9.8
	c0-0.1,0-0.2,0-0.3c0.4-0.8,0.9-1.7,1.4-2.4c0.9-1.4,1.8-2.8,2.2-4.3c0.4-1.6,0-3.6-1.1-4.7C14.5,4.7,13.8,4.3,12.9,4.3z"
      fill="currentColor"
    />
    <path
      d="M12.4,10.7c-0.2,0-0.3,0-0.5-0.1c-1.1-0.3-1.2-1-1.2-1.7c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5c0,0.5,0.1,0.6,0.5,0.7
	c0.6,0.2,0.9-0.2,1.1-0.5c0.2-0.4,0.2-1,0-1.2c-0.3-0.3-0.9-0.1-1.6,0.3c-0.2,0.1-0.5,0.1-0.7-0.2c-0.1-0.2-0.1-0.5,0.2-0.7
	c1.6-1,2.4-0.5,2.8-0.2c0.6,0.5,0.6,1.6,0.2,2.4C13.7,10.4,13.1,10.7,12.4,10.7z"
      fill="currentColor"
    />
    <path
      d="M12,20.7c-2.1,0-4.2-0.3-5.2-0.9c-0.8-0.4-1-1-0.9-1.3c0.1-0.9,1.1-1.6,3.3-2.4c0.3-0.1,0.5,0,0.6,0.3s0,0.5-0.3,0.6
	c-2.6,0.9-2.7,1.6-2.7,1.6c0,0.1,0.1,0.2,0.4,0.4c2,1,7.7,1.1,9.4-0.2c0.5-0.4,0.8-0.8,0.7-1C17.1,17.4,16,17,15,17
	c-0.3,0-0.5-0.2-0.5-0.5S14.7,16,15,16c0.9,0,2.8,0.3,3.2,1.5c0.2,0.5,0.1,1.2-1,2.1C16.1,20.3,14.1,20.7,12,20.7z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default LocationIcon;
