import { AxiosResponse } from 'axios';
import groupBy from 'lodash-es/groupBy';
import mapKeys from 'lodash-es/mapKeys';
import mapValues from 'lodash-es/mapValues';
import size from 'lodash-es/size';
import sortBy from 'lodash-es/sortBy';
import sumBy from 'lodash-es/sumBy';
import { create } from 'zustand';

import { SECURE_API } from 'src/api/api';
import { groupByMultipleKeys } from 'src/helpers/groupByMultipleKeys';
import {
  IAnonymizedCheckin,
  IBulkCheckinTimer,
  ICheckin,
  ICheckinTimer,
  IResetCheckin,
} from 'src/interfaces/Checkin';
import { AgeGroups, DataByGenderByAge, Statistics } from 'src/interfaces/Statistics';
import { IFormCheckin } from 'src/pages/Dashboard/Applicants/forms/CheckinForm';

const groupByCompetitionAndDistance = groupByMultipleKeys<IAnonymizedCheckin>(
  ['competition', 'distance'],
  ':',
);

export interface IApplicantsState {
  checkins: ICheckin[];
  checkinsById?: { [key: string]: ICheckin };
  deleteCheckin: (id: string) => Promise<void | AxiosResponse<null>>;
  fetchCheckins: () => Promise<void | AxiosResponse<ICheckin[]>>;
  fetchCheckinsByCompetitionId: (
    year: string,
    id: string,
  ) => Promise<void | AxiosResponse<ICheckin[]>>;
  fetchStatistics: (editionId: string) => Promise<{
    checkinsByPrice?: {
      [key: string]: number;
    };
    statistics?: Statistics;
    totalPrice?: number;
  }>;
  handleBulkTimer: (data: IBulkCheckinTimer) => Promise<void | AxiosResponse<unknown>>;
  handleTimer: (data: ICheckinTimer) => Promise<void | AxiosResponse<null>>;
  isLoadingCheckins: boolean;
  resetCheckins: () => void;
  resetCounter: (data: IResetCheckin) => Promise<void | AxiosResponse<null>>;
  saveCheckin: (checkin: IFormCheckin, id?: string) => Promise<void | ICheckin>;
}

const useCheckinsStore = create<IApplicantsState>(set => {
  const getCheckins = async () => {
    return SECURE_API.get<ICheckin[]>(`checkins/`)
      .then(({ data }) => {
        set({
          checkins: data,
          checkinsById: mapKeys(data, 'id'),
          isLoadingCheckins: false,
        });
      })
      .catch((error: string) => {
        set({
          checkins: [],
          checkinsById: undefined,
          isLoadingCheckins: false,
        });
        console.log({ error });
      });
  };

  const getCheckinsByCompetitionId = async (editionId: string, competitionId: string) => {
    return SECURE_API.get<ICheckin[]>(`checkins/edition/${editionId}/competition/${competitionId}`)
      .then(({ data }) => {
        set({ checkins: data, isLoadingCheckins: false });
      })
      .catch((error: string) => {
        set({ checkins: [], isLoadingCheckins: false });
        console.log({ error });
      });
  };

  return {
    checkins: [],
    deleteCheckin: async id => {
      set({ isLoadingCheckins: true });
      let editionId: string | undefined;
      let competitionId: string | undefined;
      return SECURE_API.delete<
        number,
        AxiosResponse<{ editionId?: string; competitionId?: string; message: string }>
      >(`checkins/${id}`)
        .then(({ data }) => {
          editionId = data.editionId;
          competitionId = data.competitionId;
        })
        .finally(() => {
          if (editionId && competitionId) {
            getCheckinsByCompetitionId(editionId, competitionId);
          } else getCheckins();
        });
    },
    fetchCheckins: async () => {
      set({ isLoadingCheckins: true });
      return getCheckins();
    },
    fetchCheckinsByCompetitionId: async (editionId: string, competitionId: string) => {
      set({ isLoadingCheckins: true });
      getCheckinsByCompetitionId(editionId, competitionId);
    },
    fetchStatistics: async (editionId: string) => {
      set({ isLoadingCheckins: true });
      const { data: anonymizedCheckins } = await SECURE_API.get<IAnonymizedCheckin[]>(
        `checkins/results/${editionId}`,
      );

      const checkinsByPrice = Object.entries(groupBy(anonymizedCheckins, ac => ac.price)).reduce(
        (acc, [price, checkins]) => {
          return {
            ...acc,
            [price]: checkins.length,
          };
        },
        {},
      );

      const totalPrice = anonymizedCheckins.reduce((acc, curr) => acc + curr.price, 0);

      const anonymizedCheckinsByCompetitionAndDistance =
        groupByCompetitionAndDistance(anonymizedCheckins);

      const statistics = Object.entries(anonymizedCheckinsByCompetitionAndDistance).reduce(
        (acc, [key, checkins]) => {
          const checkinsByGender = groupBy(checkins, c => c.gender);

          const winnersByGender = mapValues(checkinsByGender, c =>{
            const sortedByDuration = sortBy(c, 'duration');

            // Initialize arrays to store winners for each place
            const firstPlace = [];
            const secondPlace = [];
            const thirdPlace = [];

            // Iterate through the sorted array and determine winners
            for (const obj of sortedByDuration) {
              if (obj.duration === sortedByDuration[0].duration) {
                if (obj.duration) firstPlace.push(obj);
              } else if (obj.duration === sortedByDuration[firstPlace.length].duration) {
                if (obj.duration) secondPlace.push(obj);
              } else if (
                obj.duration ===
                sortedByDuration[firstPlace.length + secondPlace.length].duration
              ) {
                if (obj.duration) thirdPlace.push(obj);
              } else {
                // Stop once we have determined the winners for each place
                break;
              }
            }
            return {
              firstPlace,
              secondPlace,
              thirdPlace,
            };
          });

          const checkinsByGenderByAge = mapValues(checkinsByGender, c => groupBy(c, 'age'));
          const checkinsByGenderByAgeKeys = Object.keys(checkinsByGenderByAge).sort();

          const dataByGender = checkinsByGenderByAgeKeys.reduce((acc, gender) => {
            return {
              ...acc,
              [gender]: {
                ratio: checkinsByGender[gender].length,
                totalTime: sumBy(checkinsByGender[gender], 'duration'),
                averageTime:
                  sumBy(checkinsByGender[gender], 'duration') / size(checkinsByGender[gender]),
              },
            };
          }, {});

          const dataByGenderByAge = checkinsByGenderByAgeKeys.reduce((acc, gender) => {
            return {
              ...acc,
              [gender]: Object.values(AgeGroups).reduce((acc, age) => {
                const theseCheckins = checkinsByGenderByAge[gender][age];
                return {
                  ...acc,
                  [age]: {
                    bestTime: sortBy(theseCheckins, 'duration')[0]?.duration || 0,
                    averageTime: sumBy(theseCheckins, 'duration') / size(theseCheckins) || 0,
                    noOfApplicants: theseCheckins?.length || 0,
                  },
                };
              }, {} as DataByGenderByAge),
            };
          }, {});

          return {
            ...acc,
            [key]: {
              dataByGender,
              checkinsByGenderByAgeKeys,
              dataByGenderByAge,
              winnersByGender,
            },
          };
        },
        {},
      );

      set({ isLoadingCheckins: false });

      return {
        totalPrice,
        checkinsByPrice,
        statistics,
      };
    },
    handleBulkTimer: async ({ id, distanceId, editionId, ...checkinData }: IBulkCheckinTimer) => {
      set({ isLoadingCheckins: true });
      return SECURE_API.put<unknown>(`checkins/handleTimer/bulk/${id}/${distanceId}`, checkinData)
        .then(() => {
          set({ checkins: [] });
        })
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getCheckinsByCompetitionId(editionId, id);
        });
    },
    handleTimer: async ({ id, ...checkinData }: ICheckinTimer) => {
      set({ isLoadingCheckins: true });
      let editionId: string | undefined;
      let competitionId: string | undefined;
      return SECURE_API.put<ICheckin>(`checkins/handleTimer/${id}`, checkinData)
        .then(({ data }) => {
          editionId = data.edition;
          competitionId = data.competition;
          set({ checkins: [] });
        })
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          if (editionId && competitionId) {
            getCheckinsByCompetitionId(editionId, competitionId);
          } else getCheckins();
        });
    },
    isLoadingCheckins: false,
    resetCheckins: () => set({ checkins: [] }),
    resetCounter: async (data: IResetCheckin) => {
      set({ isLoadingCheckins: true });
      return SECURE_API.put<null>(`checkins/resetCounter`, data)
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          set({ isLoadingCheckins: false });
        });
    },
    saveCheckin: async (checkin: IFormCheckin, id?: string) => {
      set({ isLoadingCheckins: true });
      let editionId: string | undefined;
      let competitionId: string | undefined;
      const saveCheckinPromise = id
        ? SECURE_API.put<ICheckin>(`checkins/${id}`, checkin)
        : SECURE_API.post<ICheckin>(`checkins`, checkin);
      return saveCheckinPromise
        .then(({ data }) => {
          editionId = data.edition;
          competitionId = data.competition;
          return data;
        })
        .finally(() => {
          if (editionId && competitionId) {
            getCheckinsByCompetitionId(editionId, competitionId);
          } else getCheckins();
        });
    },
  };
});

export default useCheckinsStore;
