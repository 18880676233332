import { Gender } from 'src/common/constants/genders';
import { IAnonymizedCheckin } from 'src/interfaces/Checkin';

export enum AgeGroups {
  ten = '0-10',
  twenty = '11-20',
  thirty = '21-30',
  forty = '31-40',
  fifty = '41-50',
  sixty = '51-60',
  seventy = '61-70',
  seventyPlus = '70+',
}

export type WinnersByGender = {
  [key in Gender]: {
    firstPlace: IAnonymizedCheckin[],
    secondPlace: IAnonymizedCheckin[],
    thirdPlace: IAnonymizedCheckin[],
  };
};

export type DataByGender = {
  [key in Gender]: {
    ratio: number;
    totalTime: number;
    averageTime: number;
  };
};

export type DataByGenderByAge = {
  [key in Gender]: {
    [key in AgeGroups]: {
      bestTime: number;
      averageTime: number;
      noOfApplicants: number;
    };
  };
};

export type Statistic = {
  dataByGender: DataByGender;
  checkinsByGenderByAgeKeys: Gender[];
  winnersByGender: WinnersByGender;
  dataByGenderByAge: DataByGenderByAge;
};

export type Statistics = {
  [key: string]: Statistic;
};
