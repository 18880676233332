import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BookIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <g>
      <path
        d="M50.7,21.2c0-0.7-0.6-1.2-1.3-1.2c-0.3,0-0.6,0.2-0.8,0.4c0-0.6,0-1.2,0.1-1.8c0-0.6-0.4-1.1-1-1.2
	c-0.1-0.1-0.3-0.2-0.5-0.2c-5.2-1.2-10.7,0.3-15.9,4.6c-0.1,0.1-0.2,0.2-0.3,0.4c-4.8-2.9-11-3.2-16.6-0.5c-0.5,0.2-0.8,0.8-0.7,1.3
	c-0.4,0.3-0.7,0.9-0.5,1.4c1.2,3.5,1.8,7.3,2.4,11c0.4,2.3,0.8,4.7,1.3,7c0.1,0.5,0.5,0.9,1,1c0,0,0,0,0,0c0.2,0.4,0.5,0.6,0.8,0.7
	c-0.2,0.3-0.3,0.8-0.1,1.1c0.3,0.7,1,1,1.7,0.7c3.3-1.3,8.2-1.8,11,1.2c0.3,0.3,0.6,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.3
	c0.1,0,0.1-0.1,0.1-0.2c0.2-0.1,0.3-0.2,0.5-0.4c2-2.5,5-4.1,7.8-4.2c2.2-0.1,4,0.7,5.5,2.3c0.5,0.5,1.3,0.5,1.8,0.1
	c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0.7-0.1,1.2-0.7,1.2-1.4c-0.3-3.8-0.1-7.7,0.1-11.5C50.7,28.3,50.9,24.7,50.7,21.2z
	 M47.7,41.5c0,0.1,0,0.2,0,0.2c0,0,0,0-0.1-0.1C47.7,41.6,47.7,41.6,47.7,41.5z M32.9,23.6c3.1-2.5,7.8-5.2,13.1-4.2
	c-0.1,3-0.2,6-0.4,9c-0.2,3.5-0.4,7.1-0.4,10.7c-3.6-1.1-8.1-0.1-13.6,3.1c0-2.4,0.1-4.8,0.3-7.1c0.2-3.6,0.4-7.4,0.2-11.1
	c0,0,0,0,0,0C32.4,23.9,32.7,23.8,32.9,23.6z M15.8,23.7c4.7-2.1,9.8-1.9,13.8,0.5c0.2,3.6,0,7.2-0.2,10.8c-0.1,2.2-0.2,4.5-0.3,6.7
	c-2.9-1.6-6.3-1.8-9.7-0.5c-0.5-2.1-0.8-4.3-1.2-6.4C17.6,31.1,17,27.3,15.8,23.7z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default BookIcon;
