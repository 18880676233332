import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ThoughtIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <g>
      <path
        d="M58.8,31.3c-0.3-0.7-0.9-1.2-1.6-1.5c0-0.1,0.1-0.2,0.1-0.3c0.9-2.2,0.8-6.2-1.4-9.6c-2-3.1-5.8-3.3-8.4-3.3
		c-0.5,0-1.1,0-1.5-0.1C44.3,9.3,40,8.4,34.9,8c-3.7-0.3-7.1,1.4-10.2,5.1c-4-0.4-7-0.1-9.1,0.9c-1.5,0.7-3,2.4-2,6.1
		c-3-0.5-4.9,0.8-5.9,2.1c-1.3,1.6-2.2,4.5,0.9,8.7c0,0,0,0,0,0c-0.5-0.5-1.3-0.6-1.8-0.2c-1.3,1.1-1.9,2.7-1.5,4.3
		c0.5,2.1,2.5,3.9,5.2,4.5c2.3,0.6,4.4,0.4,6.1-0.5c1.1,3.2,3.6,5,7.5,5.3c0.2,0,0.4,0,0.6,0c3.8,0,6.9-2.3,8.9-3.8
		c0.5-0.4,1.2-0.9,1.5-1.1c2.3,1,8.3,3.7,14.3,1.9c3.2-0.9,7.7-4.9,8.8-6.6C59,33.5,59.2,32.3,58.8,31.3z M56,33.4
		c-0.8,1.2-4.7,4.8-7.3,5.5c-4.5,1.3-8.9-0.2-12.3-1.7c-1.2-1.1-2.8,0.1-4.3,1.2c-2,1.5-4.7,3.5-7.8,3.3c-3.3-0.2-5.1-1.8-5.6-5
		c-0.1-0.5-0.4-0.9-0.8-1c-0.1-0.1-0.3-0.1-0.4-0.1c-0.3,0-0.6,0.1-0.9,0.3c-1.4,1.3-3.2,1.6-5.4,1.1c-2.1-0.5-3.1-1.7-3.3-2.6
		c-0.1-0.6,0.1-1.2,0.7-1.7c0.5-0.4,0.6-1.1,0.3-1.7c0.1,0.1,0.2,0.2,0.3,0.4c0.4,0.6,1.2,0.7,1.8,0.2c0.6-0.4,0.7-1.2,0.2-1.8
		c-3-3.9-1.7-5.5-1.2-6.1c0.7-0.9,2.1-2,5.5-0.6c0.5,0.2,1,0.1,1.4-0.3c0.4-0.4,0.5-0.9,0.2-1.4c-1.3-2.9-1.4-4.6-0.2-5.2
		c1.7-0.8,4.6-1,8.4-0.6c0.4,0,0.9-0.1,1.1-0.5c2.7-3.4,5.4-4.9,8.4-4.7c4.7,0.4,7.7,1,9,7c0,0.1,0,0.1,0.1,0.2
		c0.5,1.2,1.9,1.3,3.7,1.3c2.2,0.1,5,0.2,6.3,2.1c1.5,2.4,1.8,5.4,1.3,7c-0.2,0.5-0.4,0.7-0.4,0.7c-0.9,0.2-1.4,0.7-1.4,1.4
		c0,1.1,1.1,1.3,1.5,1.3c1.4,0.2,1.7,0.5,1.7,0.6C56.5,32.4,56.4,32.7,56,33.4z"
        fill="currentColor"
      />
      <path
        d="M15.1,42.7c-0.3-0.1-1.1-0.4-1.8-0.3l-0.2,0c-0.2,0-0.3,0-0.5,0.1c-0.8,0.2-1.4,0.7-1.7,1.4c-0.1,0.2-0.1,0.4-0.1,0.6
		c-0.2,0.3-0.2,0.6-0.3,0.7c-0.2,0.8,0,1.4,0.2,1.9c0.2,0.7,0.9,1.2,1,1.3c0.5,0.4,1.1,0.5,1.8,0.5c0.1,0,0.3,0,0.4,0
		c1.1-0.1,2.4-1,2.7-2.5c0-0.2,0.1-0.4,0.1-0.6c0-0.6-0.2-1.2-0.3-1.6C16.3,43.9,15.9,43.1,15.1,42.7z M14.2,45.8c0,0.1,0,0.1,0,0.2
		c-0.1,0.4-0.4,0.4-0.4,0.4c-0.3,0-0.4,0-0.5-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0-0.1-0.1-0.3-0.1-0.3l0-0.1
		c0.1-0.2,0.2-0.5,0.2-0.8c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0l0.1,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0.1,0.1,0.2
		C14.2,45.5,14.2,45.6,14.2,45.8z"
        fill="currentColor"
      />
      <path
        d="M10.8,50.9c-0.3-0.1-0.9-0.4-1.5-0.2l-0.1,0c-0.1,0-0.2,0-0.4,0.1c-0.6,0.2-1.2,0.6-1.4,1.2c-0.1,0.2-0.1,0.3-0.1,0.5
		c-0.1,0.2-0.2,0.4-0.2,0.5c-0.2,0.6,0,1.2,0.1,1.5c0.2,0.6,0.7,1,0.9,1.1c0.4,0.3,0.9,0.5,1.5,0.5c0.1,0,0.2,0,0.3,0
		c0.9-0.1,1.9-0.8,2.2-2.1c0-0.2,0.1-0.3,0.1-0.4c0-0.5-0.1-1-0.2-1.3C11.7,51.8,11.4,51.2,10.8,50.9z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default ThoughtIcon;
