import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HomeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <g>
      <path
        d="M46.5,26.4c-0.1-0.5-0.5-0.8-1-0.9c-0.3-0.2-0.5-0.5-0.8-0.7c-0.7-0.6-1.5-1.3-2.3-2c-1.1-1-2.3-2-3.3-2.8
	c-2.3-1.8-4.5-3.3-6.6-4.4c-0.2-0.1-0.4-0.1-0.6-0.1c-0.6,0-1.1,0-8.3,5.7l-0.2,0.1c-0.8,0.6-2.8,2.4-4.6,4
	c-0.4,0.1-0.7,0.4-0.9,0.8c-0.1,0.1-0.1,0.1-0.2,0.2c-1,0.9-1.9,1.6-2.1,1.9c-0.4,0.4-0.9,0.8-1.3,1.1l-0.6,0.6
	c-0.1,0.1-0.2,0.1-0.2,0.2c-0.5,0.5-0.5,1.3,0,1.8c0.5,0.5,1.3,0.5,1.8,0c0,0,0.1-0.1,0.1-0.1l0.6-0.5c0.4-0.4,0.9-0.7,1.3-1.1
	c0.1-0.1,0.4-0.3,0.7-0.6c0,0.7,0,1.4,0,1.9c0,0.3,0,0.8,0.1,1.6c0.1,3.2,0.5,9.8-0.2,13.6c-0.1,0.7,0.4,1.4,1.1,1.5
	c0.1,0,0.1,0,0.2,0c0.6,0,1.2-0.4,1.3-1.1c0.7-4.1,0.4-10.9,0.2-14.2c0-0.7-0.1-1.3-0.1-1.5c0-0.7,0-1.8-0.1-2.8c0-0.5,0-0.9,0-1.3
	c1.8-1.6,3.9-3.4,4.6-4l0.2-0.1c4.3-3.4,6-4.6,6.7-5c1.8,1,3.7,2.3,5.6,3.8c1,0.8,2.1,1.8,3.2,2.7c0.8,0.7,1.6,1.4,2.3,2
	c0.4,0.3,0.7,0.6,1,0.9c0,0.8-0.1,1.7-0.1,2.5c0,1-0.1,1.9-0.1,2.6c0,0.7,0.1,2.9,0.1,5c0,2,0.1,4.1,0.1,4.7v4.7
	c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-4.7c0-0.6,0-2.7-0.1-4.8c-0.1-2.1-0.1-4.3-0.1-5c0-0.6,0-1.5,0.1-2.4c0-0.1,0-0.3,0-0.4
	c0.9,0.8,1.5,1.5,1.8,1.8c0.2,0.5,0.7,0.8,1.2,0.8c0,0,0.1,0,0.1,0c0.5,0,1-0.4,1.1-0.9C50.9,30.9,51.1,30.4,46.5,26.4z"
        fill="currentColor"
      />
      <path
        d="M31.4,39.1c0-0.5,0-2.1-1.5-2.1h-4.3c-0.4,0-0.8,0.2-1,0.5c-0.5,0.6-0.7,1.4-0.4,5.3c0.1,1.3,0.1,2.7,0,4.2
	c0,0.7,0.5,1.3,1.2,1.4c0.7,0,1.3-0.5,1.4-1.2c0.1-1.6,0.1-3.1,0-4.5c-0.1-1.5-0.1-2.5-0.1-3h2.3c0,0.2,0,0.3,0,0.5
	c0.1,3.9,0.1,6.1,0,6.5c-0.2,0.7,0.1,1.4,0.8,1.6c0.1,0.1,0.3,0.1,0.4,0.1c0.5,0,1-0.3,1.2-0.8c0.2-0.6,0.3-1.9,0.1-7.5
	C31.4,39.5,31.4,39.2,31.4,39.1z"
        fill="currentColor"
      />
      <path
        d="M37.8,31.8l-0.2,0c-0.8,0.1-1.6,0.1-2.5,0c-0.7-0.1-1.3,0.5-1.4,1.2c0,0.1,0,0.2,0,0.3c-0.3,0.2-0.5,0.6-0.5,1l0,0.9
	c-0.1,2.1,0.1,3.3,1,3.9c0.3,0.2,0.7,0.3,2.1,0.3c0.7,0,1.7,0,3.1-0.1c0.3,0,0.5,0,0.5,0c1.6,0,1.8-1.1,1.8-1.5v-4.5
	c0-0.4-0.2-0.8-0.5-1.1C40.6,31.5,39.7,31.6,37.8,31.8z M39.2,36.8c-1.6,0.1-2.8,0.1-3.4,0.1c0-0.3,0-0.8,0-1.5l0-0.8
	c0.7,0,1.4,0,2.1-0.1l0.2,0c0.3,0,0.8-0.1,1.1-0.1V36.8z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default HomeIcon;
