import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectSchema, object, string } from 'yup';

import TextField from '@mui/material/TextField';
import { disableAutofillBg } from 'src/common/components/constants/disableAutofillBg';
import { ILoginUser } from 'src/interfaces/User';

const signInSchema: ObjectSchema<ILoginUser> = object()
  .shape({
    email: string().email().required(),
    password: string().required(),
  })
  .required();

interface SignInFormProps {
  handleSave: (editedApplicant: ILoginUser) => void;
}

const SignInForm = ({ handleSave }: SignInFormProps) => {
  const { t } = useTranslation();
  const methods = useForm<ILoginUser>({
    resolver: yupResolver(signInSchema),
  });

  const { handleSubmit, register } = methods;

  const onSubmit: SubmitHandler<ILoginUser> = editedApplicant => {
    handleSave(editedApplicant);
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
      <TextField
        sx={disableAutofillBg}
        autoComplete="email"
        autoFocus
        defaultValue=""
        fullWidth
        id="email"
        label={t('form.email')}
        margin="normal"
        required
        {...register('email')}
      />
      <TextField
        sx={disableAutofillBg}
        autoComplete="current-password"
        defaultValue=""
        fullWidth
        id="password"
        label={t('form.password')}
        margin="normal"
        type="password"
        {...register('password')}
      />
      {/*
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label={t('form.rememberMe')}
      />
      */}
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        {t('form.logIn')}
      </Button>
    </Box>
  );
};

export default SignInForm;
