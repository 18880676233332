import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SecurityIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path
        d="M14.1,10.8c-0.2-0.2-0.5-0.3-0.9-0.3c0-1.2-0.7-1.8-1.3-1.9c-0.6,0-1.3,0.4-1.5,1.8c0,0.1,0,0.1,0,0.2
		c-0.3,0-0.6,0.1-0.8,0.2c-0.5,0.3-0.4,1-0.3,1.6c0,0.2,0.1,0.4,0.1,0.5c0,0.3,0,1,0,1.1c0,0.3,0.1,0.5,0.4,0.5
		c0.3,0.1,0.5-0.1,0.6-0.4c0-0.2,0-1,0-1.3c0-0.2,0-0.4-0.1-0.6c0-0.2-0.1-0.7-0.1-0.7c0.1,0,0.3-0.1,1.3-0.1c0.2,0,0.5,0,0.8-0.1
		c0.3,0,0.8-0.1,1,0c0.1,0.5,0.2,2.1,0.1,2.6c-0.5,0.2-1.7,0.2-2.2,0.2c-0.2,0-0.3,0-0.4,0c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5
		l0.4,0c0.2,0,0.4,0,0.6,0c1,0,1.8-0.1,2.3-0.5c0.2-0.2,0.3-0.4,0.3-0.8C14.5,13.9,14.6,11.3,14.1,10.8z M11.6,10.6
		c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0.1-0.7,0.3-1,0.5-1c0.1,0,0.4,0.3,0.4,0.9c0,0,0,0,0,0c0,0,0,0,0,0
		C12.1,10.6,11.8,10.6,11.6,10.6z"
        fill="currentColor"
      />
      <path
        d="M12.6,13.2V13c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v0.2c0,0.3,0.2,0.5,0.5,0.5S12.6,13.5,12.6,13.2z"
        fill="currentColor"
      />
    </g>
    <path
      d="M12.1,20c-0.2,0-0.3-0.1-0.4-0.3c-0.1-0.2,0-0.5,0.2-0.6c2.2-1.1,6.1-7.5,5.3-9.9c0,0-0.1,0-0.2-0.1c-0.9-0.4-3.3-1.6-5-3.7
	c-1.5,1.7-3.7,2.9-4.8,3.5C7,9,6.9,9,6.8,9.1c-0.8,2.8,2.4,8.2,3.7,9.2c0.2,0.2,0.3,0.5,0.1,0.7c-0.2,0.2-0.5,0.3-0.7,0.1
	c-1.4-1.1-5.2-7-4-10.4C6,8.4,6.1,8.3,6.8,8c1.1-0.6,3.6-1.9,4.9-3.7c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
	c1.5,2.2,4.2,3.5,5,3.9c0.4,0.2,0.5,0.2,0.6,0.4c1.3,3.1-3.3,10.1-5.7,11.3C12.3,19.9,12.2,20,12.1,20z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default SecurityIcon;
