import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import background from 'src/static/images/bg.jpg';
import Footer from './Footer';
import MenuComponent from './MenuComponent';

export interface Props {
  children?: ReactNode;
}

const PageWrapper = ({ children }: Props) => {
  const { pathname } = useLocation();
  const isAdminPage = pathname.includes('/dashboard/');
  return (
    <Box
      sx={{
        // Photo by <a href="https://unsplash.com/@joshcala?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Josh Calabrese</a> on <a href="https://unsplash.com/s/photos/plants?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: t =>
          t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isAdminPage ? 'row' : 'column',
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <MenuComponent />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            ...(isAdminPage
              ? { height: '100vh', overflow: 'auto' }
              : { minHeight: 'calc(100vh - 65px - 85px)' }),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            {children}
            <Footer />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};
export default PageWrapper;
