import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { SECURE_API } from 'src/api/api';
import {
  PERSISTENT_KEY_ACCESS_TOKEN,
  PERSISTENT_KEY_AUTH_USER,
  PERSISTENT_KEY_REFRESH_TOKEN,
} from 'src/common/constants/persistentStorageKeys';
import { ILoginUser, IUser } from 'src/interfaces/User';
import { IFormUser } from 'src/pages/Dashboard/Profile/forms/ProfileEditorForm';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
function createCtx<A extends {} | null>() {
  const ctx = createContext<A | undefined>(undefined);
  const useCtx = () => {
    const c = useContext(ctx);
    if (c === undefined) throw new Error('useAuth must be inside a Provider with a value');
    return c;
  };
  return [useCtx, ctx.Provider] as const; // 'as const' makes TypeScript infer a tuple
}

interface AuthCtxInterface {
  accessToken?: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  refreshToken?: string;
  signIn: (userToLogIn: ILoginUser, callback?: () => void) => Promise<void>;
  signOut: (callback?: () => void) => Promise<void>;
  signUp: (userToCreate: ILoginUser, callback?: () => void) => void;
  update: (userToUpdate: IFormUser, callback?: () => void) => Promise<void>;
  user?: IUser;
}

// Provider hook that creates auth object and handles state
const useProvideAuth = () => {
  const lsUser = localStorage.getItem(PERSISTENT_KEY_AUTH_USER);

  const { rolesById, setDateTimeFormat: doSetDateTimeFormat } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const [user, setUser] = useState<IUser | undefined>(lsUser ? JSON.parse(lsUser) : undefined);
  const [isSuperAdmin, setIsSuperAdmin] = useState(
    lsUser ? JSON.parse(lsUser).role.roleId === 0 : false,
  );
  const [isAdmin, setIsAdmin] = useState(lsUser ? JSON.parse(lsUser).role.roleId === 1 || JSON.parse(lsUser).role.roleId === 0 : false);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(PERSISTENT_KEY_ACCESS_TOKEN) || undefined,
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem(PERSISTENT_KEY_REFRESH_TOKEN) || undefined,
  );

  useEffect(() => {
    if (user) {
      localStorage.setItem(PERSISTENT_KEY_AUTH_USER, JSON.stringify(user));
    } else {
      localStorage.removeItem(PERSISTENT_KEY_AUTH_USER);
    }
  }, [user]);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem(PERSISTENT_KEY_ACCESS_TOKEN, accessToken);
    } else {
      localStorage.removeItem(PERSISTENT_KEY_ACCESS_TOKEN);
    }
  }, [accessToken]);

  useEffect(() => {
    if (refreshToken) {
      localStorage.setItem(PERSISTENT_KEY_REFRESH_TOKEN, refreshToken);
    } else {
      localStorage.removeItem(PERSISTENT_KEY_REFRESH_TOKEN);
    }
  }, [refreshToken]);

  const signIn = async (userToLogIn: ILoginUser, callback?: () => void) => {
    return SECURE_API.post<{
      user: IUser;
      accessToken: string;
      refreshToken: string;
    }>('users/login', userToLogIn).then(({ data }) => {
      if (data) {
        setUser(data.user);
        if (rolesById) {
          setIsSuperAdmin(data.user.role.roleId === 0);
          setIsAdmin(data.user.role.roleId === 1 || data.user.role.roleId === 0);
        }
        setAccessToken(data.accessToken);
        setRefreshToken(data.refreshToken);
        doSetDateTimeFormat(data.user.dateFormat, data.user.timeFormat);
      } else {
        setUser(undefined);
      }
      callback && callback();
    });
  };

  const signOut = async (callback?: () => void) => {
    return SECURE_API.post<null>('users/logout', {
      token: accessToken,
      'refresh-token': refreshToken,
    }).then(() => {
      setUser(undefined);
      setAccessToken(undefined);
      setRefreshToken(undefined);
      callback && callback();
    });
  };

  const signUp = (userToCreate: ILoginUser, callback?: () => void) => {
    SECURE_API.post<IUser>('users/create', userToCreate, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(({ data }) => {
        if (data) {
          setUser(data);
        } else {
          setUser(undefined);
        }
        callback && callback();
      })
      .catch(error => console.log({ error }));
  };

  const update = async (userToUpdate: IFormUser, callback?: () => void) => {
    return SECURE_API.put<IUser>(`users/${userToUpdate.id}`, userToUpdate, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(({ data }) => {
      if (data) {
        setUser(data);
      } else {
        setUser(undefined);
      }
      callback && callback();
    });
  };

  return {
    user,
    accessToken,
    isAdmin,
    isSuperAdmin,
    refreshToken,
    signIn,
    signUp,
    signOut,
    update,
  };
};

export const [useAuth, AuthCtxProvider] = createCtx<AuthCtxInterface>();

type ProvideAuthProps = { children: ReactNode };

export const ProvideAuth = ({ children }: ProvideAuthProps) => {
  const auth = useProvideAuth();
  return <AuthCtxProvider value={auth}>{children}</AuthCtxProvider>;
};
