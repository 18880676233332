import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TrifoldIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <g>
      <path
        d="M49.1,17c0-0.2-0.1-0.5-0.2-0.6c0.2-0.5,0.1-1-0.2-1.4c-0.5-0.5-1.3-0.5-1.8-0.1c-0.7,0.7-1.4,1.3-2.2,1.9
	c-2,1.8-4.1,3.6-5.9,5.8c-0.1,0.1-0.2,0.2-0.2,0.3c0,0-0.1-0.1-0.1-0.1c-0.3-0.3-0.5-0.5-0.6-0.5c-1.8-1.4-3.2-2.7-4.3-3.8
	c-4-3.8-5.8-4.9-12.7,0.1c-0.7,0.5-1.3,1-2,1.5c-1.1,0.9-2.2,1.7-3.3,2.4c-0.6,0-1.2,0.4-1.3,1c-0.1,0.2-0.1,0.5-0.1,0.8
	c-0.1,1.5-0.1,3,0,4.5c0,1.1,0,2.3,0,3.4c-0.1,1.7,0,3.4,0.1,5c0,0.6,0.1,1.3,0.1,1.9c0.1,1.9,0,3.4,0,4.6c0,2.7,0,3.8,1.1,4.3
	c1.1,0.5,1.9-0.2,3.8-1.8c1.1-1,2.7-2.3,5-3.9c0.4-0.3,0.7-0.6,1-0.9c0.3-0.3,0.8-0.8,1-0.8c0.9,0.1,4.1,2.8,8.4,7.7
	c0.4,0.5,1,0.7,1.6,0.8c0.1,0,0.1,0,0.2,0c2.2,0,5.1-2.6,9.1-6.1c1-0.9,2-1.8,2.7-2.3c0.2,0.1,0.4,0.1,0.5,0.1c0,0,0.1,0,0.1,0
	c0.7-0.1,1.2-0.7,1.2-1.4C49.2,32.1,49.1,24.4,49.1,17z M23.3,39.9c-0.2,0.2-0.4,0.5-0.6,0.6c-2.3,1.7-3.9,3-5.1,4
	c-0.2,0.2-0.5,0.4-0.7,0.6c0-0.3,0-0.7,0-1.1c0-1.2,0-2.8,0-4.7c0-0.7,0-1.3-0.1-2c-0.1-1.7-0.1-3.2-0.1-4.8c0-1.2,0-2.4,0-3.5
	c0-1.3,0-2.6,0-4c1.4-0.8,2.6-1.7,3.8-2.6c0.6-0.5,1.3-1,1.9-1.4c1.1-0.8,2.1-1.5,2.9-1.9c0.1,0.2,0.3,0.4,0.5,0.5
	c-0.1,1.7-0.4,4.4-0.7,6.6c-0.6,5.1-1.2,10.3-0.8,13C23.9,39.2,23.6,39.6,23.3,39.9z M26.8,38.4c-0.3-2.4,0.3-7.8,0.8-12.2
	c0.5-4.4,0.7-6.9,0.7-8.4c1.1,0.2,2,1.1,3.5,2.5c1.1,1.1,2.6,2.5,4.5,3.9c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.5,0.5,0.7,0.7
	c0,0.1-0.1,0.2-0.1,0.4c-0.1,1.5-0.2,3-0.2,4.5c-0.3,5.3-0.5,10.8-1,16.2C31.9,41.7,28.8,39,26.8,38.4z M43.6,41.3
	c-1.1,1-3.2,2.9-4.9,4.2c0.4-5,0.7-10.1,0.9-15.1c0.1-1.5,0.1-2.9,0.2-4.3c0.6-0.4,0.8-1.2,0.9-1.6c0-0.1,0-0.2,0.1-0.3
	c1.6-2,3.6-3.8,5.5-5.5c0.1,0,0.1-0.1,0.2-0.1c0.1,6.5,0.2,13.1,0.7,19.7c0,0,0,0-0.1,0C46.3,38.9,45.1,40,43.6,41.3z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default TrifoldIcon;
