import { mapKeys, sortBy } from 'lodash-es';
import { Country } from 'src/interfaces/Country';

export const COUNTRIES: Country[] = sortBy([
  {
    name: 'Tunisian Republic',
    nativeName: 'الجمهورية التونسية',
    cca2: 'TN',
    altSpellings: ['TN', 'Republic of Tunisia', 'al-Jumhūriyyah at-Tūnisiyyah'],
  },
  {
    name: 'Kingdom of Spain',
    nativeName: 'Reino de España',
    cca2: 'ES',
    altSpellings: ['ES', 'Kingdom of Spain', 'Reino de España'],
  },
  {
    name: 'Kyrgyz Republic',
    nativeName: 'Кыргыз Республикасы',
    cca2: 'KG',
    altSpellings: [
      'KG',
      'Киргизия',
      'Kyrgyz Republic',
      'Кыргыз Республикасы',
      'Kyrgyz Respublikasy',
    ],
  },
  {
    name: 'Republic of Yemen',
    nativeName: 'الجمهورية اليمنية',
    cca2: 'YE',
    altSpellings: ['YE', 'Yemeni Republic', 'al-Jumhūriyyah al-Yamaniyyah'],
  },
  {
    name: 'State of Israel',
    nativeName: 'دولة إسرائيل',
    cca2: 'IL',
    altSpellings: ['IL', 'State of Israel', "Medīnat Yisrā'el"],
  },
  {
    name: 'Falkland Islands',
    nativeName: 'Falkland Islands',
    cca2: 'FK',
    altSpellings: ['FK', 'Islas Malvinas', 'Falkland Islands (Malvinas)'],
  },
  {
    name: 'Virgin Islands',
    nativeName: 'Virgin Islands',
    cca2: 'VG',
    altSpellings: ['VG', 'Virgin Islands, British'],
  },
  {
    name: 'Montenegro',
    nativeName: 'Црна Гора',
    cca2: 'ME',
    altSpellings: ['ME', 'Crna Gora'],
  },
  {
    name: 'Federal Democratic Republic of Nepal',
    nativeName: 'नेपाल संघीय लोकतान्त्रिक गणतन्त्र',
    cca2: 'NP',
    altSpellings: ['NP', 'Federal Democratic Republic of Nepal', 'Loktāntrik Ganatantra Nepāl'],
  },
  {
    name: 'Republic of Indonesia',
    nativeName: 'Republik Indonesia',
    cca2: 'ID',
    altSpellings: ['ID', 'Republic of Indonesia', 'Republik Indonesia'],
  },
  {
    name: 'Republic of Rwanda',
    nativeName: 'Republic of Rwanda',
    cca2: 'RW',
    altSpellings: ['RW', 'Republic of Rwanda', "Repubulika y'u Rwanda", 'République du Rwanda'],
  },
  {
    name: 'Bouvet Island',
    nativeName: 'Bouvetøya',
    cca2: 'BV',
    altSpellings: ['BV', 'Bouvetøya', 'Bouvet-øya'],
  },
  {
    name: 'Kingdom of Denmark',
    nativeName: 'Kongeriget Danmark',
    cca2: 'DK',
    altSpellings: ['DK', 'Danmark', 'Kingdom of Denmark', 'Kongeriget Danmark'],
  },
  {
    name: 'Republic of Cameroon',
    nativeName: 'Republic of Cameroon',
    cca2: 'CM',
    altSpellings: ['CM', 'Republic of Cameroon', 'République du Cameroun'],
  },
  {
    name: 'Republic of Nicaragua',
    nativeName: 'República de Nicaragua',
    cca2: 'NI',
    altSpellings: ['NI', 'Republic of Nicaragua', 'República de Nicaragua'],
  },
  {
    name: 'Republic of El Salvador',
    nativeName: 'República de El Salvador',
    cca2: 'SV',
    altSpellings: ['SV', 'Republic of El Salvador', 'República de El Salvador'],
  },
  {
    name: 'Independent and Sovereign Republic of Kiribati',
    nativeName: 'Independent and Sovereign Republic of Kiribati',
    cca2: 'KI',
    altSpellings: ['KI', 'Republic of Kiribati', 'Ribaberiki Kiribati'],
  },
  {
    name: 'Commonwealth of Australia',
    nativeName: 'Commonwealth of Australia',
    cca2: 'AU',
    altSpellings: ['AU'],
  },
  {
    name: 'Réunion Island',
    nativeName: 'Ile de la Réunion',
    cca2: 'RE',
    altSpellings: ['RE', 'Reunion'],
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    nativeName: 'South Georgia and the South Sandwich Islands',
    cca2: 'GS',
    altSpellings: ['GS', 'South Georgia and the South Sandwich Islands'],
  },
  {
    name: 'Guam',
    nativeName: 'Guåhån',
    cca2: 'GU',
    altSpellings: ['GU', 'Guåhån'],
  },
  {
    name: 'Territory of the French Southern and Antarctic Lands',
    nativeName: 'Territoire des Terres australes et antarctiques françaises',
    cca2: 'TF',
    altSpellings: ['TF', 'French Southern Territories'],
  },
  {
    name: "Macao Special Administrative Region of the People's Republic of China",
    nativeName: 'Região Administrativa Especial de Macau da República Popular da China',
    cca2: 'MO',
    altSpellings: [
      'MO',
      '澳门',
      'Macao',
      "Macao Special Administrative Region of the People's Republic of China",
      '中華人民共和國澳門特別行政區',
      'Região Administrativa Especial de Macau da República Popular da China',
    ],
  },
  {
    name: 'Syrian Arab Republic',
    nativeName: 'الجمهورية العربية السورية',
    cca2: 'SY',
    altSpellings: ['SY', 'Syrian Arab Republic', 'Al-Jumhūrīyah Al-ʻArabīyah As-Sūrīyah'],
  },
  {
    name: 'Republic of Iraq',
    nativeName: 'جمهورية العراق',
    cca2: 'IQ',
    altSpellings: ['IQ', 'Republic of Iraq', 'Jumhūriyyat al-‘Irāq'],
  },
  {
    name: 'Republic of Albania',
    nativeName: 'Republika e Shqipërisë',
    cca2: 'AL',
    altSpellings: ['AL', 'Shqipëri', 'Shqipëria', 'Shqipnia'],
  },
  {
    name: 'Republic of Uzbekistan',
    nativeName: 'Республика Узбекистан',
    cca2: 'UZ',
    altSpellings: [
      'UZ',
      'Republic of Uzbekistan',
      'O‘zbekiston Respublikasi',
      'Ўзбекистон Республикаси',
    ],
  },
  {
    name: 'State of Palestine',
    nativeName: 'دولة فلسطين',
    cca2: 'PS',
    altSpellings: ['PS', 'Palestine, State of', 'State of Palestine', 'Dawlat Filasṭin'],
  },
  {
    name: 'Commonwealth of the Northern Mariana Islands',
    nativeName: 'Commonwealth of the Northern Mariana Islands',
    cca2: 'MP',
    altSpellings: [
      'MP',
      'Commonwealth of the Northern Mariana Islands',
      'Sankattan Siha Na Islas Mariånas',
    ],
  },
  {
    name: 'Republic of Austria',
    nativeName: 'Republik Österreich',
    cca2: 'AT',
    altSpellings: ['AT', 'Osterreich', 'Oesterreich'],
  },
  {
    name: 'Republic of Zimbabwe',
    nativeName: 'Republic of Zimbabwe',
    cca2: 'ZW',
    altSpellings: ['ZW', 'Republic of Zimbabwe'],
  },
  {
    name: 'British Indian Ocean Territory',
    nativeName: 'British Indian Ocean Territory',
    cca2: 'IO',
    altSpellings: ['IO'],
  },
  {
    name: 'Kingdom of Morocco',
    nativeName: 'المملكة المغربية',
    cca2: 'MA',
    altSpellings: ['MA', 'Kingdom of Morocco', 'Al-Mamlakah al-Maġribiyah'],
  },
  {
    name: 'Republic of Guatemala',
    nativeName: 'República de Guatemala',
    cca2: 'GT',
    altSpellings: ['GT'],
  },
  {
    name: 'Republic of India',
    nativeName: 'Republic of India',
    cca2: 'IN',
    altSpellings: ['IN', 'Bhārat', 'Republic of India', 'Bharat Ganrajya', 'இந்தியா'],
  },
  {
    name: 'Kingdom of Saudi Arabia',
    nativeName: 'المملكة العربية السعودية',
    cca2: 'SA',
    altSpellings: [
      'Saudi',
      'SA',
      'Kingdom of Saudi Arabia',
      'Al-Mamlakah al-‘Arabiyyah as-Su‘ūdiyyah',
    ],
  },
  {
    name: 'Principality of Andorra',
    nativeName: "Principat d'Andorra",
    cca2: 'AD',
    altSpellings: ['AD', 'Principality of Andorra', "Principat d'Andorra"],
  },
  {
    name: 'Commonwealth of the Bahamas',
    nativeName: 'Commonwealth of the Bahamas',
    cca2: 'BS',
    altSpellings: ['BS', 'Commonwealth of the Bahamas'],
  },
  {
    name: 'Guiana',
    nativeName: 'Guyane',
    cca2: 'GF',
    altSpellings: ['GF', 'Guiana', 'Guyane'],
  },
  {
    name: 'Republic of the Marshall Islands',
    nativeName: 'Republic of the Marshall Islands',
    cca2: 'MH',
    altSpellings: ['MH', 'Republic of the Marshall Islands', 'Aolepān Aorōkin M̧ajeļ'],
  },
  {
    name: 'Oriental Republic of Uruguay',
    nativeName: 'República Oriental del Uruguay',
    cca2: 'UY',
    altSpellings: ['UY', 'Oriental Republic of Uruguay', 'República Oriental del Uruguay'],
  },
  {
    name: 'Republic of Moldova',
    nativeName: 'Republica Moldova',
    cca2: 'MD',
    altSpellings: ['MD', 'Moldova, Republic of', 'Republic of Moldova', 'Republica Moldova'],
  },
  {
    name: 'Republic of Chad',
    nativeName: 'جمهورية تشاد',
    cca2: 'TD',
    altSpellings: ['TD', 'Tchad', 'Republic of Chad', 'République du Tchad'],
  },
  {
    name: 'Republic of Guinea-Bissau',
    nativeName: 'República da Guiné-Bissau',
    cca2: 'GW',
    altSpellings: ['GW', 'Republic of Guinea-Bissau', 'República da Guiné-Bissau'],
  },
  {
    name: 'Arab Republic of Egypt',
    nativeName: 'جمهورية مصر العربية',
    cca2: 'EG',
    altSpellings: ['EG', 'Arab Republic of Egypt'],
  },
  {
    name: 'Antarctica',
    nativeName: '',
    cca2: 'AQ',
    altSpellings: ['AQ'],
  },
  {
    name: 'Republic of Malawi',
    nativeName: 'Republic of Malawi',
    cca2: 'MW',
    altSpellings: ['MW', 'Republic of Malawi'],
  },
  {
    name: 'Romania',
    nativeName: 'România',
    cca2: 'RO',
    altSpellings: ['RO', 'Rumania', 'Roumania', 'România'],
  },
  {
    name: 'Republic of Panama',
    nativeName: 'República de Panamá',
    cca2: 'PA',
    altSpellings: ['PA', 'Republic of Panama', 'República de Panamá'],
  },
  {
    name: 'State of Libya',
    nativeName: 'الدولة ليبيا',
    cca2: 'LY',
    altSpellings: ['LY', 'State of Libya', 'Dawlat Libya'],
  },
  {
    name: 'Republic of Peru',
    nativeName: 'Piruw Suyu',
    cca2: 'PE',
    altSpellings: ['PE', 'Republic of Peru', 'República del Perú'],
  },
  {
    name: 'Country of Curaçao',
    nativeName: 'Country of Curaçao',
    cca2: 'CW',
    altSpellings: ['CW', 'Curacao', 'Kòrsou', 'Country of Curaçao', 'Land Curaçao', 'Pais Kòrsou'],
  },
  {
    name: 'Gibraltar',
    nativeName: 'Gibraltar',
    cca2: 'GI',
    altSpellings: ['GI', 'Gibraltar'],
  },
  {
    name: 'Greenland',
    nativeName: 'Kalaallit Nunaat',
    cca2: 'GL',
    altSpellings: ['GL', 'Grønland'],
  },
  {
    name: 'French Polynesia',
    nativeName: 'Polynésie française',
    cca2: 'PF',
    altSpellings: ['PF', 'Polynésie française', 'French Polynesia', 'Pōrīnetia Farāni'],
  },
  {
    name: 'United States Minor Outlying Islands',
    nativeName: 'United States Minor Outlying Islands',
    cca2: 'UM',
    altSpellings: ['UM'],
  },
  {
    name: "People's Republic of China",
    nativeName: '中华人民共和国',
    cca2: 'CN',
    altSpellings: [
      'CN',
      'Zhōngguó',
      'Zhongguo',
      'Zhonghua',
      "People's Republic of China",
      '中华人民共和国',
    ],
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    nativeName: 'United Kingdom of Great Britain and Northern Ireland',
    cca2: 'GB',
    altSpellings: ['GB', 'UK', 'Great Britain'],
  },
  {
    name: 'Republic of Niger',
    nativeName: 'République du Niger',
    cca2: 'NE',
    altSpellings: ['NE', 'Nijar'],
  },
  {
    name: "People's Republic of Bangladesh",
    nativeName: 'বাংলাদেশ গণপ্রজাতন্ত্রী',
    cca2: 'BD',
    altSpellings: ['BD', "People's Republic of Bangladesh", 'Gônôprôjatôntri Bangladesh'],
  },
  {
    name: 'Sultanate of Oman',
    nativeName: 'سلطنة عمان',
    cca2: 'OM',
    altSpellings: ['OM', 'Sultanate of Oman', 'Salṭanat ʻUmān'],
  },
  {
    name: 'Republic of the Congo',
    nativeName: 'République du Congo',
    cca2: 'CG',
    altSpellings: ['CG', 'Congo', 'Congo-Brazzaville'],
  },
  {
    name: 'Bolivarian Republic of Venezuela',
    nativeName: 'República Bolivariana de Venezuela',
    cca2: 'VE',
    altSpellings: [
      'VE',
      'Bolivarian Republic of Venezuela',
      'Venezuela, Bolivarian Republic of',
      'República Bolivariana de Venezuela',
    ],
  },
  {
    name: 'Pitcairn Group of Islands',
    nativeName: 'Pitcairn Group of Islands',
    cca2: 'PN',
    altSpellings: ['PN', 'Pitcairn', 'Pitcairn Henderson Ducie and Oeno Islands'],
  },
  {
    name: 'Heard Island and McDonald Islands',
    nativeName: 'Heard Island and McDonald Islands',
    cca2: 'HM',
    altSpellings: ['HM', 'Heard Island and McDonald Islands'],
  },
  {
    name: 'Mongolia',
    nativeName: 'Монгол улс',
    cca2: 'MN',
    altSpellings: ['MN'],
  },
  {
    name: 'Republic of the Sudan',
    nativeName: 'جمهورية السودان',
    cca2: 'SD',
    altSpellings: ['SD', 'Republic of the Sudan', 'Jumhūrīyat as-Sūdān'],
  },
  {
    name: 'New Caledonia',
    nativeName: 'Nouvelle-Calédonie',
    cca2: 'NC',
    altSpellings: ['NC'],
  },
  {
    name: 'Republic of North Macedonia',
    nativeName: 'Република Северна Македонија',
    cca2: 'MK',
    altSpellings: [
      'MK',
      'The former Yugoslav Republic of Macedonia',
      'Republic of North Macedonia',
      'Macedonia, The Former Yugoslav Republic of',
      'Република Северна Македонија',
    ],
  },
  {
    name: 'Union of the Comoros',
    nativeName: 'الاتحاد القمري',
    cca2: 'KM',
    altSpellings: [
      'KM',
      'Union of the Comoros',
      'Union des Comores',
      'Udzima wa Komori',
      'al-Ittiḥād al-Qumurī',
    ],
  },
  {
    name: 'Republic of the Union of Myanmar',
    nativeName: 'ပြည်ထောင်စု သမ္မတ မြန်မာနိုင်ငံတော်',
    cca2: 'MM',
    altSpellings: [
      'MM',
      'Burma',
      'Republic of the Union of Myanmar',
      'Pyidaunzu Thanmăda Myăma Nainngandaw',
    ],
  },
  {
    name: "People's Democratic Republic of Algeria",
    nativeName: 'الجمهورية الديمقراطية الشعبية الجزائرية',
    cca2: 'DZ',
    altSpellings: ['DZ', 'Dzayer', 'Algérie'],
  },
  {
    name: 'Co-operative Republic of Guyana',
    nativeName: 'Co-operative Republic of Guyana',
    cca2: 'GY',
    altSpellings: ['GY', 'Co-operative Republic of Guyana'],
  },
  {
    name: 'Antigua and Barbuda',
    nativeName: 'Antigua and Barbuda',
    cca2: 'AG',
    altSpellings: ['AG'],
  },
  {
    name: 'United Arab Emirates',
    nativeName: 'الإمارات العربية المتحدة',
    cca2: 'AE',
    altSpellings: ['AE', 'UAE', 'Emirates'],
  },
  {
    name: 'Nation of Brunei, Abode of Peace',
    nativeName: 'Nation of Brunei, Abode Damai',
    cca2: 'BN',
    altSpellings: ['BN', 'Brunei Darussalam', 'Nation of Brunei', 'the Abode of Peace'],
  },
  {
    name: 'Federation of Saint Christopher and Nevis',
    nativeName: 'Federation of Saint Christopher and Nevis',
    cca2: 'KN',
    altSpellings: ['KN', 'Federation of Saint Christopher and Nevis'],
  },
  {
    name: 'Martinique',
    nativeName: 'Martinique',
    cca2: 'MQ',
    altSpellings: ['MQ'],
  },
  {
    name: 'Republic of Singapore',
    nativeName: '新加坡共和国',
    cca2: 'SG',
    altSpellings: ['SG', 'Singapura', 'Republik Singapura'],
  },
  {
    name: 'American Samoa',
    nativeName: 'American Samoa',
    cca2: 'AS',
    altSpellings: ['AS', 'Amerika Sāmoa', 'Amelika Sāmoa', 'Sāmoa Amelika'],
  },
  {
    name: 'Åland Islands',
    nativeName: 'Landskapet Åland',
    cca2: 'AX',
    altSpellings: ['AX', 'Aaland', 'Aland', 'Ahvenanmaa'],
  },
  {
    name: 'Islamic Republic of Pakistan',
    nativeName: 'Islamic Republic of Pakistan',
    cca2: 'PK',
    altSpellings: [
      'PK',
      'Pākistān',
      'Islamic Republic of Pakistan',
      "Islāmī Jumhūriya'eh Pākistān",
    ],
  },
  {
    name: 'Federal Democratic Republic of Ethiopia',
    nativeName: 'የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ',
    cca2: 'ET',
    altSpellings: [
      'ET',
      'ʾĪtyōṗṗyā',
      'Federal Democratic Republic of Ethiopia',
      'የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ',
    ],
  },
  {
    name: 'Saint Vincent and the Grenadines',
    nativeName: 'Saint Vincent and the Grenadines',
    cca2: 'VC',
    altSpellings: ['VC'],
  },
  {
    name: 'Lebanese Republic',
    nativeName: 'الجمهورية اللبنانية',
    cca2: 'LB',
    altSpellings: ['LB', 'Lebanese Republic', 'Al-Jumhūrīyah Al-Libnānīyah'],
  },
  {
    name: 'Republic of Equatorial Guinea',
    nativeName: 'République de la Guinée Équatoriale',
    cca2: 'GQ',
    altSpellings: [
      'GQ',
      'Republic of Equatorial Guinea',
      'República de Guinea Ecuatorial',
      'République de Guinée équatoriale',
      'República da Guiné Equatorial',
    ],
  },
  {
    name: 'Dominican Republic',
    nativeName: 'República Dominicana',
    cca2: 'DO',
    altSpellings: ['DO'],
  },
  {
    name: 'Aruba',
    nativeName: 'Aruba',
    cca2: 'AW',
    altSpellings: ['AW'],
  },
  {
    name: 'Isle of Man',
    nativeName: 'Isle of Man',
    cca2: 'IM',
    altSpellings: ['IM', 'Ellan Vannin', 'Mann', 'Mannin'],
  },
  {
    name: 'Anguilla',
    nativeName: 'Anguilla',
    cca2: 'AI',
    altSpellings: ['AI'],
  },
  {
    name: 'Saint Martin',
    nativeName: 'Saint-Martin',
    cca2: 'MF',
    altSpellings: [
      'MF',
      'Collectivity of Saint Martin',
      'Collectivité de Saint-Martin',
      'Saint Martin (French part)',
    ],
  },
  {
    name: 'Turkmenistan',
    nativeName: 'Туркменистан',
    cca2: 'TM',
    altSpellings: ['TM'],
  },
  {
    name: 'United Republic of Tanzania',
    nativeName: 'United Republic of Tanzania',
    cca2: 'TZ',
    altSpellings: [
      'TZ',
      'Tanzania, United Republic of',
      'United Republic of Tanzania',
      'Jamhuri ya Muungano wa Tanzania',
    ],
  },
  {
    name: 'Federal Republic of Germany',
    nativeName: 'Bundesrepublik Deutschland',
    cca2: 'DE',
    altSpellings: ['DE', 'Federal Republic of Germany', 'Bundesrepublik Deutschland'],
  },
  {
    name: 'Ukraine',
    nativeName: 'Україна',
    cca2: 'UA',
    altSpellings: ['UA', 'Ukrayina'],
  },
  {
    name: 'Slovak Republic',
    nativeName: 'Slovenská republika',
    cca2: 'SK',
    altSpellings: ['SK', 'Slovak Republic', 'Slovenská republika'],
  },
  {
    name: 'Republic of Suriname',
    nativeName: 'Republiek Suriname',
    cca2: 'SR',
    altSpellings: ['SR', 'Sarnam', 'Sranangron', 'Republic of Suriname', 'Republiek Suriname'],
  },
  {
    name: 'Republic of Tajikistan',
    nativeName: 'Республика Таджикистан',
    cca2: 'TJ',
    altSpellings: [
      'TJ',
      'Toçikiston',
      'Republic of Tajikistan',
      'Ҷумҳурии Тоҷикистон',
      'Çumhuriyi Toçikiston',
    ],
  },
  {
    name: 'United Mexican States',
    nativeName: 'Estados Unidos Mexicanos',
    cca2: 'MX',
    altSpellings: ['MX', 'Mexicanos', 'United Mexican States', 'Estados Unidos Mexicanos'],
  },
  {
    name: 'Republic of the Philippines',
    nativeName: 'Republic of the Philippines',
    cca2: 'PH',
    altSpellings: ['PH', 'Republic of the Philippines', 'Repúblika ng Pilipinas'],
  },
  {
    name: 'Territory of Christmas Island',
    nativeName: 'Territory of Christmas Island',
    cca2: 'CX',
    altSpellings: ['CX', 'Territory of Christmas Island'],
  },
  {
    name: 'Republic of South Africa',
    nativeName: 'Republiek van Suid-Afrika',
    cca2: 'ZA',
    altSpellings: ['ZA', 'RSA', 'Suid-Afrika', 'Republic of South Africa'],
  },
  {
    name: 'Canada',
    nativeName: 'Canada',
    cca2: 'CA',
    altSpellings: ['CA'],
  },
  {
    name: 'Republic of Ghana',
    nativeName: 'Republic of Ghana',
    cca2: 'GH',
    altSpellings: ['GH'],
  },
  {
    name: 'Svalbard og Jan Mayen',
    nativeName: 'Svalbard og Jan Mayen',
    cca2: 'SJ',
    altSpellings: ['SJ', 'Svalbard and Jan Mayen Islands'],
  },
  {
    name: 'Islamic Republic of Afghanistan',
    nativeName: 'جمهوری اسلامی افغانستان',
    cca2: 'AF',
    altSpellings: ['AF', 'Afġānistān'],
  },
  {
    name: 'Republic of Belarus',
    nativeName: 'Рэспубліка Беларусь',
    cca2: 'BY',
    altSpellings: ['BY', 'Bielaruś', 'Republic of Belarus', 'Белоруссия', 'Республика Белоруссия'],
  },
  {
    name: 'Democratic Republic of the Congo',
    nativeName: 'République démocratique du Congo',
    cca2: 'CD',
    altSpellings: [
      'CD',
      'DR Congo',
      'Congo-Kinshasa',
      'Congo, the Democratic Republic of the',
      'DRC',
    ],
  },
  {
    name: 'Grand Duchy of Luxembourg',
    nativeName: 'Großherzogtum Luxemburg',
    cca2: 'LU',
    altSpellings: [
      'LU',
      'Grand Duchy of Luxembourg',
      'Grand-Duché de Luxembourg',
      'Großherzogtum Luxemburg',
      'Groussherzogtum Lëtzebuerg',
    ],
  },
  {
    name: 'Socialist Republic of Vietnam',
    nativeName: 'Cộng hòa xã hội chủ nghĩa Việt Nam',
    cca2: 'VN',
    altSpellings: [
      'VN',
      'Socialist Republic of Vietnam',
      'Cộng hòa Xã hội chủ nghĩa Việt Nam',
      'Viet Nam',
    ],
  },
  {
    name: 'Federal Republic of Nigeria',
    nativeName: 'Federal Republic of Nigeria',
    cca2: 'NG',
    altSpellings: ['NG', 'Nijeriya', 'Naíjíríà', 'Federal Republic of Nigeria'],
  },
  {
    name: 'Republic of Colombia',
    nativeName: 'República de Colombia',
    cca2: 'CO',
    altSpellings: ['CO', 'Republic of Colombia', 'República de Colombia'],
  },
  {
    name: 'Republic of Liberia',
    nativeName: 'Republic of Liberia',
    cca2: 'LR',
    altSpellings: ['LR', 'Republic of Liberia'],
  },
  {
    name: 'Republic of Kazakhstan',
    nativeName: 'Қазақстан Республикасы',
    cca2: 'KZ',
    altSpellings: [
      'KZ',
      'Qazaqstan',
      'Казахстан',
      'Republic of Kazakhstan',
      'Қазақстан Республикасы',
      'Qazaqstan Respublïkası',
      'Республика Казахстан',
      'Respublika Kazakhstan',
    ],
  },
  {
    name: 'Republic of the Gambia',
    nativeName: 'Republic of the Gambia',
    cca2: 'GM',
    altSpellings: ['GM', 'Republic of the Gambia'],
  },
  {
    name: 'Hungary',
    nativeName: 'Magyarország',
    cca2: 'HU',
    altSpellings: ['HU'],
  },
  {
    name: 'Georgia',
    nativeName: 'საქართველო',
    cca2: 'GE',
    altSpellings: ['GE', 'Sakartvelo'],
  },
  {
    name: 'Republic of Bulgaria',
    nativeName: 'Република България',
    cca2: 'BG',
    altSpellings: ['BG', 'Republic of Bulgaria', 'Република България'],
  },
  {
    name: 'Solomon Islands',
    nativeName: 'Solomon Islands',
    cca2: 'SB',
    altSpellings: ['SB'],
  },
  {
    name: 'Kingdom of Belgium',
    nativeName: 'Königreich Belgien',
    cca2: 'BE',
    altSpellings: [
      'BE',
      'België',
      'Belgie',
      'Belgien',
      'Belgique',
      'Kingdom of Belgium',
      'Koninkrijk België',
      'Royaume de Belgique',
      'Königreich Belgien',
    ],
  },
  {
    name: 'Principality of Monaco',
    nativeName: 'Principauté de Monaco',
    cca2: 'MC',
    altSpellings: ['MC', 'Principality of Monaco', 'Principauté de Monaco'],
  },
  {
    name: 'Principality of Liechtenstein',
    nativeName: 'Fürstentum Liechtenstein',
    cca2: 'LI',
    altSpellings: ['LI', 'Principality of Liechtenstein', 'Fürstentum Liechtenstein'],
  },
  {
    name: 'Kingdom of Bhutan',
    nativeName: 'འབྲུག་རྒྱལ་ཁབ་',
    cca2: 'BT',
    altSpellings: ['BT', 'Kingdom of Bhutan'],
  },
  {
    name: 'State of Qatar',
    nativeName: 'دولة قطر',
    cca2: 'QA',
    altSpellings: ['QA', 'State of Qatar', 'Dawlat Qaṭar'],
  },
  {
    name: 'Argentine Republic',
    nativeName: 'Argentine Republic',
    cca2: 'AR',
    altSpellings: ['AR', 'Argentine Republic', 'República Argentina'],
  },
  {
    name: 'Czech Republic',
    nativeName: 'Česká republika',
    cca2: 'CZ',
    altSpellings: ['CZ', 'Česká republika', 'Česko'],
  },
  {
    name: 'Malaysia',
    nativeName: 'Malaysia',
    cca2: 'MY',
    altSpellings: ['MY'],
  },
  {
    name: 'State of Eritrea',
    nativeName: 'دولة إرتريا',
    cca2: 'ER',
    altSpellings: ['ER', 'State of Eritrea', 'ሃገረ ኤርትራ', 'Dawlat Iritriyá', 'ʾErtrā', 'Iritriyā'],
  },
  {
    name: 'Italian Republic',
    nativeName: 'Repubblica italiana',
    cca2: 'IT',
    altSpellings: ['IT', 'Italian Republic', 'Repubblica italiana'],
  },
  {
    name: 'Republic of Korea',
    nativeName: '대한민국',
    cca2: 'KR',
    altSpellings: ['KR', 'Korea, Republic of', 'Republic of Korea', '남한', '남조선'],
  },
  {
    name: 'Japan',
    nativeName: '日本',
    cca2: 'JP',
    altSpellings: ['JP', 'Nippon', 'Nihon'],
  },
  {
    name: 'Kingdom of Bahrain',
    nativeName: 'مملكة البحرين',
    cca2: 'BH',
    altSpellings: ['BH', 'Kingdom of Bahrain', 'Mamlakat al-Baḥrayn'],
  },
  {
    name: 'Central African Republic',
    nativeName: 'République centrafricaine',
    cca2: 'CF',
    altSpellings: ['CF', 'Central African Republic', 'République centrafricaine'],
  },
  {
    name: 'Republic of Cuba',
    nativeName: 'República de Cuba',
    cca2: 'CU',
    altSpellings: ['CU', 'Republic of Cuba', 'República de Cuba'],
  },
  {
    name: 'State of Kuwait',
    nativeName: 'دولة الكويت',
    cca2: 'KW',
    altSpellings: ['KW', 'State of Kuwait', 'Dawlat al-Kuwait'],
  },
  {
    name: 'Republic of Mauritius',
    nativeName: 'Republic of Mauritius',
    cca2: 'MU',
    altSpellings: ['MU', 'Republic of Mauritius', 'République de Maurice'],
  },
  {
    name: 'Cook Islands',
    nativeName: 'Cook Islands',
    cca2: 'CK',
    altSpellings: ['CK', "Kūki 'Āirani"],
  },
  {
    name: 'Republic of Ireland',
    nativeName: 'Republic of Ireland',
    cca2: 'IE',
    altSpellings: ['IE', 'Éire', 'Republic of Ireland', 'Poblacht na hÉireann'],
  },
  {
    name: 'Republic of the Maldives',
    nativeName: 'ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ',
    cca2: 'MV',
    altSpellings: [
      'MV',
      'Maldive Islands',
      'Republic of the Maldives',
      'Dhivehi Raajjeyge Jumhooriyya',
    ],
  },
  {
    name: 'Republic of Cyprus',
    nativeName: 'Δημοκρατία της Κύπρος',
    cca2: 'CY',
    altSpellings: [
      'CY',
      'Kýpros',
      'Kıbrıs',
      'Republic of Cyprus',
      'Κυπριακή Δημοκρατία',
      'Kıbrıs Cumhuriyeti',
    ],
  },
  {
    name: 'Kingdom of Cambodia',
    nativeName: 'ព្រះរាជាណាចក្រកម្ពុជា',
    cca2: 'KH',
    altSpellings: ['KH', 'Kingdom of Cambodia'],
  },
  {
    name: 'Republic of Mozambique',
    nativeName: 'República de Moçambique',
    cca2: 'MZ',
    altSpellings: ['MZ', 'Republic of Mozambique', 'República de Moçambique'],
  },
  {
    name: 'Kingdom of Sweden',
    nativeName: 'Konungariket Sverige',
    cca2: 'SE',
    altSpellings: ['SE', 'Kingdom of Sweden', 'Konungariket Sverige'],
  },
  {
    name: 'Republic of Chile',
    nativeName: 'República de Chile',
    cca2: 'CL',
    altSpellings: ['CL', 'Republic of Chile', 'República de Chile'],
  },
  {
    name: 'Swiss Confederation',
    nativeName: 'Confédération suisse',
    cca2: 'CH',
    altSpellings: ['CH', 'Swiss Confederation', 'Schweiz', 'Suisse', 'Svizzera', 'Svizra'],
  },
  {
    name: 'Republic of Cabo Verde',
    nativeName: 'República de Cabo Verde',
    cca2: 'CV',
    altSpellings: ['CV', 'Republic of Cabo Verde', 'República de Cabo Verde'],
  },
  {
    name: 'Hellenic Republic',
    nativeName: 'Ελληνική Δημοκρατία',
    cca2: 'GR',
    altSpellings: ['GR', 'Elláda', 'Hellenic Republic', 'Ελληνική Δημοκρατία'],
  },
  {
    name: 'Kingdom of the Netherlands',
    nativeName: 'Koninkrijk der Nederlanden',
    cca2: 'NL',
    altSpellings: ['NL', 'Holland', 'Nederland', 'The Netherlands'],
  },
  {
    name: 'Republic of South Sudan',
    nativeName: 'Republic of South Sudan',
    cca2: 'SS',
    altSpellings: ['SS'],
  },
  {
    name: 'Republic of Armenia',
    nativeName: 'Հայաստանի Հանրապետություն',
    cca2: 'AM',
    altSpellings: ['AM', 'Hayastan', 'Republic of Armenia', 'Հայաստանի Հանրապետություն'],
  },
  {
    name: 'Territory of the Cocos (Keeling) Islands',
    nativeName: 'Territory of the Cocos (Keeling) Islands',
    cca2: 'CC',
    altSpellings: ['CC', 'Keeling Islands', 'Cocos Islands'],
  },
  {
    name: 'Portuguese Republic',
    nativeName: 'República português',
    cca2: 'PT',
    altSpellings: ['PT', 'Portuguesa', 'Portuguese Republic', 'República Portuguesa'],
  },
  {
    name: 'Kingdom of Tonga',
    nativeName: 'Kingdom of Tonga',
    cca2: 'TO',
    altSpellings: ['TO'],
  },
  {
    name: 'Republic of Djibouti',
    nativeName: 'جمهورية جيبوتي',
    cca2: 'DJ',
    altSpellings: [
      'DJ',
      'Jabuuti',
      'Gabuuti',
      'Republic of Djibouti',
      'République de Djibouti',
      'Gabuutih Ummuuno',
      'Jamhuuriyadda Jabuuti',
    ],
  },
  {
    name: 'Saint Pierre and Miquelon',
    nativeName: 'Collectivité territoriale de Saint-Pierre-et-Miquelon',
    cca2: 'PM',
    altSpellings: ['PM', 'Collectivité territoriale de Saint-Pierre-et-Miquelon'],
  },
  {
    name: 'Iceland',
    nativeName: 'Ísland',
    cca2: 'IS',
    altSpellings: ['IS', 'Island', 'Republic of Iceland', 'Lýðveldið Ísland'],
  },
  {
    name: 'Republic of Guinea',
    nativeName: 'République de Guinée',
    cca2: 'GN',
    altSpellings: ['GN', 'Republic of Guinea', 'République de Guinée'],
  },
  {
    name: 'Republic of Uganda',
    nativeName: 'Republic of Uganda',
    cca2: 'UG',
    altSpellings: ['UG', 'Republic of Uganda', 'Jamhuri ya Uganda'],
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    nativeName: 'Saint Helena, Ascension and Tristan da Cunha',
    cca2: 'SH',
    altSpellings: ['Saint Helena', 'St. Helena, Ascension and Tristan da Cunha'],
  },
  {
    name: 'Faroe Islands',
    nativeName: 'Færøerne',
    cca2: 'FO',
    altSpellings: ['FO', 'Føroyar', 'Færøerne'],
  },
  {
    name: 'Republic of San Marino',
    nativeName: 'Repubblica di San Marino',
    cca2: 'SM',
    altSpellings: ['SM', 'Republic of San Marino', 'Repubblica di San Marino'],
  },
  {
    name: 'Tokelau',
    nativeName: 'Tokelau',
    cca2: 'TK',
    altSpellings: ['TK'],
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    nativeName: 'Bonaire, Sint Eustatius en Saba',
    cca2: 'BQ',
    altSpellings: ['BES islands'],
  },
  {
    name: 'Grenada',
    nativeName: 'Grenada',
    cca2: 'GD',
    altSpellings: ['GD'],
  },
  {
    name: 'Republic of Kenya',
    nativeName: 'Republic of Kenya',
    cca2: 'KE',
    altSpellings: ['KE', 'Republic of Kenya', 'Jamhuri ya Kenya'],
  },
  {
    name: 'Democratic Socialist Republic of Sri Lanka',
    nativeName: 'ශ්‍රී ලංකා ප්‍රජාතාන්ත්‍රික සමාජවාදී ජනරජය',
    cca2: 'LK',
    altSpellings: ['LK', 'ilaṅkai', 'Democratic Socialist Republic of Sri Lanka'],
  },
  {
    name: 'Turks and Caicos Islands',
    nativeName: 'Turks and Caicos Islands',
    cca2: 'TC',
    altSpellings: ['TC'],
  },
  {
    name: 'Virgin Islands of the United States',
    nativeName: 'Virgin Islands of the United States',
    cca2: 'VI',
    altSpellings: ['VI', 'Virgin Islands, U.S.'],
  },
  {
    name: 'Republic of Ecuador',
    nativeName: 'República del Ecuador',
    cca2: 'EC',
    altSpellings: ['EC', 'Republic of Ecuador', 'República del Ecuador'],
  },
  {
    name: 'Federal Republic of Somalia',
    nativeName: 'جمهورية الصومال‎‎',
    cca2: 'SO',
    altSpellings: [
      'SO',
      'aṣ-Ṣūmāl',
      'Federal Republic of Somalia',
      'Jamhuuriyadda Federaalka Soomaaliya',
      'Jumhūriyyat aṣ-Ṣūmāl al-Fiderāliyya',
    ],
  },
  {
    name: 'Montserrat',
    nativeName: 'Montserrat',
    cca2: 'MS',
    altSpellings: ['MS'],
  },
  {
    name: 'Republic of Honduras',
    nativeName: 'República de Honduras',
    cca2: 'HN',
    altSpellings: ['HN', 'Republic of Honduras', 'República de Honduras'],
  },
  {
    name: "Democratic People's Republic of Korea",
    nativeName: '조선민주주의인민공화국',
    cca2: 'KP',
    altSpellings: [
      'KP',
      "Democratic People's Republic of Korea",
      'DPRK',
      '조선민주주의인민공화국',
      'Chosŏn Minjujuŭi Inmin Konghwaguk',
      "Korea, Democratic People's Republic of",
      '북한',
      '북조선',
    ],
  },
  {
    name: 'Republic of Trinidad and Tobago',
    nativeName: 'Republic of Trinidad and Tobago',
    cca2: 'TT',
    altSpellings: ['TT', 'Republic of Trinidad and Tobago'],
  },
  {
    name: 'Republic of Slovenia',
    nativeName: 'Republika Slovenija',
    cca2: 'SI',
    altSpellings: ['SI', 'Republic of Slovenia', 'Republika Slovenija'],
  },
  {
    name: 'Kingdom of Thailand',
    nativeName: 'ราชอาณาจักรไทย',
    cca2: 'TH',
    altSpellings: [
      'TH',
      'Prathet',
      'Thai',
      'Kingdom of Thailand',
      'ราชอาณาจักรไทย',
      'Ratcha Anachak Thai',
    ],
  },
  {
    name: 'Federated States of Micronesia',
    nativeName: 'Federated States of Micronesia',
    cca2: 'FM',
    altSpellings: ['FM', 'Federated States of Micronesia', 'Micronesia, Federated States of'],
  },
  {
    name: 'Republic of Malta',
    nativeName: 'Republic of Malta',
    cca2: 'MT',
    altSpellings: ['MT', 'Republic of Malta', "Repubblika ta' Malta"],
  },
  {
    name: 'Republic of Haiti',
    nativeName: "République d'Haïti",
    cca2: 'HT',
    altSpellings: ['HT', 'Republic of Haiti', "République d'Haïti", 'Repiblik Ayiti'],
  },
  {
    name: 'Republic of Latvia',
    nativeName: 'Latvijas Republikas',
    cca2: 'LV',
    altSpellings: ['LV', 'Republic of Latvia', 'Latvijas Republika'],
  },
  {
    name: 'Guadeloupe',
    nativeName: 'Guadeloupe',
    cca2: 'GP',
    altSpellings: ['GP', 'Gwadloup'],
  },
  {
    name: 'Department of Mayotte',
    nativeName: 'Département de Mayotte',
    cca2: 'YT',
    altSpellings: ['YT', 'Department of Mayotte', 'Département de Mayotte'],
  },
  {
    name: 'Belize',
    nativeName: 'Belize',
    cca2: 'BZ',
    altSpellings: ['BZ'],
  },
  {
    name: "Republic of Côte d'Ivoire",
    nativeName: "République de Côte d'Ivoire",
    cca2: 'CI',
    altSpellings: [
      'CI',
      "Côte d'Ivoire",
      'Ivory Coast',
      "Republic of Côte d'Ivoire",
      "République de Côte d'Ivoire",
    ],
  },
  {
    name: 'Republic of Zambia',
    nativeName: 'Republic of Zambia',
    cca2: 'ZM',
    altSpellings: ['ZM', 'Republic of Zambia'],
  },
  {
    name: 'Democratic Republic of São Tomé and Príncipe',
    nativeName: 'República Democrática do São Tomé e Príncipe',
    cca2: 'ST',
    altSpellings: [
      'ST',
      'Democratic Republic of São Tomé and Príncipe',
      'Sao Tome and Principe',
      'República Democrática de São Tomé e Príncipe',
    ],
  },
  {
    name: 'Federative Republic of Brazil',
    nativeName: 'República Federativa do Brasil',
    cca2: 'BR',
    altSpellings: [
      'BR',
      'Brasil',
      'Federative Republic of Brazil',
      'República Federativa do Brasil',
    ],
  },
  {
    name: 'Republic of Namibia',
    nativeName: 'Republiek van Namibië',
    cca2: 'NA',
    altSpellings: ['NA', 'Namibië', 'Republic of Namibia'],
  },
  {
    name: 'Republic of Senegal',
    nativeName: 'République du Sénégal',
    cca2: 'SN',
    altSpellings: ['SN', 'Republic of Senegal', 'République du Sénégal'],
  },
  {
    name: 'Independent State of Papua New Guinea',
    nativeName: 'Independent State of Papua New Guinea',
    cca2: 'PG',
    altSpellings: [
      'PG',
      'Independent State of Papua New Guinea',
      'Independen Stet bilong Papua Niugini',
    ],
  },
  {
    name: 'Republic of Seychelles',
    nativeName: 'Repiblik Sesel',
    cca2: 'SC',
    altSpellings: ['SC', 'Republic of Seychelles', 'Repiblik Sesel', 'République des Seychelles'],
  },
  {
    name: 'Kingdom of Lesotho',
    nativeName: 'Kingdom of Lesotho',
    cca2: 'LS',
    altSpellings: ['LS', 'Kingdom of Lesotho', 'Muso oa Lesotho'],
  },
  {
    name: 'Sint Maarten',
    nativeName: 'Sint Maarten',
    cca2: 'SX',
    altSpellings: ['SX', 'Sint Maarten (Dutch part)'],
  },
  {
    name: 'Gabonese Republic',
    nativeName: 'République gabonaise',
    cca2: 'GA',
    altSpellings: ['GA', 'Gabonese Republic', 'République Gabonaise'],
  },
  {
    name: 'Saint Lucia',
    nativeName: 'Saint Lucia',
    cca2: 'LC',
    altSpellings: ['LC'],
  },
  {
    name: 'Republic of Madagascar',
    nativeName: 'République de Madagascar',
    cca2: 'MG',
    altSpellings: [
      'MG',
      'Republic of Madagascar',
      "Repoblikan'i Madagasikara",
      'République de Madagascar',
    ],
  },
  {
    name: 'Republic of Sierra Leone',
    nativeName: 'Republic of Sierra Leone',
    cca2: 'SL',
    altSpellings: ['SL', 'Republic of Sierra Leone'],
  },
  {
    name: 'Republic of Benin',
    nativeName: 'République du Bénin',
    cca2: 'BJ',
    altSpellings: ['BJ', 'Republic of Benin', 'République du Bénin'],
  },
  {
    name: 'Commonwealth of Puerto Rico',
    nativeName: 'Commonwealth of Puerto Rico',
    cca2: 'PR',
    altSpellings: ['PR', 'Commonwealth of Puerto Rico', 'Estado Libre Asociado de Puerto Rico'],
  },
  {
    name: 'Bermuda',
    nativeName: 'Bermuda',
    cca2: 'BM',
    altSpellings: ['BM', 'The Islands of Bermuda', 'The Bermudas', 'Somers Isles'],
  },
  {
    name: 'Republic of Croatia',
    nativeName: 'Republika Hrvatska',
    cca2: 'HR',
    altSpellings: ['HR', 'Hrvatska', 'Republic of Croatia', 'Republika Hrvatska'],
  },
  {
    name: "Lao People's Democratic Republic",
    nativeName: 'ສາທາລະນະ ຊາທິປະໄຕ ຄົນລາວ ຂອງ',
    cca2: 'LA',
    altSpellings: [
      'LA',
      'Lao',
      "Lao People's Democratic Republic",
      'Sathalanalat Paxathipatai Paxaxon Lao',
    ],
  },
  {
    name: 'Russian Federation',
    nativeName: 'Российская Федерация',
    cca2: 'RU',
    altSpellings: ['RU', 'Russian Federation', 'Российская Федерация'],
  },
  {
    name: 'French Republic',
    nativeName: 'République française',
    cca2: 'FR',
    altSpellings: ['FR', 'French Republic', 'République française'],
  },
  {
    name: 'United States of America',
    nativeName: 'United States of America',
    cca2: 'US',
    altSpellings: ['US', 'USA', 'United States of America'],
  },
  {
    name: 'Republic of Burundi',
    nativeName: 'République du Burundi',
    cca2: 'BI',
    altSpellings: ['BI', 'Republic of Burundi', "Republika y'Uburundi", 'République du Burundi'],
  },
  {
    name: 'Republic of Fiji',
    nativeName: 'Republic of Fiji',
    cca2: 'FJ',
    altSpellings: ['FJ', 'Viti', 'Republic of Fiji', 'Matanitu ko Viti', 'Fijī Gaṇarājya'],
  },
  {
    name: "Hong Kong Special Administrative Region of the People's Republic of China",
    nativeName: "Hong Kong Special Administrative Region of the People's Republic of China",
    cca2: 'HK',
    altSpellings: ['HK'],
  },
  {
    name: 'Islamic Republic of Iran',
    nativeName: 'جمهوری اسلامی ایران',
    cca2: 'IR',
    altSpellings: [
      'IR',
      'Islamic Republic of Iran',
      'Iran, Islamic Republic of',
      'Jomhuri-ye Eslāmi-ye Irān',
    ],
  },
  {
    name: 'Sahrawi Arab Democratic Republic',
    nativeName: 'Sahrawi Arab Democratic Republic',
    cca2: 'EH',
    altSpellings: ['EH', 'Taneẓroft Tutrimt'],
  },
  {
    name: 'Collectivity of Saint Barthélemy',
    nativeName: 'Collectivité de Saint-Barthélemy',
    cca2: 'BL',
    altSpellings: [
      'BL',
      'St. Barthelemy',
      'Collectivity of Saint Barthélemy',
      'Collectivité de Saint-Barthélemy',
    ],
  },
  {
    name: 'Republic of Mali',
    nativeName: 'République du Mali',
    cca2: 'ML',
    altSpellings: ['ML', 'Republic of Mali', 'République du Mali'],
  },
  {
    name: 'Republic of Poland',
    nativeName: 'Rzeczpospolita Polska',
    cca2: 'PL',
    altSpellings: ['PL', 'Republic of Poland', 'Rzeczpospolita Polska'],
  },
  {
    name: 'Commonwealth of Dominica',
    nativeName: 'Commonwealth of Dominica',
    cca2: 'DM',
    altSpellings: ['DM', 'Dominique', 'Wai‘tu kubuli', 'Commonwealth of Dominica'],
  },
  {
    name: 'Republic of Lithuania',
    nativeName: 'Lietuvos Respublikos',
    cca2: 'LT',
    altSpellings: ['LT', 'Republic of Lithuania', 'Lietuvos Respublika'],
  },
  {
    name: 'Republic of Azerbaijan',
    nativeName: 'Azərbaycan Respublikası',
    cca2: 'AZ',
    altSpellings: ['AZ', 'Republic of Azerbaijan', 'Azərbaycan Respublikası'],
  },
  {
    name: 'Burkina Faso',
    nativeName: 'République du Burkina',
    cca2: 'BF',
    altSpellings: ['BF'],
  },
  {
    name: 'Republic of Kosovo',
    nativeName: 'Republika e Kosovës',
    cca2: 'XK',
    altSpellings: ['XK', 'Република Косово'],
  },
  {
    name: 'Kingdom of Norway',
    nativeName: 'Kongeriket Noreg',
    cca2: 'NO',
    altSpellings: [
      'NO',
      'Norge',
      'Noreg',
      'Kingdom of Norway',
      'Kongeriket Norge',
      'Kongeriket Noreg',
    ],
  },
  {
    name: 'Republic of China (Taiwan)',
    nativeName: '中華民國',
    cca2: 'TW',
    altSpellings: [
      'TW',
      'Táiwān',
      'Republic of China',
      '中華民國',
      'Zhōnghuá Mínguó',
      'Chinese Taipei',
    ],
  },
  {
    name: 'Republic of Finland',
    nativeName: 'Suomen tasavalta',
    cca2: 'FI',
    altSpellings: ['FI', 'Suomi', 'Republic of Finland', 'Suomen tasavalta', 'Republiken Finland'],
  },
  {
    name: 'Republic of Angola',
    nativeName: 'República de Angola',
    cca2: 'AO',
    altSpellings: ['AO', 'República de Angola', "ʁɛpublika de an'ɡɔla"],
  },
  {
    name: 'Republic of Nauru',
    nativeName: 'Republic of Nauru',
    cca2: 'NR',
    altSpellings: ['NR', 'Naoero', 'Pleasant Island', 'Republic of Nauru', 'Ripublik Naoero'],
  },
  {
    name: 'Republic of Palau',
    nativeName: 'Republic of Palau',
    cca2: 'PW',
    altSpellings: ['PW', 'Republic of Palau', 'Beluu er a Belau'],
  },
  {
    name: 'Territory of Norfolk Island',
    nativeName: 'Territory of Norfolk Island',
    cca2: 'NF',
    altSpellings: ['NF', 'Territory of Norfolk Island', "Teratri of Norf'k Ailen"],
  },
  {
    name: 'Bailiwick of Jersey',
    nativeName: 'Bailiwick of Jersey',
    cca2: 'JE',
    altSpellings: ['JE', 'Bailiwick of Jersey', 'Bailliage de Jersey', 'Bailliage dé Jèrri'],
  },
  {
    name: 'Kingdom of Eswatini',
    nativeName: 'Kingdom of Eswatini',
    cca2: 'SZ',
    altSpellings: [
      'SZ',
      'Swaziland',
      'weSwatini',
      'Swatini',
      'Ngwane',
      'Kingdom of Eswatini',
      'Umbuso weSwatini',
    ],
  },
  {
    name: 'Republic of Serbia',
    nativeName: 'Република Србија',
    cca2: 'RS',
    altSpellings: ['RS', 'Srbija', 'Republic of Serbia', 'Република Србија', 'Republika Srbija'],
  },
  {
    name: 'Tuvalu',
    nativeName: 'Tuvalu',
    cca2: 'TV',
    altSpellings: ['TV'],
  },
  {
    name: 'Republic of Botswana',
    nativeName: 'Republic of Botswana',
    cca2: 'BW',
    altSpellings: ['BW', 'Republic of Botswana', 'Lefatshe la Botswana'],
  },
  {
    name: 'Bailiwick of Guernsey',
    nativeName: 'Bailiwick of Guernsey',
    cca2: 'GG',
    altSpellings: ['GG', 'Bailiwick of Guernsey', 'Bailliage de Guernesey'],
  },
  {
    name: 'Republic of Costa Rica',
    nativeName: 'República de Costa Rica',
    cca2: 'CR',
    altSpellings: ['CR', 'Republic of Costa Rica', 'República de Costa Rica'],
  },
  {
    name: 'Vatican City State',
    nativeName: 'Stato della Città del Vaticano',
    cca2: 'VA',
    altSpellings: [
      'VA',
      'Holy See (Vatican City State)',
      'Vatican City State',
      'Stato della Città del Vaticano',
    ],
  },
  {
    name: 'Hashemite Kingdom of Jordan',
    nativeName: 'المملكة الأردنية الهاشمية',
    cca2: 'JO',
    altSpellings: ['JO', 'Hashemite Kingdom of Jordan', 'al-Mamlakah al-Urdunīyah al-Hāshimīyah'],
  },
  {
    name: 'Republic of Estonia',
    nativeName: 'Eesti Vabariik',
    cca2: 'EE',
    altSpellings: ['EE', 'Eesti', 'Republic of Estonia', 'Eesti Vabariik'],
  },
  {
    name: 'Barbados',
    nativeName: 'Barbados',
    cca2: 'BB',
    altSpellings: ['BB'],
  },
  {
    name: 'Plurinational State of Bolivia',
    nativeName: 'Wuliwya Suyu',
    cca2: 'BO',
    altSpellings: [
      'BO',
      'Buliwya',
      'Wuliwya',
      'Bolivia, Plurinational State of',
      'Plurinational State of Bolivia',
      'Estado Plurinacional de Bolivia',
      'Buliwya Mamallaqta',
      'Wuliwya Suyu',
      'Tetã Volívia',
    ],
  },
  {
    name: 'Togolese Republic',
    nativeName: 'République togolaise',
    cca2: 'TG',
    altSpellings: ['TG', 'Togolese', 'Togolese Republic', 'République Togolaise'],
  },
  {
    name: 'Independent State of Samoa',
    nativeName: 'Independent State of Samoa',
    cca2: 'WS',
    altSpellings: ['WS', 'Independent State of Samoa', 'Malo Saʻoloto Tutoʻatasi o Sāmoa'],
  },
  {
    name: 'Islamic Republic of Mauritania',
    nativeName: 'الجمهورية الإسلامية الموريتانية',
    cca2: 'MR',
    altSpellings: [
      'MR',
      'Islamic Republic of Mauritania',
      'al-Jumhūriyyah al-ʾIslāmiyyah al-Mūrītāniyyah',
    ],
  },
  {
    name: 'New Zealand',
    nativeName: 'New Zealand',
    cca2: 'NZ',
    altSpellings: ['NZ', 'Aotearoa'],
  },
  {
    name: 'Niue',
    nativeName: 'Niue',
    cca2: 'NU',
    altSpellings: ['NU'],
  },
  {
    name: 'Republic of Turkey',
    nativeName: 'Türkiye Cumhuriyeti',
    cca2: 'TR',
    altSpellings: ['TR', 'Turkiye', 'Republic of Turkey', 'Türkiye Cumhuriyeti'],
  },
  {
    name: 'Democratic Republic of Timor-Leste',
    nativeName: 'República Democrática de Timor-Leste',
    cca2: 'TL',
    altSpellings: [
      'TL',
      'East Timor',
      'Democratic Republic of Timor-Leste',
      'República Democrática de Timor-Leste',
      'Repúblika Demokrátika Timór-Leste',
      "Timór Lorosa'e",
      'Timor Lorosae',
    ],
  },
  {
    name: 'Republic of Paraguay',
    nativeName: 'Tetã Paraguái',
    cca2: 'PY',
    altSpellings: ['PY', 'Republic of Paraguay', 'República del Paraguay', 'Tetã Paraguái'],
  },
  {
    name: 'Bosnia and Herzegovina',
    nativeName: 'Bosna i Hercegovina',
    cca2: 'BA',
    altSpellings: ['BA', 'Bosnia-Herzegovina', 'Босна и Херцеговина'],
  },
  {
    name: 'Cayman Islands',
    nativeName: 'Cayman Islands',
    cca2: 'KY',
    altSpellings: ['KY'],
  },
  {
    name: 'Jamaica',
    nativeName: 'Jamaica',
    cca2: 'JM',
    altSpellings: ['JM'],
  },
], 'cca2');

export const COUNTRIES_BY_CCA = mapKeys(COUNTRIES, 'cca2');
