import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BarGraphIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g opacity="0.5">
      <path
        d="M5.5,5.2c0.3,0.2,0.7,0.7,0.8,0.9C6.4,6.4,6.7,6.5,7,6.4C7.2,6.3,7.3,6,7.2,5.8C7,5.3,6,4.1,5.4,4.1l0,0
		C5.2,4.1,5,4.3,4.9,4.5c0,0.1-0.2,0.2-0.3,0.4C4.1,5.4,3.8,5.8,3.7,6.2c0,0.2,0.1,0.4,0.4,0.5h0.1c0.2,0,0.4-0.1,0.5-0.4
		c0-0.2,0.4-0.6,0.6-0.9C5.4,5.4,5.5,5.3,5.5,5.2z"
        fill="currentColor"
      />
      <path
        d="M19.9,17.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.5-0.5-0.9-0.8-1.3-0.8c-0.3-0.1-0.5,0.1-0.6,0.3c-0.1,0.3,0.1,0.5,0.3,0.6
		c0.2,0,0.6,0.4,0.9,0.6c0.1,0.1,0.2,0.2,0.3,0.2c-0.2,0.3-0.7,0.7-0.9,0.8c-0.2,0.1-0.4,0.4-0.3,0.6s0.3,0.3,0.4,0.3s0.1,0,0.2,0
		c0.5-0.2,1.7-1.2,1.7-1.8C20.2,17.8,20.1,17.6,19.9,17.5z"
        fill="currentColor"
      />
      <path
        d="M18.6,18.1c0-0.3-0.2-0.5-0.5-0.5c-0.2,0-0.5,0-1,0c-1,0-2.7,0.1-4.7,0.1c-1.9,0-3.8,0-5.7-0.1c-0.3,0-0.5,0.2-0.5,0.5
		s0.2,0.5,0.5,0.5c1.9,0.1,3.8,0.1,5.7,0.1c2,0,3.7-0.1,4.7-0.1c0.5,0,0.8,0,1,0C18.4,18.6,18.6,18.4,18.6,18.1z"
        fill="currentColor"
      />
      <path
        d="M5.9,6.2c0-0.3-0.2-0.5-0.5-0.5S5,6,5,6.2s0,0.5,0,1c0,1,0,2.7,0,4.7c0,1.9,0,3.8-0.1,5.7c0,0.3,0.2,0.5,0.5,0.5l0,0
		c0.3,0,0.5-0.2,0.5-0.5C6,15.7,6,13.8,6,11.9c0-2,0-3.6,0-4.7C5.9,6.8,5.9,6.4,5.9,6.2z"
        fill="currentColor"
      />
    </g>
    <g>
      <path
        d="M12.1,16.5c0,0-0.1,0-0.1,0l-0.1,0c-0.1,0-0.3,0-0.3,0c-0.1,0-0.4,0-0.5-0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-0.1
		c0-1.1,0-2.1,0-3.1c0-0.9,0-1.8,0-2.8l0-0.2c0-0.2,0.1-0.5,0.5-0.6l0.6,0c0.3,0,0.5,0,0.8,0c0.1,0,0.3,0,0.4,0.1
		c0.2,0.2,0.2,0.5,0.2,0.7c-0.1,1.9-0.1,3.9,0,5.8c0,0,0,0,0,0.2c0,0.4-0.3,0.6-0.5,0.6c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0L12.1,16.5
		C12.2,16.5,12.1,16.5,12.1,16.5z M12,15.5c0,0,0.1,0,0.1,0l0.1,0l0.3,0c-0.1-1.8-0.1-3.7,0-5.5c-0.1,0-0.3,0-0.4,0l-0.1,0
		c0,0.9,0,1.7,0,2.6C11.9,13.6,12,14.5,12,15.5C12,15.5,12,15.5,12,15.5C12,15.5,12,15.5,12,15.5z M12.9,10C12.9,10,12.9,10,12.9,10
		C12.9,10,12.9,10,12.9,10z M11.9,9.7L11.9,9.7C11.9,9.7,11.9,9.7,11.9,9.7z"
        fill="currentColor"
      />
      <path
        d="M14.9,16.5C14.9,16.5,14.9,16.5,14.9,16.5l-0.2,0c0,0-0.3,0-0.3,0c-0.3,0-0.4-0.1-0.5-0.2
		c-0.1-0.1-0.2-0.3-0.1-0.4c0-0.8,0-1.5,0-2.2c0-0.6,0-1.3,0-1.9l0-0.1c0-0.2,0.2-0.5,0.5-0.5l0.6,0c0.3,0,0.5,0,0.8,0
		c0.2,0,0.3,0,0.4,0.1c0.2,0.2,0.2,0.5,0.2,0.6c-0.1,1.3-0.1,2.6,0,3.9c0,0,0,0.1,0,0.1c0,0.3-0.2,0.6-0.5,0.6c-0.1,0-0.1,0-0.2,0
		c0,0-0.1,0-0.1,0L14.9,16.5C15,16.5,15,16.5,14.9,16.5z M14.7,15.9C14.7,15.9,14.7,15.9,14.7,15.9C14.7,15.9,14.7,15.9,14.7,15.9z
		 M14.8,15.5l0.2,0l0.4,0c-0.1-1.2-0.1-2.3,0-3.4c-0.1,0-0.3,0-0.4,0l-0.1,0c0,0.5,0,1.1,0,1.6C14.7,14.3,14.8,14.9,14.8,15.5
		C14.8,15.5,14.8,15.5,14.8,15.5z M14.7,11.7L14.7,11.7C14.7,11.7,14.7,11.7,14.7,11.7z"
        fill="currentColor"
      />
      <path
        d="M9.3,16.5c0,0-0.1,0-0.1,0l-0.1,0c-0.1,0-0.3,0-0.3,0c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1
		c-0.1-0.1-0.2-0.3-0.2-0.5l0-0.1c0-1.3,0-2.5,0-3.8c0-1.1,0-2.3,0-3.4l0-0.2c0-0.1,0.1-0.6,0.5-0.6c0.3,0,0.4,0,0.6,0
		c0.3,0,0.5,0,0.8,0c0.1,0,0.3,0,0.4,0.1c0.2,0.2,0.2,0.5,0.2,0.7c-0.1,2.3-0.1,4.7,0,7.1c0,0,0,0,0,0.2c0,0.6-0.5,0.6-0.5,0.6
		c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1,0L9.3,16.5C9.3,16.5,9.3,16.5,9.3,16.5z M9.1,15.9L9.1,15.9C9.1,15.9,9.1,15.9,9.1,15.9z
		 M9.1,15.5c0,0,0.1,0,0.1,0l0.1,0l0.3,0c-0.1-2.3-0.1-4.6,0-6.9c-0.1,0-0.3,0-0.4,0c0,0-0.1,0-0.1,0c0,1.1,0,2.2,0,3.3
		C9.1,13.1,9.1,14.3,9.1,15.5C9.1,15.5,9.1,15.5,9.1,15.5C9.1,15.5,9.1,15.5,9.1,15.5z M10,8.6c0,0,0.1,0,0.1,0
		C10.1,8.6,10.1,8.6,10,8.6z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default BarGraphIcon;
