import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import useMountedState from 'react-use/lib/useMountedState';

import PrivateRoute from 'src/common/PrivateRoute';
import ApplicantsResolver from 'src/pages/Dashboard/Applicants/ApplicantsResolver';
import CompetitionResolver from 'src/pages/Dashboard/Competitions/CompetitionResolver';
import StatisticsResolver from 'src/pages/Dashboard/Statistics/StatisticsResolver';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import Loader from './common/components/Loader';
import PageWrapper from './common/components/PageWrapper';
import Editions from './pages/Dashboard/Editions/Editions';
import Profile from './pages/Dashboard/Profile/Profile';
import AccommodationsResolver from './pages/Dashboard/System/Accommodations/AccommodationsResolver';
import CompetitionsResolver from './pages/Dashboard/System/Competitions/CompetitionsResolver';
import DistancesResolver from './pages/Dashboard/System/Distances/DistancesResolver';
import TrackMapsResolver from './pages/Dashboard/System/TrackMaps/TrackMapsResolver';
import ContactResolver from './pages/PublicPages/ContactResolver';
import HomeResolver from './pages/PublicPages/HomeResolver';
import DetailsResolver from './pages/PublicPages/Race/DetailsResolver';
import ResultsResolver from './pages/PublicPages/Race/ResultsResolver';
import SignUpResolver from './pages/PublicPages/Race/SignUpResolver';
import TrailsResolver from './pages/PublicPages/Race/TrailsResolver';
import SignIn from './pages/PublicPages/SignIn';
import SponsorsResolver from './pages/PublicPages/SponsorsResolver';
import AccommodationResolver from './pages/PublicPages/UsefulInfo/AccommodationResolver';
import CountTelekiSamuResolver from './pages/PublicPages/UsefulInfo/CountTelekiSamuResolver';
import DirectionsResolver from './pages/PublicPages/UsefulInfo/DirectionsResolver';
import RulesResolver from './pages/PublicPages/UsefulInfo/RulesResolver';
import SovataResolver from './pages/PublicPages/UsefulInfo/SovataResolver';
import IconList from './pages/Util/IconList';

export default function RootRoutes() {
  const isMounted = useMountedState();

  const {
    activeEditionId,
    competitionsById,
    editionsById,
    fetchSystemOptions: doFetchSystemOptions,
  } = useSystemOptionsStore(systemOptionsState => systemOptionsState);

  const selectedEdition =
    activeEditionId && editionsById ? editionsById[activeEditionId] : undefined;

  const [resolved, setResolved] = useState(false);

  const loadDependencies = () => {
    const deps: Array<Promise<void | AxiosResponse<any, any>>> = [
      doFetchSystemOptions(),
    ];
    return Promise.all(deps);
  };

  useEffectOnce(() => {
    loadDependencies()
      .then(() => {
        if (!isMounted()) return;
        setResolved(true);
      })
      .catch(() => {
        if (!isMounted()) return;
      });
  });

  const year = selectedEdition && selectedEdition.year;
  const compId = selectedEdition?.competitions[0].competitionId;
  const competitionName = compId && competitionsById && competitionsById[compId].name;

  return (
    <Router>
      <PageWrapper>
        {resolved ? (
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<HomeResolver />} />

            <Route path="/race" element={<Navigate to="/race/details" />} />
            <Route path="/race/details" element={<DetailsResolver />} />
            <Route path="/race/trails" element={<TrailsResolver />} />
            <Route path="/race/signup" element={<SignUpResolver />} />
            <Route
              path="/race/results"
              element={
                <Navigate
                  to={
                    year && competitionName ? `/race/results/${year}/${competitionName}` : '/race'
                  }
                />
              }
            />
            <Route path="/race/results/:year/:competition" element={<ResultsResolver />} />

            <Route path="/useful-info" element={<Navigate to="/useful-info/rules" />} />
            <Route path="/useful-info/rules" element={<RulesResolver />} />
            <Route path="/useful-info/directions" element={<DirectionsResolver />} />
            <Route path="/useful-info/sovata" element={<SovataResolver />} />
            <Route path="/useful-info/accommodation" element={<AccommodationResolver />} />
            <Route path="/useful-info/count-teleki-samu" element={<CountTelekiSamuResolver />} />

            <Route path="/sponsors" element={<SponsorsResolver />} />

            <Route path="/contact" element={<ContactResolver />} />

            <Route path="/signin" element={<SignIn />} />

            <Route element={<PrivateRoute />}>
              <Route path="/icon-list" element={<IconList />} />
              <Route path="/dashboard" element={<Navigate to="/dashboard/editions" />} />
              <Route path="/dashboard/editions" element={<Editions />} />
              <Route path="/dashboard/applicants" element={<ApplicantsResolver />} />

              <Route
                path="/dashboard/competition"
                element={
                  <Navigate
                    to={
                      year && competitionName
                        ? `/dashboard/competition/${year}/${competitionName}`
                        : '/dashboard/editions'
                    }
                  />
                }
              />
              <Route
                path="/dashboard/competition/:year/:competition"
                element={<CompetitionResolver />}
              />
              <Route
                path="/dashboard/competition/:year/:competition/statistics"
                element={<StatisticsResolver />}
              />

              <Route path="/dashboard/system/accommodations" element={<AccommodationsResolver />} />
              <Route path="/dashboard/system/competitions" element={<CompetitionsResolver />} />
              <Route path="/dashboard/system/distances" element={<DistancesResolver />} />
              <Route path="/dashboard/system/trackMaps" element={<TrackMapsResolver />} />

              <Route path="/dashboard/profile" element={<Profile />} />
            </Route>
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        ) : (
          <Loader />
        )}
      </PageWrapper>
    </Router>
  );
}
