import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PieChartIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g opacity="0.5">
      <path
        d="M11.9,19.8c-1.6,0-3.1-0.4-4.4-1.3c-0.5-0.3-1.7-1.4-2.2-2.6c-0.5-1.4-1-2.8-0.6-4.3c0-0.1,0.1-0.3,0.1-0.4
		c0.1-0.5,0.2-1.1,0.7-1.5c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7C6,10.6,5.9,11,5.8,11.4c0,0.1-0.1,0.3-0.1,0.4
		c-0.4,1.2,0.1,2.5,0.5,3.7c0.4,1,1.4,2,1.9,2.3c1.3,0.9,2.9,1.3,4.7,1.1c1.4-0.1,4.6-1.6,5.3-5c0.1-0.6,0.2-1,0.2-1.5
		c0.1-1-0.2-2.1-0.7-3.5c-0.6-1.4-1.2-2.4-2.4-3c-0.7-0.3-2.5-0.8-3.5-0.7c-0.3,0-0.5,0.1-0.7,0.1c-0.4,0.1-0.8,0.1-1.3,0.2
		C8.4,5.8,7.4,6.6,6.9,7.7C6.8,8,6.5,8.1,6.3,8C6,7.9,5.9,7.6,6,7.3C6.6,6,7.9,5,9.5,4.5c0.5-0.1,0.9-0.2,1.4-0.3
		c0.2,0,0.4-0.1,0.7-0.1c1.2-0.2,3.2,0.4,4,0.7c1.6,0.8,2.3,2.1,2.9,3.5c0.6,1.5,0.9,2.8,0.8,3.9c0,0.5-0.1,1.1-0.2,1.6
		c-0.8,3.7-4.1,5.6-6.2,5.8C12.6,19.8,12.2,19.8,11.9,19.8z"
        fill="currentColor"
      />
    </g>
    <path
      d="M19.2,10.3C19.2,10.3,19.2,10.3,19.2,10.3c0-0.2,0-0.2-0.1-0.3c0,0,0,0-0.1-0.1c-0.1-0.5-0.3-1-0.5-1.6
	c-0.6-1.4-1.3-2.7-2.9-3.5c-0.6-0.3-2-0.7-3.2-0.8c0,0,0,0,0,0c-0.3,0-0.5,0.2-0.5,0.4c-0.1,1.3-0.2,2.6-0.3,4.1c0,1.4,0,2.8,0,4.1
	c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c1.3-0.4,2.3-0.7,3.2-1.1
	c0.8-0.3,1.7-0.7,2.8-1.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0C19.1,10.9,19.2,10.6,19.2,10.3z M15.3,11.2
	c-0.8,0.3-1.7,0.6-2.8,0.9c0-1.1,0-2.2,0-3.5c0-1.2,0.1-2.4,0.2-3.5c0.9,0.1,2,0.4,2.4,0.6c1.2,0.6,1.9,1.6,2.4,3
	c0.2,0.4,0.3,0.8,0.4,1.2C17,10.5,16.1,10.9,15.3,11.2z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default PieChartIcon;
