import { AxiosResponse } from 'axios';
import { mapKeys } from 'lodash-es';
import { create } from 'zustand';

import { SECURE_API } from 'src/api/api';
import { Competition } from 'src/interfaces/Competition';
import { IEdition } from 'src/interfaces/Edition';
import { IRole } from 'src/interfaces/Role';
import { TechnicalType } from 'src/interfaces/TechnicalType';
import { AccommodationSlice, createAccommodationsSlice } from './slices/createAccommodationsSlice';
import { EditionSlice, createEditionsSlice } from './slices/createEditionsSlice';
import { DistanceSlice, createDistancesSlice } from './slices/createDistancesSlice';
import { CompetitionSlice, createCompetitionsSlice } from './slices/createCompetitionsSlice';

export interface ISystemOptionsState {
  dateFormat: string;
  dateTimeFormat: string;
  roles: IRole[];
  rolesById?: { [key: string]: IRole };
  timeFormat: string;

  isLoading: boolean;
  fetchRoles: () => Promise<void | AxiosResponse<null>>;
  fetchSystemOptions: () => Promise<void | AxiosResponse<null>>;
  setDateTimeFormat: (dateFormat: string, timeFormat: string) => void;
}

const useSystemOptionsStore = create<
  ISystemOptionsState & AccommodationSlice & DistanceSlice & EditionSlice & CompetitionSlice
>()((...props) => {
  const [set] = props;

  return {
    ...createAccommodationsSlice(...props),
    ...createCompetitionsSlice(...props),
    ...createDistancesSlice(...props),
    ...createEditionsSlice(...props),
    dateFormat: 'YYYY.MM.DD.',
    dateTimeFormat: 'YYYY.MM.DD. HH:mm',
    roles: [],
    timeFormat: 'HH:mm',

    isLoading: false,

    fetchRoles: async () => {
      set({ isLoading: true });
      return SECURE_API.get<IRole[]>(`roles/`)
        .then(({ data }) => {
          set({
            roles: data,
            rolesById: mapKeys(data, 'id'),
            isLoading: false,
          });
        })
        .catch((error: string) => {
          set({
            roles: [],
            rolesById: undefined,
            isLoading: false,
          });
          console.log({ error });
        });
    },
    fetchSystemOptions: async () => {
      set({ isLoading: true });
      return SECURE_API.get<{
        activeEdition: {
          id: string;
          activeEditionId?: string;
        };
        accommodations: TechnicalType[];
        competitions: Competition[];
        distances: TechnicalType[];
        editions: IEdition[];
      }>('systemOptions/')
        .then(({ data: { activeEdition, accommodations, competitions, distances, editions } }) => {
          set({
            activeEditionId: activeEdition.activeEditionId,
            accommodations: accommodations,
            accommodationsById: mapKeys(accommodations, 'id'),
            accommodationsByName: mapKeys(accommodations, 'name'),
            competitions: competitions,
            competitionsById: mapKeys(competitions, 'id'),
            competitionsByName: mapKeys(competitions, 'name'),
            distances: distances,
            distancesById: mapKeys(distances, 'id'),
            distancesByName: mapKeys(distances, 'name'),
            editions: editions,
            editionsById: mapKeys(editions, 'id'),
            editionsByYear: mapKeys(editions, 'year'),
            isLoading: false,
          });
        })
        .catch((error: string) => {
          set({
            activeEditionId: undefined,
            accommodations: [],
            accommodationsById: undefined,
            accommodationsByName: undefined,
            competitions: [],
            competitionsById: undefined,
            competitionsByName: undefined,
            distances: [],
            distancesById: undefined,
            distancesByName: undefined,
            editions: [],
            editionsById: undefined,
            editionsByYear: undefined,
            isLoading: false,
          });
          console.log({ error });
        });
    },
    setDateTimeFormat: (dateFormat: string, timeFormat: string) =>
      set({ dateFormat, dateTimeFormat: `${dateFormat} ${timeFormat}`, timeFormat }),
  };
});

export default useSystemOptionsStore;
