import { styled } from '@mui/material/styles';
import MuiBox from '@mui/material/Box';

export const ModalWrapper = styled(MuiBox)(
  () => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    boxShadow: 24px;
  `,
);
