import { MouseEvent } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import map from 'lodash-es/map';
import { useTranslation } from 'react-i18next';

import TableHead from 'src/common/components/table/TableHead';
import { HeadCell } from 'src/interfaces/Table';
import { ITrackMap } from 'src/interfaces/TrackMap';
import TrackMapTableRow from './TrackMapTableRow';

export interface Props {
  trackMaps: ITrackMap[];
  onDeleteTrackMap: (trackMapId: string, e?: MouseEvent<unknown>) => void;
  onEditTrackMap: (trackMapId: string, e?: MouseEvent<unknown>) => void;
}

const TrackMapsTable = ({ onDeleteTrackMap, onEditTrackMap, trackMaps }: Props) => {
  const { t } = useTranslation();

  const headCells: readonly HeadCell<{}>[] = [
    {
      id: 'name',
      label: t('system.trackMaps.nameLabel'),
    },
    {
      id: 'distance',
      label: t('system.trackMaps.distance'),
      width: '220px',
    },
    {
      id: 'route',
      label: t('system.trackMaps.routeLabel'),
      width: '120px',
    },
    {
      id: 'totalDistance',
      label: t('system.trackMaps.routeLengthLabel'),
      width: '120px',
    },
    {
      id: 'levelDiff',
      label: t('system.trackMaps.levelLabel'),
      width: '220px',
    },
    {
      id: 'time',
      label: t('system.trackMaps.timeLabel'),
      width: '220px',
    },
  ];

  return (
    <Paper sx={{ width: '100%', mb: 2, mt: 2, p: 2 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 950 }}
          aria-label={t('system.trackMaps.trackMapsTableLabel')}
          size="medium"
        >
          <TableHead<{}>
            withOptions
            optionsLabel={t('system.trackMaps.optionsLabel')}
            optionsCellWidth="120px"
            headCells={headCells}
          />
          <TableBody>
            {map(trackMaps, trackMap => (
              <TrackMapTableRow
                key={trackMap.id}
                trackMap={trackMap}
                optionElement={
                  <>
                    <Tooltip title={t('system.trackMaps.deleteTrackMap')}>
                      <IconButton size="small" onClick={e => onDeleteTrackMap(trackMap.id, e)} color="error">
                        <DeleteForeverIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('system.trackMaps.editTrackMap')}>
                      <IconButton size="small" onClick={e => onEditTrackMap(trackMap.id, e)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TrackMapsTable;
