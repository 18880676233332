import { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PaymentsIcon from '@mui/icons-material/Payments';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import CompetitionsList from 'src/common/components/CompetitionsList';
import { IEdition } from 'src/interfaces/Edition';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import Loader from 'src/common/components/Loader';

interface Props {
  edition: IEdition;
  isLoading?: boolean;
  optionElement?: JSX.Element;
}

const EditionTableRow = ({
  edition: {
    checkinsByPrice,
    competitions: editionCompetitions,
    date,
    id,
    signupDeadline,
    isSignupOpen,
    totalPrice,
    year,
  },
  isLoading,
  optionElement,
}: Props) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { dateFormat, dateTimeFormat } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const [competitionsOpen, setCompetitionsOpen] = useState(false);
  const [totalsOpen, setTotalsOpen] = useState(false);

  return (
    <>
      {isLoading ? (
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          sx={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: palette.primary.main,
            position: 'relative',
          }}
        >
          <TableCell colSpan={5}>
            <Loader isLoading={isLoading} height="160px" />
          </TableCell>
        </TableRow>
      ) : null}
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: palette.primary.main,
          position: 'relative',
        }}
      >
        <TableCell>{year}</TableCell>
        <TableCell>{moment(date).format(dateFormat)}</TableCell>
        <TableCell>{moment(signupDeadline).format(dateTimeFormat)}</TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" color={palette.grey[600]}>
              {t('editions.competitions')}:
            </Typography>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setCompetitionsOpen(!competitionsOpen)}
            >
              {competitionsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" color={palette.grey[600]}>
              {t('editions.totals')}:
            </Typography>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setTotalsOpen(!totalsOpen)}
            >
              {totalsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
        {!!optionElement && <TableCell align="right">{optionElement}</TableCell>}
      </TableRow>
      <TableRow>
        <TableCell colSpan={6}>
          <Collapse in={competitionsOpen} timeout="auto" unmountOnExit>
            <CompetitionsList
              competitions={editionCompetitions}
              isSignupOpen={moment().isBefore(signupDeadline) && isSignupOpen}
            />
          </Collapse>
          {checkinsByPrice ? (
            <Collapse in={totalsOpen} timeout="auto" unmountOnExit>
              <List sx={{ width: '100%', pt: 1, pb: 0, flexGrow: 1 }}>
                {Object.entries(checkinsByPrice).map(([price, count]) => (
                  <ListItem key={price}>
                    <ListItemText
                      primary={t('checkins.checkinsCount', { count })}
                      secondary={t('base.currencyValue', { count: Number(price) })}
                    />
                  </ListItem>
                ))}
                <ListItem>
                  <ListItemIcon>
                    <PaymentsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('checkins.total')}
                    secondary={t('base.currencyValue', {
                      count: totalPrice,
                    })}
                  />
                </ListItem>
              </List>
            </Collapse>
          ) : null}
        </TableCell>
      </TableRow>
    </>
  );
};

export default EditionTableRow;
