import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HashTag = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M17.7,9.3c-0.6,0-1.3,0.1-2.1,0.1c0.3-0.7,0.5-1.4,0.8-1.9c0.1-0.2,0-0.5-0.2-0.6c-0.2-0.1-0.5,0-0.6,0.2
	c-0.3,0.7-0.7,1.5-0.9,2.3c-1.2,0-2.4,0.1-3.5,0.1c0-0.2,0.1-0.3,0.1-0.5c0.1-0.2,0.1-0.3,0.1-0.4c0.2-0.5,0.3-1,0.5-1.5l0.1-0.5
	c0.1-0.3-0.1-0.5-0.3-0.6C11.3,6.1,11,6.2,11,6.5l-0.1,0.5c-0.2,0.5-0.3,1-0.5,1.6c0,0,0,0.2-0.1,0.4c-0.1,0.3-0.1,0.5-0.2,0.7
	c-0.2,0-0.4,0-0.6,0c-1.1,0-1.8,0-2,0.1C7.2,9.7,7,10,7,10.2c0,0.3,0.2,0.4,0.5,0.4c0,0,0,0,0,0c0.2,0,0.9,0,2-0.1
	c0.1,0,0.2,0,0.3,0c-0.3,1.3-0.6,2.4-0.9,3.3c-0.9,0-1.8,0-2.6,0c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5c0.8,0,1.6,0,2.3,0
	c-0.5,1.8-0.8,2.4-0.9,2.6c-0.1,0.2-0.1,0.5,0.1,0.7c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.4-0.2c0.3-0.4,0.6-1.5,1.1-3.2
	c1.1,0,2.3-0.1,3.5-0.2c-0.3,1.4-0.5,2.7-0.7,3.4c0,0.3,0.1,0.5,0.4,0.6c0,0,0.1,0,0.1,0c0.2,0,0.4-0.2,0.5-0.4
	c0.2-1.3,0.5-2.5,0.7-3.6c1-0.1,1.9-0.1,2.9-0.2c0.3,0,0.5-0.3,0.4-0.5s-0.3-0.4-0.5-0.4c-0.9,0.1-1.8,0.1-2.6,0.2
	c0.3-1.2,0.6-2.3,0.9-3.2c0.9,0,1.8-0.1,2.5-0.1c0.3,0,0.5-0.3,0.4-0.5S17.9,9.3,17.7,9.3z M13.4,13.7c-1.2,0.1-2.3,0.1-3.5,0.2
	c0.3-0.9,0.6-2,0.9-3.3c1.1,0,2.3-0.1,3.4-0.1C13.9,11.5,13.6,12.6,13.4,13.7z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default HashTag;
