import mapKeys from "lodash-es/mapKeys";

export enum Gender {
  MALE = 'M',
  FEMALE = 'F',
}

export const GENDERS = [
  {
    id: '0',
    name: Gender.MALE,
  },
  {
    id: '1',
    name: Gender.FEMALE,
  },
];

export const GENDERS_BY_NAME = mapKeys(GENDERS, 'name');
