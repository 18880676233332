import { AxiosResponse } from 'axios';
import { create } from 'zustand';

import { SECURE_API } from 'src/api/api';
import { LanguageKeys } from 'src/common/constants/languages';
import { IApplicant } from 'src/interfaces/Applicant';
import { IFormApplicant } from 'src/pages/Dashboard/Applicants/forms/ApplicantEditorForm';

export interface IApplicantsState {
  fetchApplicants: () => Promise<void | AxiosResponse<null>>;
  deleteApplicant: (id: string) => Promise<void | AxiosResponse<null>>;
  deleteAllApplicants: () => Promise<void | AxiosResponse<null>>;
  applicants: IApplicant[];
  isLoadingApplicants: boolean;
  resetApplicants: () => void;
  saveApplicant: (
    contestant: IFormApplicant,
    id?: string,
    lang?: LanguageKeys,
  ) => Promise<void | AxiosResponse<IApplicant[]>>;
}

const useApplicantsStore = create<IApplicantsState>(set => {
  const getApplicants = async () => {
    return SECURE_API.get<IApplicant[]>('applicants/')
      .then(({ data }) => {
        set({ applicants: data, isLoadingApplicants: false });
      })
      .catch((error: string) => {
        set({ applicants: [], isLoadingApplicants: false });
        console.log({ error });
      });
  };

  return {
    applicants: [],
    isLoadingApplicants: false,
    fetchApplicants: async () => {
      set({ isLoadingApplicants: true });
      return getApplicants();
    },
    saveApplicant: async (contestant: IFormApplicant, id?: string, lang?: LanguageKeys) => {
      set({ isLoadingApplicants: true });
      const saveApplicantPromise = id
        ? SECURE_API.put<IApplicant[]>(`applicants/${id}`, contestant)
        : SECURE_API.post<IApplicant[]>(
            `applicants${lang ? '?lang=' + lang : ''}`,
            contestant,
          );

      return saveApplicantPromise
        .finally(() => {
          getApplicants();
        });
    },
    deleteApplicant: async id => {
      set({ isLoadingApplicants: true });
      return SECURE_API.delete<null>(`applicants/one/${id}`)
        .finally(() => {
          getApplicants();
        });
    },
    deleteAllApplicants: async () => {
      set({ isLoadingApplicants: true });
      return SECURE_API.delete<null>(`applicants/bulk`)
        .finally(() => {
          getApplicants();
        });
    },
    resetApplicants: () => set({ applicants: [] }),
  };
});

export default useApplicantsStore;
