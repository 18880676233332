import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { IApplicant } from 'src/interfaces/Applicant';
import ApplicantEditorForm, {
  IFormApplicant,
} from 'src/pages/Dashboard/Applicants/forms/ApplicantEditorForm';

interface Props extends DialogProps {
  handleClose: () => void;
  handleSave: (editedApplicant: IFormApplicant) => void;
  selectedApplicant?: IApplicant;
}

const ApplicantEditorModal = ({
  id,
  open,
  handleClose,
  handleSave,
  selectedApplicant,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} aria-labelledby={`${id}-modal-title`} fullScreen>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label={t('base.close')}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" id={`${id}-modal-title`}>
            {!!selectedApplicant
              ? t('applicants.editApplicantModalTitle', {
                  name: `${selectedApplicant.lastName} ${selectedApplicant.firstName}`,
                })
              : t('applicants.addApplicantModalTitle')}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <ApplicantEditorForm
          handleSave={handleSave}
          handleClose={handleClose}
          selectedApplicant={selectedApplicant}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ApplicantEditorModal;
