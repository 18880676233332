import { AxiosResponse } from 'axios';

import Resolver from 'src/common/Resolver';
import systemOptionsStore from 'src/stores/systemOptionsStore';
import Accommodations from './Accommodations';

const AccommodationsResolver = () => {
  const { fetchAccommodations: doFetchAccommodations } = systemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const loadDependencies = () => {
    const deps: Array<Promise<void | AxiosResponse<any, any>>> = [doFetchAccommodations()];

    return Promise.all(deps);
  };

  return <Resolver resolve={loadDependencies} successComponent={Accommodations} />;
};

export default AccommodationsResolver;
