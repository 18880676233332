import { MouseEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { find, map } from 'lodash-es';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useTitle from 'react-use/lib/useTitle';

import { useAuth } from 'src/auth/useAuth';
import PageBox from 'src/common/components/PageBox';
import ViewSwitch from 'src/common/components/ViewSwitch';
import AppBar from 'src/common/components/styled/AppBar';
import {
  PERSISTENT_KEY_SELECTED_EDITION,
  PERSISTENT_KEY_VIEW,
} from 'src/common/constants/persistentStorageKeys';
import { IEdition } from 'src/interfaces/Edition';
import { GRID_VIEW, TABLE_VIEW, View } from 'src/interfaces/View';
import useCheckinsStore from 'src/stores/checkinsStore';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import EditionCard from './components/EditionCard';
import { IFormEdition } from './forms/EditionEditorForm';
import EditionEditorModal from './modals/EditionEditorModal';
import EditionsTable from './tables/EditionsTable';

const Editions = () => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(`${t('editions.pageTitle')} - ${t('base.defaultTitle')}`);

  const {
    activeEditionId,
    editions,
    deleteEdition: doDeleteEdition,
    deleteAllEditions: doDeleteAllEditions,
    isLoading: isLoadingSystemOptions,
    saveEdition: doSaveEdition,
    selectedEdition,
    setSelectedEdition,
    toggleActiveEdition: doToggleActiveEdition,
    saveStatisticsForEdition: doSaveStatisticsForEdition,
  } = useSystemOptionsStore(systemOptionsState => systemOptionsState);
  const { fetchStatistics: doFetchStatistics } = useCheckinsStore(
    checkinsState => checkinsState,
  );

  const { isSuperAdmin } = useAuth();

  const lsView = localStorage.getItem(PERSISTENT_KEY_VIEW);

  const [view, setView] = useState<View>((lsView as View) || GRID_VIEW);
  const [editionEditorModalOpen, setEditionEditorModalOpen] = useState(false);
  const [editionToEdit, setEditionToEdit] = useState<IEdition | undefined>(undefined);
  const [isLoadingEdition, setIsLoadingEdition] = useState<{[key: string]: boolean}>();

  useEffect(() => {
    if (view) {
      localStorage.setItem(PERSISTENT_KEY_VIEW, view);
    } else {
      localStorage.removeItem(PERSISTENT_KEY_VIEW);
    }
  }, [view]);

  useEffect(() => {
    if (selectedEdition) {
      localStorage.setItem(PERSISTENT_KEY_SELECTED_EDITION, JSON.stringify(selectedEdition));
    } else {
      localStorage.removeItem(PERSISTENT_KEY_SELECTED_EDITION);
    }
  }, [selectedEdition]);

  const handleOpenEditionEditorModal = (id?: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    const edition = find(editions, { id });
    setEditionToEdit(edition);
    setEditionEditorModalOpen(true);
  };

  const handleCloseEditionEditorModal = () => {
    setEditionToEdit(undefined);
    setEditionEditorModalOpen(false);
  };

  const saveEdition = async (editedEdition: IFormEdition, id?: string) => {
    await doSaveEdition(editedEdition, id).then(() =>
      enqueueSnackbar(t('editions.saveSuccessful'), {
        variant: 'success',
      }),
    );
    handleCloseEditionEditorModal();
  };

  const handleDeleteEdition = async (id: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('editions.deleteConfirm'),
        description: t('editions.deleteDescription'),
        confirmationText: t('editions.confirmationText'),
        cancellationText: t('editions.cancellationText'),
      });
      await doDeleteEdition(id);

      enqueueSnackbar(t('editions.deleteSuccessfulMessage'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('editions.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const handleDeleteAllEditions = async (e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('editions.deleteAllConfirm'),
        description: t('editions.deleteDescription'),
        confirmationText: t('editions.confirmationText'),
        cancellationText: t('editions.cancellationText'),
      });
      await doDeleteAllEditions()
        .then(() => {
          enqueueSnackbar(t('editions.deleteSuccessfulMessage'), {
            variant: 'success',
          });
        })
        .catch(
          ({
            response: {
              data: { errorCode, message },
            },
          }) => {
            enqueueSnackbar(errorCode ? t(`editions.errors.${errorCode}`) : message, {
              variant: 'error',
            });
          },
        );
    } catch (error) {
      enqueueSnackbar(t('editions.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const handleToggleActiveEdition = async (id: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    const isActive = id === activeEditionId;
    try {
      await confirm({
        title: isActive
          ? t('editions.deactivateEditionConfirm')
          : t('editions.activateEditionConfirm'),
        description: t('editions.toggleEditionDescription'),
        confirmationText: t('editions.confirmationText'),
        cancellationText: t('editions.cancellationText'),
      });
      await doToggleActiveEdition(id);

      enqueueSnackbar(
        isActive
          ? t('editions.deactivateEditionSuccessfulMessage')
          : t('editions.activateEditionSuccessfulMessage'),
        {
          variant: 'success',
        },
      );
    } catch (error) {
      enqueueSnackbar(
        isActive
          ? t('editions.deactivateEditionErrorMessage')
          : t('editions.activateEditionErrorMessage'),
        {
          variant: 'error',
        },
      );
    }
  };

  const handleSaveStatisticsForEdition = async (id: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    const { checkinsByPrice, statistics, totalPrice } = await doFetchStatistics(id);
    if (checkinsByPrice && statistics && totalPrice) {
      doSaveStatisticsForEdition(id, {
        checkinsByPrice,
        statistics,
        totalPrice,
      });
    }

    setIsLoadingEdition({[id]: false});
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar
          component={Paper}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(3px)',
            mb: 2,
            mt: 2,
            p: 1,
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" component="div" sx={{ mr: 1 }}>
              {t('editions.pageTitle')}
            </Typography>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => handleOpenEditionEditorModal()}
              startIcon={<AddIcon />}
            >
              {t('editions.new')}
            </Button>
            {isSuperAdmin ? (
              <Tooltip title={t('editions.deleteAllEditions')}>
                <IconButton onClick={handleDeleteAllEditions} color="error" sx={{ ml: 1 }}>
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
          <ViewSwitch view={view} onSetView={setView} />
        </Toolbar>
      </AppBar>
      <PageBox isLoading={isLoadingSystemOptions}>
        {!!editions.length && (
          <>
            {view === GRID_VIEW && (
              <Grid container spacing={2}>
                {map(editions, (edition, index) => (
                  <Grid item xs={12} md={6} key={edition.id}>
                    <EditionCard
                      isLoading={isLoadingEdition ? isLoadingEdition[edition.id] : false}
                      activeEditionId={activeEditionId}
                      edition={edition}
                      selectedEditionId={selectedEdition?.id}
                      onDeleteEdition={handleDeleteEdition}
                      onEditEdition={handleOpenEditionEditorModal}
                      onSaveEdition={saveEdition}
                      onSelectEdition={setSelectedEdition}
                      onToggleActiveEdition={handleToggleActiveEdition}
                      onSaveStatistics={handleSaveStatisticsForEdition}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {view === TABLE_VIEW && (
              <EditionsTable
                activeEditionId={activeEditionId}
                editions={editions}
                isLoading={isLoadingEdition}
                selectedEditionId={selectedEdition?.id}
                onDeleteEdition={handleDeleteEdition}
                onEditEdition={handleOpenEditionEditorModal}
                onSaveEdition={saveEdition}
                onSaveStatistics={handleSaveStatisticsForEdition}
                onSelectEdition={setSelectedEdition}
                onToggleActiveEdition={handleToggleActiveEdition}
              />
            )}
          </>
        )}
      </PageBox>

      {editionEditorModalOpen && (
        <EditionEditorModal
          id="edition-editor"
          open={editionEditorModalOpen}
          handleClose={handleCloseEditionEditorModal}
          handleSave={saveEdition}
          editionToEdit={editionToEdit}
        />
      )}
    </>
  );
};

export default Editions;
