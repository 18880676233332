import { MouseEvent, useEffect, useState } from 'react';

import find from 'lodash-es/find';
import groupBy from 'lodash-es/groupBy';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import useTitle from 'react-use/lib/useTitle';

import PageBox from 'src/common/components/PageBox';
import { LanguageKeys } from 'src/common/constants/languages';
import { PERSISTENT_KEY_VIEW } from 'src/common/constants/persistentStorageKeys';
import { ECheckinTimerType, IBulkCheckinTimer, ICheckin } from 'src/interfaces/Checkin';
import { Competition as CompetitionType } from 'src/interfaces/Competition';
import { GRID_VIEW, View } from 'src/interfaces/View';
import { IFormCheckin } from 'src/pages/Dashboard/Applicants/forms/CheckinForm';
import CheckinModal from 'src/pages/Dashboard/Applicants/modals/CheckinModal';
import CheckinSerialModal from 'src/pages/Dashboard/Applicants/modals/CheckinSerialModal';
import useCheckinsStore from 'src/stores/checkinsStore';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import CheckinDistance from './components/CheckinDistance';

export interface Props {
  competition: CompetitionType;
  distances: string[];
  editionId: string;
}

const Competition = ({ competition, distances, editionId }: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const { distancesById, activeEditionId, editionsById } = useSystemOptionsStore(systemOptionsState => systemOptionsState);

  const {
    checkins,
    deleteCheckin: doDeleteCheckin,
    fetchCheckinsByCompetitionId: doFetchCheckinsByCompetitionId,
    handleTimer: doHandleTimer,
    handleBulkTimer: doHandleBulkTimer,
    isLoadingCheckins,
    resetCheckins: doResetCheckins,
    resetCounter: doResetCounter,
    saveCheckin: doSaveCheckin,
  } = useCheckinsStore(checkinsState => checkinsState);

  const currentEdition =
    editionsById && activeEditionId ? editionsById[activeEditionId] : undefined;

  useTitle(
    `${t('competitions.pageTitle', { event: competition.i18n[currentLang] })} - ${t(
      'base.defaultTitle',
    )}`,
  );

  const [newCheckin, setNewCheckin] = useState<ICheckin | undefined>();
  const [checkinModalOpen, setCheckinModalOpen] = useState(false);
  const [selectedCheckin, setSelectedCheckin] = useState<ICheckin | undefined>(undefined);

  const lsView = localStorage.getItem(PERSISTENT_KEY_VIEW);
  const [view, setView] = useState<View>((lsView as View) || GRID_VIEW);

  useEffectOnce(() => {
    return () => {
      doResetCheckins();
    };
  });

  useEffect(() => {
    if (view) {
      localStorage.setItem(PERSISTENT_KEY_VIEW, view);
    } else {
      localStorage.removeItem(PERSISTENT_KEY_VIEW);
    }
  }, [view]);

  const handleDelete = async (id: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('checkins.deleteConfirm'),
        description: t('checkins.deleteDescription'),
        confirmationText: t('checkins.confirmationText'),
        cancellationText: t('checkins.cancellationText'),
      });
      await doDeleteCheckin(id)
        .then(() => {
          enqueueSnackbar(t('checkins.deleteSuccessfulMessage'), {
            variant: 'success',
          });
        })
        .catch(
          ({
            response: {
              data: { errorCode, message },
            },
          }) => {
            enqueueSnackbar(errorCode ? t(`signIn.errors.${errorCode}`) : message, {
              variant: 'error',
            });
          },
        );
    } catch (error) {
      enqueueSnackbar(t('checkins.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const handleOpenCheckinModal = (id?: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    const checkin = find(checkins, { id });
    setSelectedCheckin(checkin);
    setCheckinModalOpen(true);
  };

  const handleResetCounter = async (dist: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('counters.resetAllCounters'),
        description: t('counters.confirmResetCounters'),
        confirmationText: t('counters.confirmationText'),
        cancellationText: t('counters.cancellationText'),
      });
      if (currentEdition) {
        await doResetCounter({
          competitionId: competition.id,
          distanceId: dist,
          editionId: currentEdition.id,
        });
      }
      enqueueSnackbar(t('counters.resetSuccessfulMessage'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('counters.resetErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const handleTimer = async (
    id: string,
    type: ECheckinTimerType,
    timeStamp: Date = moment().toDate(),
  ) => {
    const selectedCheckin = find(checkins, { id });

    if (!selectedCheckin) return;

    const { startTime, finishTime } = selectedCheckin;

    let confirmTitle: string = '';
    let confirmationText: string = '';
    let snackbarSuccess: string = '';
    let snackbarError: string = '';

    switch (type) {
      case ECheckinTimerType.start:
        confirmTitle = !startTime ? t('timers.startStartTitle') : t('timers.updateStartTitle');
        confirmationText = !startTime
          ? t('timers.startStartConfirm')
          : t('timers.updateStartConfirm');
        snackbarSuccess = !startTime
          ? t('timers.startStartSuccess')
          : t('timers.updateStartSuccess');
        snackbarError = !startTime ? t('timers.startStartError') : t('timers.updateStartError');
        break;
      case ECheckinTimerType.finish:
        confirmTitle = !finishTime ? t('timers.startFinishTitle') : t('timers.updateFinishTitle');
        confirmationText = !finishTime
          ? t('timers.startFinishConfirm')
          : t('timers.updateFinishConfirm');
        snackbarSuccess = !finishTime
          ? t('timers.startFinishSuccess')
          : t('timers.updateFinishSuccess');
        snackbarError = !finishTime ? t('timers.startFinishError') : t('timers.updateFinishError');
        break;
      case ECheckinTimerType.clear:
        confirmTitle = t('timer.clearTitle');
        confirmationText = t('timer.clearConfirm');
        snackbarSuccess = t('timer.clearSuccess');
        snackbarError = t('timer.clearError');
        break;

      default:
        break;
    }

    try {
      await confirm({
        title: confirmTitle,
        confirmationText,
        cancellationText: t('timers.cancel'),
      });
      await doHandleTimer({
        id: selectedCheckin.id,
        timeStamp,
        type,
      });
      enqueueSnackbar(snackbarSuccess, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(snackbarError, {
        variant: 'error',
      });
    }
  };

  const handleBulkTimer = async (payload: Omit<IBulkCheckinTimer, 'editionId'>) => {
    let confirmTitle: string = '';
    let confirmationText: string = '';
    let snackbarSuccess: string = '';
    let snackbarError: string = '';

    switch (payload.type) {
      case ECheckinTimerType.start:
        confirmTitle = t('timers.bulkStartTitle');
        confirmationText = t('timers.bulkStartConfirm');
        snackbarSuccess = t('timers.bulkStartSuccess');
        snackbarError = t('timers.bulkStartError');
        break;
      case ECheckinTimerType.finish:
        confirmTitle = t('timers.bulkFinishTitle');
        confirmationText = t('timers.bulkFinishConfirm');
        snackbarSuccess = t('timers.bulkFinishSuccess');
        snackbarError = t('timers.bulkFinishError');
        break;
      case ECheckinTimerType.clear:
        confirmTitle = t('timers.bulkClearTitle');
        confirmationText = t('timers.bulkClearConfirm');
        snackbarSuccess = t('timers.bulkClearSuccess');
        snackbarError = t('timers.bulkClearError');
        break;

      default:
        break;
    }

    try {
      await confirm({
        title: confirmTitle,
        confirmationText,
        cancellationText: t('timers.cancel'),
      });
      await doHandleBulkTimer({
        ...payload,
        editionId,
      });
      enqueueSnackbar(snackbarSuccess, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(snackbarError, {
        variant: 'error',
      });
    }
  };

  const handleCloseCheckinModal = () => {
    setSelectedCheckin(undefined);
    setCheckinModalOpen(false);
  };

  const saveCheckin = async (checkin: IFormCheckin, id?: string) => {
    await doSaveCheckin(checkin, id)
      .then(newCheckin => {
        doFetchCheckinsByCompetitionId(editionId, competition.id);
        enqueueSnackbar(t('checkins.saveCheckinSuccess'), {
          variant: 'success',
        });
        setNewCheckin(newCheckin || undefined);
      })
      .catch(
        ({
          response: {
            data: { errorCode, message },
          },
        }) => {
          enqueueSnackbar(errorCode ? t(`checkins.errors.${errorCode}`) : message, {
            variant: 'error',
          });
        },
      )
      .finally(() => handleCloseCheckinModal());
  };

  if (!distancesById) return null;

  const checkinsByDistance = groupBy(checkins, 'distance');

  return (
    <>
      <PageBox isLoading={isLoadingCheckins}>
        {distances.map(distanceId => {
          return (
            <CheckinDistance
              key={distanceId}
              checkins={checkinsByDistance[distanceId]}
              competitionId={competition.id}
              distanceId={distanceId}
              onClickBulkTimer={handleBulkTimer}
              onClickTimer={handleTimer}
              onDeleteCheckin={handleDelete}
              onEditCheckin={handleOpenCheckinModal}
              onResetCounter={handleResetCounter}
              onSetView={setView}
              view={view}
            />
          );
        })}
      </PageBox>

      <CheckinSerialModal
        open={!!newCheckin}
        handleClose={() => {
          setNewCheckin(undefined);
        }}
        serialNo={newCheckin?.ser}
      />

      {checkinModalOpen && !!selectedCheckin && (
        <CheckinModal
          id="checkin"
          isLoading={isLoadingCheckins}
          open={checkinModalOpen}
          handleClose={handleCloseCheckinModal}
          handleSave={saveCheckin}
          selectedCheckin={selectedCheckin}
        />
      )}
    </>
  );
};

export default Competition;
