import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InfoIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <g>
      <path
        d="M32.9,52.5c-1,0-2.1-0.1-3.2-0.2c-7.3-1.1-14-6.6-16.4-13.4c-2.2-6.2-0.5-12.6,4.7-18.1c0.5-0.5,1.3-0.5,1.8-0.1
	c0.5,0.5,0.5,1.3,0.1,1.8c-5.7,6.1-5.4,11.8-4.1,15.5c2,5.9,8.1,10.8,14.3,11.7c10.3,1.5,16.4-5.2,18.6-12.1
	c2.4-7.4,1-17.5-7.7-22.1c-5.5-2.9-10.8-2.2-16.9,2c-0.6,0.4-1.4,0.3-1.8-0.3c-0.4-0.6-0.3-1.4,0.3-1.8c6.8-4.8,13.2-5.5,19.5-2.2
	c10.1,5.3,11.7,16.8,8.9,25.2C48.9,45.2,42.9,52.5,32.9,52.5z"
        fill="currentColor"
      />
      <path
        d="M32.2,28.8c-0.1,0.7-0.2,1.4-0.3,2.2c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.7-0.1,1.3-0.1,1.9c0,0.6,0,1.2,0,1.6c0,0.7,0,1.2,0,1.7
		c0,0.5,0.1,0.9,0.1,1.2c0,0.3,0.1,0.6,0.2,0.8c0.1,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.3,0,0.4,0
		c0.3,0,0.6,0,1-0.1s0.7-0.2,1.1-0.4l0.2,0.5c-0.2,0.2-0.5,0.4-0.9,0.6c-0.3,0.2-0.7,0.4-1.1,0.5c-0.4,0.2-0.8,0.3-1.1,0.4
		c-0.4,0.1-0.7,0.1-0.9,0.1c-0.2,0-0.3,0-0.5,0s-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.2-0.5-0.2-0.9
		c-0.1-0.4-0.1-0.9-0.1-1.4s0-1.3,0-2.1c0-0.5,0-1,0-1.6c0-0.6,0-1.2,0-1.9c0-0.7,0-1.4,0.1-2.2c0-0.8,0.1-1.6,0.1-2.4
		c0-0.2,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0C31.5,28.5,31.9,28.6,32.2,28.8z
		 M31.4,24.5c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.2-0.3-0.3-0.5C30,23.2,30,23,30,22.8c0-0.3,0-0.5,0.1-0.8
		c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.1,0.3-0.3,0.5-0.3c0.2-0.1,0.4-0.1,0.5-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3
		c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.7c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.3,0.3-0.5,0.4
		C31.9,24.4,31.7,24.5,31.4,24.5z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default InfoIcon;
