import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FinishFlagIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <g opacity="0.5">
        <path
          d="M11.5,10.9c0-0.2,0-0.4,0-0.6c-0.4-0.2-0.7-0.4-1-0.6c-0.2-0.1-0.3-0.2-0.5-0.3c0,0.3,0,0.6,0,0.9
			C10.6,10.3,11.1,10.6,11.5,10.9z"
          fill="currentColor"
        />
        <path
          d="M9.1,12.1c0-0.2,0-0.5,0-0.7c-0.3,0.1-0.7,0.3-1.2,0.7c-0.1,0.1-0.2,0.1-0.2,0.1c0,0.2,0,0.3,0,0.5
			C8.2,12.3,8.6,12.1,9.1,12.1z"
          fill="currentColor"
        />
        <path
          d="M7.4,8c0,0.1,0,0.3,0,0.5C8,8,8.5,7.9,9,8c0-0.4,0-0.8,0-1.1C8.5,6.8,8,7.1,7.4,8z"
          fill="currentColor"
        />
        <path
          d="M14.1,9.5c0-0.3,0-0.7,0-1c-0.3,0.1-0.6,0.1-1,0.1c-0.2,0-0.3,0-0.5,0c-0.1,0-0.1,0-0.2,0c0,0.4,0,0.7,0,1.1
			c0,0,0.1,0,0.1,0C13.1,9.7,13.6,9.6,14.1,9.5z"
          fill="currentColor"
        />
        <path
          d="M15.1,11.7c0.4-0.2,0.8-0.5,1.3-0.9c0.1-0.1,0.2-0.1,0.3-0.1c0-0.1,0-0.1,0-0.2c0-0.4,0-0.8,0-1.2
			c-0.3,0.1-0.6,0.2-0.8,0.4c-0.3,0.1-0.5,0.3-0.8,0.4C15.1,10.7,15.1,11.2,15.1,11.7z"
          fill="currentColor"
        />
        <path
          d="M14.2,13.7c0-0.2,0-0.5,0-0.7c-0.1,0-0.2,0-0.2,0c-0.5,0-1-0.2-1.4-0.4c0,0.3,0,0.5,0,0.8C13,13.7,13.5,13.8,14.2,13.7z"
          fill="currentColor"
        />
      </g>
      <path
        d="M17.7,10.5c0-1.3-0.1-2.6,0-3.8c0-0.1-0.1-0.3-0.2-0.4c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.1-0.4,0
		c-0.5,0.2-0.9,0.4-1.4,0.6c-1,0.5-1.8,0.9-2.9,0.8c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1,0-0.1,0-0.1,0
		c-0.2-0.1-0.5-0.3-0.7-0.4c-1.1-0.7-2.6-1.6-4.1,0C7,6.8,7,6.7,6.9,6.7c-0.2,0-0.5,0.2-0.6,0.4c-0.1,0.2,0,0.3,0.1,0.5
		c0,0.2,0,0.5,0,1l0,0.8c0,0.1-0.1,0.1-0.1,0.2C6.2,9.7,6.3,10,6.5,10.2c0,0,0,0,0.1,0c0.1,1.5,0.2,3.1,0.2,4.7c0,0.6,0,1.3,0.1,1.9
		c0,0.1,0,0.4,0,0.6c0,0.3,0,0.8,0.5,0.8c0,0,0,0,0,0c0.3,0,0.5-0.3,0.5-0.5l0-0.2l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0.1-0.2
		c0-0.2,0-0.3-0.1-0.4c-0.1-0.6-0.1-1.2-0.1-1.8c0-0.4,0-0.7,0-1.1c1.7-1.3,2.5-0.7,3.6,0c0.7,0.5,1.4,1,2.4,1
		c0.8,0,1.8-0.4,3.1-1.4c0.1-0.1,0.2-0.3,0.2-0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0,0.5-0.2,0.5-0.5C17.7,11.8,17.7,11.2,17.7,10.5z
		 M15.1,11.7c0-0.5,0-1.1,0-1.6c0.3-0.1,0.5-0.2,0.8-0.4c0.3-0.1,0.5-0.3,0.8-0.4c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1,0,0.2
		c-0.1,0-0.2,0-0.3,0.1C15.9,11.3,15.5,11.5,15.1,11.7z M7.5,9.8l0-0.2C8,9,8.5,8.9,9,9c0,0.5,0,0.9,0,1.4c-0.4,0.1-0.9,0.3-1.5,0.7
		C7.5,10.7,7.5,10.2,7.5,9.8z M10,10.3c0-0.3,0-0.6,0-0.9c0.2,0.1,0.3,0.2,0.5,0.3c0.3,0.2,0.7,0.4,1,0.6c0,0.2,0,0.4,0,0.6
		C11.1,10.6,10.6,10.3,10,10.3z M12.5,11.5c0-0.3,0-0.6,0-0.9c0.2,0,0.3,0,0.5,0c0.4,0,0.8-0.1,1.2-0.2c0,0.5,0,0.9,0,1.4
		c0,0,0,0.1,0,0.1C13.5,12.1,13,11.8,12.5,11.5z M16.1,7.7c0.2-0.1,0.4-0.2,0.6-0.3c0,0.3,0,0.6,0,0.9c-0.4,0.2-0.8,0.4-1.2,0.6
		c-0.1,0.1-0.2,0.1-0.4,0.2c0-0.3,0-0.6,0-0.9C15.5,8,15.8,7.8,16.1,7.7z M13.2,8.6c0.3,0,0.7-0.1,1-0.1c0,0.3,0,0.7,0,1
		c-0.5,0.2-1,0.2-1.6,0.2c0,0-0.1,0-0.1,0c0-0.4,0-0.7,0-1.1c0.1,0,0.1,0,0.2,0C12.8,8.5,13,8.6,13.2,8.6z M11.6,8.1
		c0,0.4,0,0.7,0,1.1C11.3,9.1,11.2,9,11,8.9c-0.3-0.2-0.7-0.4-1-0.6c0-0.4,0-0.7,0-1.1c0.2,0.1,0.4,0.3,0.7,0.4
		C11,7.8,11.3,8,11.6,8.1z M9.1,6.9C9,7.3,9,7.6,9,8C8.5,7.9,8,8,7.4,8.4c0-0.2,0-0.3,0-0.5C8,7.1,8.5,6.8,9.1,6.9z M7.6,12.1
		c0.1,0,0.2,0,0.2-0.1c0.5-0.4,0.8-0.6,1.2-0.7c0,0.2,0,0.5,0,0.7c-0.4,0.1-0.9,0.2-1.4,0.5C7.6,12.4,7.6,12.3,7.6,12.1z M10,12.1
		c0-0.3,0-0.5,0-0.8c0.5,0.1,1,0.4,1.5,0.7c0,0,0,0,0,0c0,0.3,0,0.5,0,0.8C11.1,12.5,10.6,12.2,10,12.1z M12.5,13.4
		c0-0.3,0-0.5,0-0.8c0.4,0.2,0.9,0.4,1.4,0.4c0.1,0,0.1,0,0.2,0c0,0.2,0,0.5,0,0.7C13.5,13.8,13,13.7,12.5,13.4z M16.2,12.6
		c-0.4,0.3-0.7,0.5-1,0.7c0-0.2,0-0.4,0-0.6c0.5-0.2,1-0.5,1.6-0.9c0,0.2,0,0.5,0,0.7c0,0,0,0.1,0,0.1
		C16.6,12.5,16.3,12.4,16.2,12.6z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default FinishFlagIcon;
