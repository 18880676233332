import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import BasicModal from './BasicModal';

interface Props {
  handleClose: () => void;
  open: boolean;
  serialNo?: number | string;
}

const CheckinSerialModal = ({ handleClose, open, serialNo = '-' }: Props) => {
  const { t } = useTranslation();
  return (
    <BasicModal
      open={open}
      handleClose={handleClose}
      id="checkin-ser"
      title={t('checkins.checkinSerial')}
    >
      <Typography sx={{ mt: 2, fontSize: 72 }} align="center">
        {serialNo}
      </Typography>
    </BasicModal>
  );
};

export default CheckinSerialModal;
