import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const OutdoorsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M4.2,9.9c0.1,0,0.1,0,0.2,0C5.1,9.5,5.5,9,5.9,8.5c0.2-0.3,0.5-0.6,0.8-0.8c0.2-0.1,0.4-0.2,0.6-0.4
		c0.4-0.2,0.8-0.4,1.1-0.7c0,0,0.1-0.1,0.1-0.2c0,0,0,0,0.1-0.1C8.5,6.7,8.3,6.9,8.1,7.1C7.8,7.5,7.5,7.9,7.5,8.3
		c0,0.3,0.1,0.5,0.4,0.6c0,0,0.1,0,0.1,0c0.2,0,0.4-0.2,0.5-0.4c0-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.5-0.6,0.7-1C9.6,6.8,9.7,6.9,9.7,7
		c0.2,0.3,0.3,0.5,0.4,0.6c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1C9.4,7.9,9.3,8.2,9.4,8.5C9.5,8.7,9.7,8.8,10,8.7
		c0.1,0,0.1-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2c0,0,0,0.1,0,0.1c0,0.2,0.1,0.3,0.2,0.5
		c0.2,0.2,0.4,0.3,0.6,0.4c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.3,0.5,0.5,0.7l0.3,0.3c0.5,0.5,1,1,1.5,1.6
		c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.5,0.1-0.7c-0.5-0.6-1-1.1-1.5-1.6l-0.3-0.3c-0.1-0.1-0.2-0.3-0.3-0.5
		c0,0-0.1-0.1-0.1-0.1c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.2,0.4-0.4,0.5-0.5c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.3,0.5,0.6,0.7
		c0.3,0.2,0.6,0.2,0.8,0.2c0.2,0,0.2,0,0.5,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.9,1,1.4,1.5,1.8,1.6c0.2,0,0.3,0,0.4,0
		c0,0,0.1,0.1,0.1,0.1c0.3,0.3,0.6,0.7,1.2,0.7c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5c-0.1,0-0.4-0.2-0.5-0.4
		c-0.1-0.2-0.3-0.3-0.4-0.4c-0.3-0.1-0.5,0-0.6,0c-0.3-0.2-1-1-1.3-1.3c-0.1-0.1-0.2-0.3-0.3-0.3c-0.5-0.5-0.8-0.6-1.2-0.6
		c-0.1,0-0.2,0-0.4-0.1c0,0-0.1-0.2-0.1-0.2c-0.1-0.2-0.2-0.5-0.5-0.7c-0.6-0.3-1.2,0.3-1.6,0.8c-0.1,0.1-0.2,0.2-0.3,0.4
		c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.2,0c0,0,0-0.1-0.1-0.2c-0.1-0.3-0.2-0.7-0.7-0.8c0,0-0.1,0-0.1,0c0-0.1,0-0.1-0.1-0.2
		c-0.1-0.1-0.3-0.4-0.5-0.7C9.9,5.6,9.6,5.1,9.3,5C8.9,4.8,8.5,5.2,7.9,5.8L7.8,6C7.6,6.1,7.2,6.3,6.9,6.5C6.6,6.6,6.4,6.8,6.1,6.9
		C5.8,7.2,5.5,7.6,5.2,7.9C4.8,8.3,4.5,8.7,4,9C3.7,9.1,3.6,9.4,3.7,9.6C3.8,9.8,4,9.9,4.2,9.9z"
      fill="currentColor"
    />
    <path
      d="M19.9,13.6c-1.3-0.2-3.3-0.1-5.3,0c-1.4,0-2.7,0.1-3.7,0c-0.2-0.1-0.4-0.2-0.5-0.3l-0.1-0.1
		C10,13.2,9.8,13,9.5,12.9c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.3-0.2-0.4c0-0.1,0.1-0.1,0.1-0.2c0-0.2-0.1-0.4-0.3-0.5
		c0-0.1,0.1-0.1,0.1-0.2c0-0.2-0.1-0.3-0.2-0.4c0-0.1,0-0.2,0-0.3c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0-0.2v0c0-0.3-0.2-0.5-0.5-0.5
		c-0.2,0-0.5,0.2-0.5,0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0
		c0-0.1,0.1-0.1,0.1-0.2c0-0.2-0.1-0.3-0.2-0.4c0-0.1,0.1-0.2,0-0.3c0-0.1-0.1-0.1-0.1-0.2l0-0.1c0-0.3-0.2-0.5-0.5-0.5
		c-0.3,0-0.5,0.2-0.5,0.5l0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c-0.2,0.1-0.3,0.2-0.3,0.4c0,0.2,0.1,0.4,0.3,0.4l0,0.3l0,0.1
		c0,0,0,0.1,0,0.1c-0.8-0.2-1.6-0.2-2.4-0.1c-0.3,0-0.4,0.3-0.4,0.5c0,0.3,0.3,0.5,0.5,0.4c0.6-0.1,1.2,0,1.8,0.1
		c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0c0.2,0,0.3-0.1,0.4-0.2
		c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0c0,0.1,0,0.1,0,0.2c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0c0.2,0,0.4-0.1,0.4-0.3
		c0.3,0.2,0.6,0.3,1,0.5l0.1,0.1c0.2,0.1,0.3,0.1,0.4,0.2c0,0,0.1,0.1,0.2,0.1c-0.5,0.1-1.6,0.3-2.4,0.4c-2.3,0.3-3,0.4-3.1,1
		c0,0.5,0.6,0.7,1.5,0.8c0.1,0,0.3,0,0.4,0.1c-0.1,0-0.2,0.1-0.3,0.1c-1,0.3-2.2,0.7-2.8,1.3c-0.2,0.2-0.2,0.5,0,0.7
		C3.9,19,4,19,4.2,19c0.1,0,0.2,0,0.3-0.1c0.5-0.4,1.6-0.8,2.5-1c1.1-0.3,1.7-0.5,1.7-1.1c0-0.4-0.4-0.6-1.2-0.8
		c0.3,0,0.6-0.1,0.8-0.1c2.4-0.3,3.1-0.5,3.3-0.8c0.1-0.1,0.1-0.3,0.1-0.4c0.1,0,0.1,0,0.2,0c0.8,0,1.8,0,2.8-0.1
		c1.9-0.1,3.9-0.1,5.1,0c0.3,0,0.5-0.1,0.5-0.4C20.3,13.9,20.2,13.7,19.9,13.6z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default OutdoorsIcon;
