import { Fragment, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TranslateIcon from '@mui/icons-material/Translate';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetState } from 'react-use';

import { usePopupState } from 'material-ui-popup-state/hooks';
import { useAuth } from 'src/auth/useAuth';
import Flag from 'src/common/components/flags/Flag';
import Icon from 'src/common/components/icons/Icon';
import DrawerListItemButton from 'src/common/components/styled/DrawerListItemButton';
import { LANGUAGES, LanguageKeys } from 'src/common/constants/languages';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import UserMenu from './UserMenu';
import { getMenuLayout } from './constants/menuLayout';
import WrenchIcon from './icons/Wrench';

interface Props {
  open?: boolean;
  closeDrawer: () => void;
}

const UserDrawer = ({ open: drawerOpen, closeDrawer }: Props) => {
  const {
    t,
    i18n: { resolvedLanguage, changeLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  const { activeEditionId, competitionsById, editionsById } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const userMenu = usePopupState({ variant: 'popover', popupId: 'user-menu' });

  const currentEdition =
    editionsById && activeEditionId ? editionsById[activeEditionId] : undefined;

  const menuLayout = getMenuLayout({
    competitionsById,
    year: currentEdition?.year,
    competitions: currentEdition?.competitions,
    lang: currentLang,
  });

  const [menuOpen, setMenuOpen] = useSetState<{ [k: string]: boolean }>({});
  const [languageOpen, setLanguageOpen] = useState(false);

  const toggleMenuOpen = (mainPage: string) => {
    setMenuOpen(prevState => ({ [mainPage]: !prevState[mainPage] }));
  };

  const toggleLanguage = () => {
    setLanguageOpen(!languageOpen);
  };

  const container = document.body;

  return (
    <Drawer
      container={container}
      variant="temporary"
      open={drawerOpen}
      PaperProps={{ sx: { backgroundColor: grey[900] } }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 300 },
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={closeDrawer}>
          <Icon icon="close" sx={{ color: grey[100] }} />
        </IconButton>
      </Toolbar>
      <Typography
        variant="h5"
        component="h1"
        sx={{
          color: '#fff',
          textAlign: 'center',
          mb: 2,
        }}
        aria-label={t('base.pageTitle')}
      >
        <IconButton sx={{ color: 'inherit' }} onClick={() => navigate('/')}>
          <Icon
            icon="ttt"
            sx={{
              fontSize: 100,
              transition: theme =>
                theme.transitions.create('fontSize', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                  delay: theme.transitions.duration.leavingScreen,
                }),
            }}
          />
        </IconButton>
      </Typography>
      <Divider light />
      <List component="nav" sx={{ flexGrow: 1 }}>
        {menuLayout.map(page => {
          return (
            <Fragment key={page.id}>
              <DrawerListItemButton
                {...(page.subPages?.length
                  ? {
                      onClick: () => toggleMenuOpen(page.id),
                    }
                  : { href: page.pageUrl })}
                selected={pathname.includes(page.pageUrl)}
              >
                {page.icon ? (
                  <ListItemIcon sx={{ minWidth: 45 }}>
                    <Icon icon={page.icon} fontSize="large" />
                  </ListItemIcon>
                ) : null}
                <ListItemText primary={page.pageName ? page.pageName : t(`drawer.${page.id}`)} />
                {page.subPages?.length ? menuOpen[page.id] ? <ExpandLess /> : <ExpandMore /> : null}
              </DrawerListItemButton>

              {page.subPages?.length ? (
                <Collapse
                  in={menuOpen[page.id]}
                  timeout="auto"
                  unmountOnExit
                  sx={drawerOpen ? { borderLeft: '2px solid #ccc', ml: 4 } : undefined}
                >
                  <List component="div" disablePadding>
                    {page.subPages.map(pg => (
                      <Fragment key={pg.id}>
                        <DrawerListItemButton
                          {...(pg.subPages?.length
                            ? {
                                onClick: () => toggleMenuOpen(pg.id),
                              }
                            : { href: pg.pageUrl })}
                          selected={pathname.includes(pg.pageUrl)}
                        >
                          {pg.icon ? (
                            <ListItemIcon sx={{ minWidth: 45 }}>
                              <Icon icon={pg.icon} fontSize="large" />
                            </ListItemIcon>
                          ) : null}
                          <ListItemText
                            primary={pg.pageName ? pg.pageName : t(`drawer.${pg.id}`)}
                          />
                          {pg.subPages?.length ? (
                            menuOpen[pg.id] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )
                          ) : null}
                        </DrawerListItemButton>

                        {pg.subPages?.length ? (
                          <Collapse
                            in={menuOpen[pg.id]}
                            timeout="auto"
                            unmountOnExit
                            sx={drawerOpen ? { borderLeft: '2px solid #ccc', ml: 4 } : undefined}
                          >
                            <List component="div" disablePadding>
                              {pg.subPages.map(spg => (
                                <DrawerListItemButton
                                  href={spg.pageUrl}
                                  selected={pathname.includes(spg.pageUrl)}
                                  key={spg.id}
                                >
                                  {spg.icon ? (
                                    <ListItemIcon sx={{ minWidth: 45 }}>
                                      <Icon icon={spg.icon} fontSize="large" />
                                    </ListItemIcon>
                                  ) : null}
                                  <ListItemText
                                    primary={spg.pageName ? spg.pageName : t(`drawer.${spg.id}`)}
                                  />
                                </DrawerListItemButton>
                              ))}
                            </List>
                          </Collapse>
                        ) : null}
                      </Fragment>
                    ))}
                  </List>
                </Collapse>
              ) : null}

              <Divider sx={{ my: 1 }} light />
            </Fragment>
          );
        })}
        <DrawerListItemButton onClick={toggleLanguage}>
          <ListItemIcon sx={{ pl: 1, minWidth: 45 }}>
            <TranslateIcon />
          </ListItemIcon>
          <ListItemText primary={t(`base.i18n.${currentLang}`)} />
        </DrawerListItemButton>
        <Collapse
          in={languageOpen}
          timeout="auto"
          unmountOnExit
          sx={drawerOpen ? { borderLeft: '2px solid #ccc', ml: 4 } : undefined}
        >
          <List component="div" disablePadding>
            {LANGUAGES.map(lng => (
              <DrawerListItemButton
                key={lng.code}
                onClick={async () => {
                  await changeLanguage(lng.code);
                  toggleLanguage();
                }}
                selected={currentLang === lng.code}
                disabled={currentLang === lng.code}
              >
                <IconButton
                  aria-label="Switch language"
                  sx={{
                    padding: 0,
                    mr: 1,
                    overflow: 'hidden',
                  }}
                >
                  <Flag code={lng.code} />
                </IconButton>
                <ListItemText primary={t(`base.i18n.${lng.code}`)} />
              </DrawerListItemButton>
            ))}
          </List>
        </Collapse>
        <Divider sx={{ my: 1 }} light />
      </List>
      <Divider light />

      {auth.user && (
        <>
          <DrawerListItemButton
            onClick={userMenu.open}
            selected={pathname.includes('/dashboard/profile')}
            sx={{ flexGrow: 0 }}
          >
            <ListItemIcon sx={{ minWidth: 45 }}>
              <WrenchIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary={auth.user.name} />
          </DrawerListItemButton>
          <UserMenu popupState={userMenu} />
        </>
      )}
    </Drawer>
  );
};
export default UserDrawer;
