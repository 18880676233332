import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CaretDownIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6.6,9c0-0.1,0-0.1,0-0.2C6.7,8.6,6.8,8.5,7,8.5c0.3,0,0.5,0.1,0.5,0.4c0.1,0.2,0.7,1,1.5,2
		c0.2,0.2,0.4,0.5,0.6,0.8c0.3,0.4,0.6,0.8,0.9,1.1c0.5,0.6,1,1.1,1.4,1.4c0.2-0.2,0.7-0.8,1.7-1.9c0.3-0.3,1-1.3,1.6-2
		c0.4-0.3,0.7-0.6,0.7-0.7c0.2-0.3,0.4-0.5,0.6-0.7c0.1-0.3,0.4-0.3,0.6-0.2c0.2,0.1,0.3,0.4,0.2,0.7l-0.1,0.1
		c-0.1,0.2-0.3,0.4-0.5,0.7c-0.1,0.1-0.3,0.4-0.6,0.8c-0.6,0.7-1.3,1.7-1.6,2c-2.1,2.5-2.2,2.5-2.5,2.5c-0.1,0-0.2,0-0.3-0.1
		c-0.6-0.5-1.2-1.1-1.9-1.8c-0.3-0.3-0.6-0.8-0.9-1.2c-0.2-0.3-0.4-0.6-0.6-0.8C6.9,9.8,6.6,9.3,6.6,9z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CaretDownIcon;
