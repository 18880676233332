import { AxiosResponse } from 'axios';
import mapKeys from 'lodash-es/mapKeys';
import { StateCreator } from 'zustand';

import { SECURE_API } from 'src/api/api';
import { PERSISTENT_KEY_SELECTED_EDITION } from 'src/common/constants/persistentStorageKeys';
import { IEdition } from 'src/interfaces/Edition';
import { Statistics } from 'src/interfaces/Statistics';
import { IFormEdition } from 'src/pages/Dashboard/Editions/forms/EditionEditorForm';

export interface EditionSlice {
  activeEditionId?: string;
  editions: IEdition[];
  editionsById?: { [key: string]: IEdition };
  editionsByYear?: { [key: string]: IEdition };
  selectedEdition?: IEdition;

  isLoading: boolean;

  deleteAllEditions: () => Promise<void | AxiosResponse<null>>;
  deleteEdition: (id: string) => Promise<void | AxiosResponse<null>>;
  fetchActiveEdition: () => Promise<void | AxiosResponse<null>>;
  fetchEditions: () => Promise<void | AxiosResponse<null>>;
  resetEditions: () => void;
  saveActiveEdition: (
    activeEdition: IFormEdition,
    id?: string,
  ) => Promise<void | AxiosResponse<null>>;
  saveEdition: (edition: IFormEdition, id?: string) => Promise<void | AxiosResponse<IEdition[]>>;
  saveStatisticsForEdition: (
    id: string,
    payload: {
      checkinsByPrice?: {
        [key: string]: number;
      };
      statistics: Statistics;
      totalPrice: number;
    },
  ) => Promise<void | AxiosResponse<IEdition[]>>;
  setSelectedEdition: (edition?: IEdition) => void;
  toggleActiveEdition: (editionId: string) => Promise<void>;
}

export const createEditionsSlice: StateCreator<EditionSlice, [], [], EditionSlice> = (set, get) => {
  const getEditions = async () => {
    return SECURE_API.get<IEdition[]>('editions/')
      .then(({ data }) => {
        set({
          editions: data,
          editionsById: mapKeys(data, 'id'),
          editionsByYear: mapKeys(data, 'year'),
          isLoading: false,
        });
      })
      .catch((error: string) => {
        set({
          editions: [],
          editionsById: undefined,
          editionsByYear: undefined,
          isLoading: false,
        });
        console.log({ error });
      });
  };

  const lsSelectedEdition = localStorage.getItem(PERSISTENT_KEY_SELECTED_EDITION);

  return {
    editions: [],
    selectedEdition: lsSelectedEdition ? JSON.parse(lsSelectedEdition) : undefined,

    isLoading: false,

    deleteAllEditions: async () => {
      set({ isLoading: true });
      return SECURE_API.delete<null>(`editions/delete/bulk`)
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getEditions();
        });
    },
    deleteEdition: async id => {
      set({ isLoading: true });
      return SECURE_API.delete<null>(`editions/delete/${id}`)
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getEditions();
        });
    },
    fetchActiveEdition: async () => {
      set({ isLoading: true });
      return SECURE_API.get<{
        id: string;
        activeEditionId?: string;
      }>('activeEdition/')
        .then(({ data }) => {
          set({
            activeEditionId: data.activeEditionId,
            isLoading: false,
          });
        })
        .catch((error: string) => {
          set({
            activeEditionId: undefined,
            isLoading: false,
          });
          console.log({ error });
        });
    },

    fetchEditions: async () => {
      set({ isLoading: true });
      return getEditions();
    },

    resetEditions: () => set({ editions: [] }),
    saveEdition: async (edition: IFormEdition, id?: string) => {
      set({ isLoading: true });
      const saveEditionPromise = id
        ? SECURE_API.put<IEdition[]>(`editions/update/${id}`, edition)
        : SECURE_API.post<IEdition[]>(`editions/save`, edition);
      return saveEditionPromise
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getEditions();
        });
    },
    saveActiveEdition: async (activeEdition: IFormEdition, id?: string) => {
      set({ isLoading: true });

      return SECURE_API.put<null>(`editions/${id}`, activeEdition)
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getEditions();
        });
    },
    saveStatisticsForEdition: async (
      id: string,
      payload: {
        statistics: Statistics;
        totalPrice: number;
      },
    ) => {
      set({ isLoading: true });
      return SECURE_API.put<IEdition[]>(`editions/statistics/${id}`, payload)
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getEditions();
        });
    },

    setSelectedEdition: (edition?: IEdition) => set({ selectedEdition: edition }),
    toggleActiveEdition: async (id: string) => {
      set({ isLoading: true });
      const editionId = id === get().activeEditionId ? undefined : id;
      return SECURE_API.put<{
        id: string;
        activeEditionId?: string;
      }>('activeEdition/update/', { editionId })
        .then(({ data }) => {
          set({
            activeEditionId: data.activeEditionId,
            isLoading: false,
          });
        })
        .catch((error: string) => {
          set({
            activeEditionId: undefined,
            isLoading: false,
          });
          console.log({ error });
        });
    },
  };
};
