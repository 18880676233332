import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ShieldIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M11.8,19.9c-0.6,0-1.2,0-1.8,0c-0.4,0-0.8,0-1.1,0c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5c0.3,0,0.7,0,1.1,0
	c2,0,5.4,0.1,6.8-0.8c0.4-0.9,0.1-7.2-0.3-7.9c-0.6-0.5-2-0.4-3.4-0.2c-0.7,0.1-1.4,0.1-2,0.1c-1.6,0-3.4,0.1-4,0.5
	c-0.4,0.3-0.2,1.6-0.1,2.7c0.1,0.5,0.1,1.1,0.2,1.5c0.1,0.8,0.1,2.8,0,3.3c0,0.3-0.3,0.4-0.6,0.4c-0.3,0-0.4-0.3-0.4-0.5
	c0.1-0.3,0-2.3,0-3.1c0-0.5-0.1-1-0.2-1.5c-0.2-1.5-0.4-2.9,0.4-3.6c0.6-0.5,1.9-0.7,4.6-0.7c0.6,0,1.2-0.1,1.9-0.1
	c1.6-0.2,3.4-0.4,4.3,0.5c0.7,0.7,0.8,6.1,0.7,7.8c-0.1,1.1-0.2,1.4-0.5,1.5C16.2,19.8,13.9,19.9,11.8,19.9z M16.8,18.1L16.8,18.1
	L16.8,18.1z M16.9,18.1C16.9,18.1,16.9,18.1,16.9,18.1C16.9,18.1,16.9,18.1,16.9,18.1z"
      fill="currentColor"
    />
    <path
      d="M9.2,8.6C9.2,8.6,9.2,8.6,9.2,8.6c-0.3,0-0.5-0.3-0.5-0.5c0.3-3.2,1.9-4.3,3.1-4.2c1.4,0.1,2.8,1.6,2.8,4.2
	c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5c0-2.1-1-3.2-1.8-3.3c-0.9,0-1.8,1-2.1,3.3C9.7,8.4,9.5,8.6,9.2,8.6z"
      fill="currentColor"
    />
    <path
      d="M12.2,16.7c-0.3,0-0.5-0.3-0.5-0.7v-1c0-0.4,0.2-0.7,0.5-0.7s0.5,0.3,0.5,0.7v1C12.7,16.4,12.5,16.7,12.2,16.7z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ShieldIcon;
