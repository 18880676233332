import ClearIcon from '@mui/icons-material/Clear';
import TuneIcon from '@mui/icons-material/Tune';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip from '@mui/material/Tooltip';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';

import Searcher from 'src/common/components/Searcher';
import Sorter from 'src/common/components/Sorter';
import ViewSwitch from 'src/common/components/ViewSwitch';
import { LanguageKeys } from 'src/common/constants/languages';
import { EApplicantFilters } from 'src/interfaces/Applicant';
import { Order } from 'src/interfaces/Order';
import { View } from 'src/interfaces/View';
import {
  AGE,
  CITY,
  EMAIL,
  NAME,
  PHONE_NUMBER,
} from 'src/pages/Dashboard/Applicants/constants/applicantSortKeys';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

interface Props {
  checkedInFilter: string | null;
  competitionFilter: string | null;
  distanceFilter: string | null;
  onChange: (key: EApplicantFilters, value: string | null) => void;
  onSetView: (v: View) => void;
  order: Order;
  orderByKey: string;
  query: string;
  setOrder: (a: Order) => void;
  setOrderByKey: (a: string) => void;
  view: View;
}

const ApplicantFilterForm = ({
  checkedInFilter,
  competitionFilter,
  distanceFilter,
  onChange,
  onSetView,
  order,
  orderByKey,
  query,
  setOrder,
  setOrderByKey,
  view,
}: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  const { competitions, distances } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );
  const filterMenu = usePopupState({ variant: 'popover', popupId: 'filterMenu' });

  const checkedOptions = [
    {
      label: t('form.yes'),
      value: 'true',
    },
    {
      label: t('form.no'),
      value: 'false',
    },
  ];

  const sortOptions = [
    {
      label: t('form.name'),
      value: NAME,
    },
    {
      label: t('form.age'),
      value: AGE,
    },
    {
      label: t('form.city'),
      value: CITY,
    },
    {
      label: t('form.email'),
      value: EMAIL,
    },
    {
      label: t('form.phoneNumber'),
      value: PHONE_NUMBER,
    },
  ];

  return (
    <Grid container spacing={2} sx={{ display: 'flex' }}>
      <Grid item xs={12} sm={6} md={5} display="flex" justifyContent="flex-end">
        <Searcher
          AddonIcon={
            <IconButton {...bindTrigger(filterMenu)} edge="end">
              <TuneIcon />
            </IconButton>
          }
          onChange={q => onChange(EApplicantFilters.query, q)}
          query={query}
          width={100}
        />
      </Grid>
      <Grid item xs={12} sm md={4}>
        <Sorter
          order={order}
          orderByKey={orderByKey}
          setOrder={setOrder}
          setOrderByKey={setOrderByKey}
          sortOptions={sortOptions}
        />
        <Popover
          {...bindMenu(filterMenu)}
          keepMounted
          slotProps={{
            paper: {
              style: {
                width: 400,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid
              item
              sm={4}
              component={FormControl}
              margin="dense"
              size="small"
              flexDirection="column"
            >
              <FormLabel
                id="competition-radio-group"
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {t('events.event')}
                {!!competitionFilter && (
                  <Tooltip title={t('form.clearFilter')}>
                    <IconButton
                      size="small"
                      onClick={() => onChange(EApplicantFilters.competition, null)}
                      sx={{ padding: 0 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </FormLabel>
              <RadioGroup
                aria-labelledby="competition-radio-group"
                value={competitionFilter}
                onChange={(_e, v) => onChange(EApplicantFilters.competition, v)}
              >
                {competitions.map(c => (
                  <FormControlLabel
                    key={c.id}
                    value={c.id}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 18,
                          },
                        }}
                      />
                    }
                    label={c.i18n[currentLang]}
                  />
                ))}
              </RadioGroup>
            </Grid>

            <Grid
              item
              sm={4}
              component={FormControl}
              margin="dense"
              size="small"
              flexDirection="column"
            >
              <FormLabel
                id="distance-radio-group"
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {t('events.distance')}
                {!!distanceFilter && (
                  <Tooltip title={t('form.clearFilter')}>
                    <IconButton
                      size="small"
                      onClick={() => onChange(EApplicantFilters.distance, null)}
                      sx={{ padding: 0 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </FormLabel>
              <RadioGroup
                aria-labelledby="distance-radio-group"
                value={distanceFilter}
                onChange={(_e, v) => onChange(EApplicantFilters.distance, v || null)}
              >
                {distances.map(d => (
                  <FormControlLabel
                    key={d.id}
                    value={d.id}
                    control={<Radio size="small" />}
                    label={d.i18n[currentLang]}
                  />
                ))}
              </RadioGroup>
            </Grid>

            <Grid
              item
              sm={4}
              component={FormControl}
              margin="dense"
              size="small"
              flexDirection="column"
            >
              <FormLabel
                id="checkedIn-radio-group"
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {t('events.registration')}
                {!!checkedInFilter && (
                  <Tooltip title={t('form.clearFilter')}>
                    <IconButton
                      size="small"
                      onClick={() => onChange(EApplicantFilters.checkedIn, null)}
                      sx={{ padding: 0 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </FormLabel>
              <RadioGroup
                aria-labelledby="checkedIn-radio-group"
                value={checkedInFilter}
                onChange={(_e, v) => onChange(EApplicantFilters.checkedIn, v || null)}
              >
                {checkedOptions.map(c => (
                  <FormControlLabel
                    key={c.value}
                    value={c.value}
                    control={<Radio size="small" />}
                    label={c.label}
                  />
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
        </Popover>
      </Grid>
      <Grid item xs={12} sm="auto">
        <ViewSwitch view={view} onSetView={onSetView} />
      </Grid>
    </Grid>
  );
};

export default ApplicantFilterForm;
