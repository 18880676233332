import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MapIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g opacity="0.5">
      <path
        d="M14.5,13.4c-0.2-0.1-0.5,0-0.6,0.2c-0.1,0-0.4,0-0.6,0.2c-0.4,0.5-1,0.7-1.4,0.5c-0.2-0.1-0.4-0.2-0.4-0.4
		c0-0.2,0.1-0.4,0.3-0.5c0,0,0.1,0,0.1,0c0.2,0,0.4,0.1,0.7-0.1c0.2-0.1,0.4-0.3,0.5-0.5c0.2-0.3,0.3-0.3,0.4-0.3
		c0.3,0,0.5-0.1,0.5-0.4c0-0.2-0.1-0.4-0.3-0.5c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.4-0.3
		c0.1-0.2,0-0.5-0.3-0.6c-0.1,0-0.2-0.1-0.3-0.1c0,0,0-0.1,0-0.1c0-0.3-0.3-0.4-0.6-0.4c-0.2,0-0.4,0.3-0.4,0.5
		c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0,0,0c-0.2-0.4-0.4-0.9-0.9-1c-0.4-0.1-0.8,0.2-1.1,0.4c-0.1,0.1-0.3,0.3-0.4,0.3c0,0-0.1,0-0.2,0
		c-0.2,0-0.5-0.1-0.8,0.1c-0.6,0.3-0.7,0.8-0.7,1.1c0.1,0.9,1.2,1.6,1.6,1.7c0,0,0.1,0,0.1,0c0.2,0,0.4-0.1,0.5-0.3
		c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.3,0,0.4,0c0.7,0,1-0.3,1.2-1.2c0,0,0,0,0-0.1c0.1,0.1,0.2,0.1,0.3,0.2
		c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.2,0.3-0.3,0.3c0,0-0.1,0-0.1,0c-0.2,0-0.4-0.1-0.7,0.1
		c-0.6,0.4-0.9,0.9-0.8,1.5c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.4-0.1-0.6,0.1c-0.2,0.2-0.2,0.4-0.1,0.6
		c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.2,0.1
		c0.2,0,0.3-0.1,0.4-0.3c0.1-0.1,0.1-0.2,0-0.4c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.6,0,1.2-0.3,1.7-0.7
		c0.4,0,0.7-0.2,0.8-0.6C14.9,13.7,14.8,13.5,14.5,13.4z M11.6,11.4c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.7,0.1c-0.3,0.1-0.5,0.4-0.6,0.6
		c-0.3-0.2-0.6-0.5-0.6-0.7c0-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0.2,0c0.2,0,0.4,0.1,0.6,0c0.3-0.1,0.6-0.3,0.8-0.5
		c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.1,0.1,0.3,0.5c0,0,0,0,0,0C11.6,11.4,11.6,11.4,11.6,11.4z"
        fill="currentColor"
      />
    </g>
    <path
      d="M18.2,9.8c0.5-1.2,1-2.5,0.1-2.8c-0.2-0.1-3.1-0.8-3.8,0.2c-0.2,0.3-0.3,0.7-0.3,1.3c-0.1,0-0.2,0-0.2,0
	c-1.3,0.6-3.2,0-4.6-0.7c0-0.2,0-0.3,0-0.5c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.2c0.1-0.3-0.1-0.5-0.3-0.6c-1.5-0.5-2.4-0.5-2.9,0
	C5.6,7,5.8,7.9,6,9c0.1,0.7,0.3,1.4,0.3,2c0,0.4-0.3,1.2-0.5,2c-0.5,1.8-0.7,2.6-0.4,3c0.9,1.4,3,1.9,5.3,1.9c0.9,0,1.7-0.1,2.5-0.2
	c1-0.2,4.4-0.9,4.8-2.6c0.1-0.3,0-0.5-0.3-1c-0.1-0.2-0.2-0.4-0.2-0.5c-0.1-0.9,0-2,0.2-3C17.9,10.4,18.1,10.1,18.2,9.8z M15.6,15.2
	C15.6,15.2,15.5,15.2,15.6,15.2c-0.3-0.2-0.3-0.3-0.3-0.3c0-0.1,0.1-0.1,0.1-0.2C15.4,14.8,15.4,15,15.6,15.2z M7,8.8
	C6.9,8.2,6.7,7.3,6.9,7.1C7,7.1,7.3,6.9,8.4,7.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.4c0,0-0.1,0.1-0.1,0.1C8.2,8,8.2,8.2,8.3,8.3
	c0,0.5-0.1,1-0.2,1.5C8,11,7.8,12.2,7.9,13.4c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4,0.1-0.6,0.2c0-0.1,0.1-0.2,0.1-0.3
	c0.3-0.9,0.5-1.8,0.5-2.2C7.3,10.3,7.1,9.5,7,8.8z M13.1,16.8c-2.8,0.5-6,0.2-6.9-1.2c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.2,0.2-0.3
	c0-0.2,0.3-0.4,0.8-0.5c0.5-0.1,0.8-0.1,0.9,0c0,0,0,0.1-0.2,0.5c0,0.1-0.2,0.1-0.3,0.2c0-0.2-0.1-0.3-0.3-0.4
	c-0.2-0.1-0.5,0-0.6,0.3c-0.2,0.5,0,0.9,0.3,1c0.1,0.1,0.3,0.1,0.5,0.1c0.5,0,1-0.3,1.3-0.6c0.1-0.2,0.2-0.3,0.3-0.5
	c0.2-0.1,0.3-0.3,0.2-0.6c-0.4-1.3-0.2-2.9,0-4.5c0.1-0.4,0.1-0.8,0.1-1.1c1.1,0.5,2.3,0.9,3.5,0.9c0.5,0,1-0.1,1.4-0.3
	c0,1,0.1,2.1,0.2,3.5c0,0.7,0.1,1.3,0.1,1.6c0,0,0,0,0,0c-0.2,0.5,0.1,1.1,0.6,1.4c0.2,0.1,0.3,0.1,0.5,0.2
	C14.9,16.4,14.1,16.6,13.1,16.8z M16.7,15.5c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.5-0.7-0.8c-0.1,0-0.2,0-0.3,0
	c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.5,0.5c0.1,0.2,0.2,0.3,0.4,0.3C17,15.2,16.9,15.3,16.7,15.5z M17.3,9.4
	c-0.2,0.4-0.3,0.7-0.3,1c-0.2,0.9-0.3,2-0.3,3c-0.1-0.1-0.3-0.1-0.5-0.1c0,0,0,0,0,0c-0.3,0-0.6,0.1-0.9,0.3c0-0.2,0-0.4,0-0.6
	c-0.1-1.5-0.3-4.6,0-5.2c0.3-0.2,1.8-0.1,2.5,0.1C17.8,8.1,17.5,9,17.3,9.4z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default MapIcon;
