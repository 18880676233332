import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HikerIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path
        d="M13.3,8.8c1.4,0,2.2-0.9,2.3-1.7c0.2-0.8-0.1-1.7-0.8-2.3c0.1,0,0.2-0.1,0.2-0.2C15.3,4.5,15.2,4.2,15,4
		c-0.6-0.5-1.4-0.7-2.2-0.7c-1,0-1.9,0.5-2.4,1.3c-0.5,0.9-0.5,2,0,2.8C10.7,7.9,11.5,8.8,13.3,8.8z M11.3,5
		c0.3-0.5,0.9-0.8,1.5-0.8c0,0,0,0,0,0c0.4,0,0.7,0.1,1,0.2c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.2,0,0.5,0.2,0.6c0.7,0.3,0.9,1.1,0.8,1.7
		c-0.1,0.6-0.6,0.9-1.4,0.9c-1,0-1.7-0.3-2-1C10.9,6.3,10.9,5.6,11.3,5z"
        fill="currentColor"
      />
      <path
        d="M17.1,9.5c-0.3,0-0.5,0.2-0.5,0.4c0,0.3-0.1,0.6-0.1,0.9c-1.4,0.2-3.7-0.4-4.8-1.2c-0.2-0.1-0.4-0.1-0.6,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c-0.4,1-0.6,1.9-0.7,2.9c-0.3,1.8-0.6,3.4-0.7,3.6c0,0.1-0.2,0.2-0.4,0.4
		C8.7,17,8,17.7,7.1,18.9c0,0-0.8,1-0.6,1.4c0.1,0.2,0.2,0.3,0.4,0.3c0,0,0,0,0,0c0.3,0,0.4-0.2,0.6-0.6c0-0.1,0-0.1,0.1-0.2
		c0.1-0.1,0.1-0.2,0.2-0.3c0.9-1.2,1.6-1.8,2-2.2c0.3-0.3,0.5-0.5,0.6-0.7c0.1-0.3,0.3-1.3,0.6-2.6c0.3,0.2,0.7,0.3,1.1,0.5
		c0.4,0.2,0.9,0.3,1,0.5c0.3,0.4,0.1,2.4,0,3.4C13,19,13,19.6,13,19.9c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0-0.3,0.1-0.9,0.1-1.5
		c0.2-2,0.3-3.5-0.2-4.1c-0.3-0.4-0.9-0.6-1.5-0.8c-0.5-0.2-0.9-0.3-1.1-0.6c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0.1-0.7,0.3-1.5,0.5-2.2
		c1.2,0.6,2.9,1.1,4.3,1.1c0.2,0,0.3,0,0.5,0c-0.1,0.8-0.2,1.6-0.4,2.5c-0.3,1.9-0.6,3.8-0.6,5.8c0,0.3,0.2,0.5,0.5,0.5
		s0.5-0.2,0.5-0.5c0-1.9,0.3-3.8,0.6-5.6c0.2-1.4,0.5-2.9,0.6-4.4C17.6,9.7,17.4,9.5,17.1,9.5z"
        fill="currentColor"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M8.8,15.5c-0.2,0-0.5,0-0.7-0.2C7.4,15.1,7,14.5,7,13.6c0-1.1,0.6-2.6,1.4-3.2c-0.1,0-0.1-0.1-0.2-0.1
		c-0.2-0.2-0.2-0.5,0-0.7c1.2-1.1,2-0.8,2.3-0.5c1,0.9,0.8,3.7-0.1,5.2C10,15.1,9.4,15.5,8.8,15.5z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default HikerIcon;
