import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import TuneIcon from '@mui/icons-material/Tune';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopupState, bindMenu } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/auth/useAuth';
import Icon from 'src/common/components/icons/Icon';

interface Props {
  popupState: PopupState;
}

const UserMenu = ({ popupState }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const isAdminPage = pathname.includes('/dashboard/');

  const signOut = () =>
    auth.signOut().catch(
      ({
        response: {
          data: { errorCode, message },
        },
      }) => {
        enqueueSnackbar(errorCode ? t(`signIn.errors.${errorCode}`) : message, {
          variant: 'error',
        });
      },
    );

  return (
    <Menu
      {...bindMenu(popupState)}
      sx={{ mt: !isAdminPage ? '45px' : undefined }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              ...(isAdminPage
                ? {
                    bottom: 10,
                    left: -5,
                    width: 10,
                    height: 10,
                    transform: 'translateY(-50%) rotate(45deg)',
                  }
                : {
                    top: -5,
                    right: 10,
                    width: 10,
                    height: 10,
                    transform: 'translateX(-50%) rotate(45deg)',
                  }),
              bgcolor: 'background.paper',
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{
        horizontal: isAdminPage ? 'left' : 'right',
        vertical: isAdminPage ? 'bottom' : 'top',
      }}
      anchorOrigin={{
        horizontal: isAdminPage ? 'right' : 'right',
        vertical: isAdminPage ? 'bottom' : 'top',
      }}
    >
      {!isAdminPage && (
        <>
          <MenuItem onClick={() => navigate('/dashboard')}>
            <ListItemIcon>
              <TuneIcon fontSize="small" />
            </ListItemIcon>
            {t('drawer.dashboard')}
          </MenuItem>
          <MenuItem onClick={() => navigate('/dashboard/applicants')}>
            <ListItemIcon>
              <Icon icon="agreement" fontSize="large" />
            </ListItemIcon>
            {t('drawer.applicants')}
          </MenuItem>
        </>
      )}
      {!isAdminPage && <Divider light />}

      <MenuItem onClick={() => navigate('/dashboard/profile')}>
        <ListItemIcon>
          <AccountCircleIcon fontSize="small" />
        </ListItemIcon>
        {t('drawer.profile')}
      </MenuItem>
      <Divider light />
      <MenuItem onClick={signOut}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {t('drawer.signOut')}
      </MenuItem>
    </Menu>
  );
};
export default UserMenu;
