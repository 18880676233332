import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PenIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M15.8,7.6C16,6.8,16.2,6,16,5.5c-0.1-0.3-0.3-0.4-0.4-0.4c-0.1,0-0.3-0.1-0.6,0.1c-1.3,0.7-5.3,7.9-6.3,11.4
	c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c-0.2,0-0.3,0.1-0.4,0.3c-0.3,0.6-0.6,1.2-0.2,1.5c0.1,0.1,0.2,0.1,0.3,0.1
	c0.3,0,0.7-0.4,1.3-1l0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.2c0.4-0.2,0.8-0.5,1-0.8c0.9-1.1,1.7-2.2,2.4-3.5c0,0,0,0,0,0
	c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.3,0.3-0.5c-0.1,0.2,0,0.4,0.2,0.6c0.1,0,0.2,0.1,0.2,0.1c0.2,0,0.3-0.1,0.4-0.2l0.1-0.1
	C15.6,11.3,17.1,8.8,15.8,7.6z M15.1,6.3c-0.1,0.7-0.4,2-0.6,2.5c-0.4,1.1-0.9,2.1-1.4,3c-0.3-0.3-0.5-0.4-0.8-0.6l-0.1-0.1
	c0,0-0.1,0-0.1-0.1C13.3,8.9,14.5,7,15.1,6.3z M10.4,15.9c-0.2,0.3-0.5,0.5-0.7,0.6c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1
	c0.4-1.3,1.1-2.9,1.9-4.5l0.1,0c0.3,0.2,0.6,0.4,0.9,0.7C11.9,13.9,11.2,14.9,10.4,15.9z M14.1,11.9c0.4-0.8,0.8-1.6,1.1-2.5
	C15.1,10,14.7,10.8,14.1,11.9z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default PenIcon;
