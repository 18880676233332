import { useLocation } from 'react-router-dom';

import AdminDrawer from './AdminDrawer';
import TopMenu from './TopMenu';

const MenuComponent = () => {
  const { pathname } = useLocation();
  return pathname.includes('/dashboard/') ? <AdminDrawer /> : <TopMenu />;
};
export default MenuComponent;
