import { AxiosResponse } from 'axios';

import Resolver from 'src/common/Resolver';
import usePageContentsStore from 'src/stores/pageContentsStore';
import useTrackMapsStore from 'src/stores/trackMapsStore';
import Trails from './Trails';

export interface ResultsRouteParams {
  competition: string;
}

const TrailsResolver = () => {
  const { fetchTrackMaps: doFetchTrackMaps } = useTrackMapsStore(trackMapsState => trackMapsState);
  const { fetchPageContents: doFetchPageContents } = usePageContentsStore(
    pageContentsStore => pageContentsStore,
  );

  const loadDependencies = () => {
    const deps: Array<Promise<void | AxiosResponse<any, any>>> = [
      doFetchTrackMaps(),
      doFetchPageContents(),
    ];

    return Promise.all(deps);
  };

  return <Resolver resolve={loadDependencies} successComponent={Trails} />;
};

export default TrailsResolver;
