import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { IEdition } from 'src/interfaces/Edition';
import EditionEditorForm, { IFormEdition } from 'src/pages/Dashboard/Editions/forms/EditionEditorForm';

interface Props extends DialogProps {
  handleClose: () => void;
  handleSave: (editedEdition: IFormEdition, id?: string) => void;
  editionToEdit?: IEdition;
}

const EditionEditorModal = ({ id, open, handleClose, handleSave, editionToEdit }: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} aria-labelledby={`${id}-modal-title`}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label={t('base.close')}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" id={`${id}-modal-title`}>
            {!!editionToEdit
              ? t('editions.editEditionModalTitle', {
                  year: `${editionToEdit.year}`,
                })
              : t('editions.addEditionModalTitle')}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <EditionEditorForm
          handleSave={handleSave}
          handleClose={handleClose}
          editionToEdit={editionToEdit}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditionEditorModal;
