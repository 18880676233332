import { MouseEvent, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import find from 'lodash-es/find';

import { useTranslation } from 'react-i18next';
import TableHead from 'src/common/components/table/TableHead';
import { LanguageKeys } from 'src/common/constants/languages';
import { Competition } from 'src/interfaces/Competition';
import { IApplicant } from 'src/interfaces/Applicant';
import { Order } from 'src/interfaces/Order';
import { HeadCell } from 'src/interfaces/Table';
import { TechnicalType } from 'src/interfaces/TechnicalType';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import useCheckinsStore from 'src/stores/checkinsStore';

interface Props<T> {
  applicant: IApplicant;
  optionElement?: JSX.Element;
  collapsibleTableHeadProps?: {
    onRequestSort?: (event: MouseEvent<unknown>, property: T) => void;
    order?: Order;
    orderBy?: string;
    headCells: readonly HeadCell<T>[];
  };
}

const ApplicantTableRow = <T extends unknown>({
  collapsibleTableHeadProps,
  applicant: {
    age,
    checkedIn,
    city,
    competition: competitionId,
    distance: distanceId,
    email,
    firstName,
    gender,
    id,
    lastName,
    phoneNumber,
    price,
  },
  optionElement,
}: Props<T>) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  const [open, setOpen] = useState(!!collapsibleTableHeadProps ? false : true);

  const { competitions, distances } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );
  const { checkinsById } = useCheckinsStore(checkinsState => checkinsState);

  const competition = find(competitions, c => c.id === competitionId) as Competition;
  const competitionName = competition.i18n[currentLang];

  const distance = find(distances, d => d.id === distanceId) as TechnicalType;
  const distanceName = distance.i18n[currentLang];

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={id}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {checkedIn && checkinsById ? checkinsById[checkedIn].ser : '-'}
        </TableCell>
        <TableCell>{`${lastName} ${firstName}`}</TableCell>
        <TableCell>{phoneNumber}</TableCell>
        <TableCell>{competitionName}</TableCell>
        <TableCell>{distanceName}</TableCell>
        {!!optionElement ? <TableCell align="center">{optionElement}</TableCell> : null}
      </TableRow>
      {collapsibleTableHeadProps ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead<T> {...collapsibleTableHeadProps} />
                  <TableBody>
                    <TableRow>
                      <TableCell>{email}</TableCell>
                      <TableCell>{city}</TableCell>
                      <TableCell>{age}</TableCell>
                      <TableCell>{t(`base.gender.${gender}`)}</TableCell>
                      <TableCell>{t('base.currencyValue', { count: price })}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

export default ApplicantTableRow;
