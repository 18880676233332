import { AxiosResponse } from 'axios';
import mapKeys from 'lodash-es/mapKeys';
import { create } from 'zustand';

import { SECURE_API } from 'src/api/api';
import { BasePageContent, PageContent } from 'src/interfaces/PageContent';

export interface PageContentsState {
  pageContents: PageContent[];
  pageContentsById?: { [key: string]: PageContent };
  pageContentsByName?: { [key: string]: PageContent };

  isLoading: boolean;

  deleteAllPageContents: () => Promise<void | AxiosResponse<null>>;
  deletePageContent: (id: string) => Promise<void | AxiosResponse<null>>;
  fetchPageContents: () => Promise<void | AxiosResponse<null>>;
  resetPageContents: () => void;
  savePageContent: (
    pageContent: BasePageContent,
    id?: string,
  ) => Promise<void | AxiosResponse<PageContent[]>>;
}

const usePageContentsStore = create<PageContentsState>(set => {
  const getPageContents = async () => {
    return SECURE_API.get<PageContent[]>('pageContents/')
      .then(({ data }) => {
        set({
          pageContents: data,
          pageContentsById: mapKeys(data, 'id'),
          pageContentsByName: mapKeys(data, 'name'),
          isLoading: false
        });
      })
      .catch((error: string) => {
        set({
          pageContents: [],
          pageContentsById: undefined,
          pageContentsByName: undefined,
          isLoading: false
        });
        console.log({ error });
      });
  };

  return {
    pageContents: [],
    pageContentsById: undefined,
    pageContentsByName: undefined,

    isLoading: false,

    fetchPageContents: async () => {
      set({ isLoading: true });
      return getPageContents();
    },

    savePageContent: async (pageContent: BasePageContent, id?: string) => {
      set({ isLoading: true });
      const savePageContentPromise = id
        ? SECURE_API.put<PageContent[]>(
            `pageContents/update/${id}`,
            pageContent,
          )
        : SECURE_API.post<PageContent[]>(
            'pageContents/save',
            pageContent,
          );
      return savePageContentPromise
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getPageContents();
        });
    },
    deletePageContent: async id => {
      set({ isLoading: true });
      return SECURE_API.delete<null>(`pageContents/delete/${id}`)
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getPageContents();
        });
    },
    deleteAllPageContents: async () => {
      set({ isLoading: true });
      return SECURE_API.delete<null>(`pageContents/delete/bulk`)
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getPageContents();
        });
    },
    resetPageContents: () => set({ pageContents: [] }),
  };
});

export default usePageContentsStore;
