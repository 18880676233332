import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AgreementIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path
        d="M7.1,8.6c0.3,0,0.6-0.1,0.9-0.1c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.4-0.1,0.4-0.3S9.1,7.7,8.9,7.7c-0.4,0-0.8,0-1.1,0.1
		C7.5,7.8,7.2,7.9,6.9,7.9C6.7,7.8,6.5,8,6.5,8.2c0,0.2,0.1,0.4,0.3,0.4C6.9,8.6,7,8.6,7.1,8.6z"
        fill="currentColor"
      />
      <path
        d="M6.4,10.4c0,0.2,0.1,0.4,0.3,0.4c0.2,0,0.4,0,0.6,0c0.5,0,1.1,0,1.6-0.1c0.7-0.1,1.4-0.1,2-0.1c0.2,0,0.4-0.1,0.4-0.3
		c0-0.2-0.1-0.4-0.3-0.4c-0.7-0.1-1.4,0-2.1,0.1c-0.7,0.1-1.4,0.1-2.1,0.1C6.6,10.1,6.4,10.2,6.4,10.4z"
        fill="currentColor"
      />
      <path
        d="M11.1,12.6c0.2,0,0.3-0.2,0.3-0.4c0-0.2-0.2-0.3-0.4-0.3c-1.1,0.1-1.6,0-2.4-0.1c-0.3,0-0.6-0.1-0.9-0.1
		c-0.3,0-0.5,0.2-0.7,0.3c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.3,0.3-0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.1,0,0.1,0
		c0.2-0.1,0.3-0.2,0.4-0.3c0,0,0.1-0.1,0-0.1c0.3,0,0.6,0.1,0.9,0.1C9.3,12.7,9.9,12.8,11.1,12.6z"
        fill="currentColor"
      />
      <path
        d="M7.9,14.1c-0.2,0-0.5,0.1-0.8,0.1c-0.2,0-0.4,0.1-0.4,0.3c0,0.2,0.1,0.4,0.3,0.4c0.1,0,0.2,0,0.2,0c0.3,0,0.6,0,0.8-0.1
		c0.4-0.1,0.7-0.1,1.4,0c0.2,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.4-0.3-0.4C8.7,14,8.3,14,7.9,14.1z"
        fill="currentColor"
      />
      <path
        d="M19.2,8.5c-0.5-0.4-0.9-0.5-1.3-0.4c-0.4,0.1-0.7,0.5-0.9,0.8c-0.1,0.2-0.2,0.3-0.4,0.5c-0.3,0.4-0.7,0.9-1.2,1.5
		c0-0.8,0-1.4,0.1-1.8c0.1-1.3,0-1.5-1.4-2.7l-0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.3-0.5-0.7-0.8-0.8C12.4,5,11.6,5,9,5.1
		C7.8,5.2,6.3,5.3,6,5.2c0,0-1.5,0-1.7,0.6c-0.3,0.8-0.2,2.5,0,4.7c0.1,0.7,0.1,1.3,0.1,1.6c0,1.7,0,3.4,0.2,5.2
		c0,0.2,0.2,0.4,0.5,0.4c0,0,0,0,0.1,0c0.3,0,0.5-0.3,0.4-0.5c-0.2-1.7-0.2-3.5-0.2-5.1c0-0.4,0-1-0.1-1.7C5.1,9,4.9,7,5.1,6.3
		c0.2-0.1,0.6-0.1,0.7-0.1c0.4,0.1,1.5,0,3.2-0.1c1,0,2.5-0.1,3.1-0.1c0,0.1,0,0.3,0.1,0.4c0.1,0.2,0,0.4-0.1,0.8
		c-0.2,0.3-0.3,0.7-0.2,1.1c0.1,0.3,0.2,0.4,0.6,0.4c0.2,0,0.4,0,0.7-0.1c0.4,0,1.2-0.1,1.4-0.1c0.1,0,0.1,0,0.2,0
		c0,0.2,0,0.4,0,0.7c0,0.4-0.1,1-0.1,1.8c0,0.3,0.2,0.5,0.5,0.5c0,0,0.1,0,0.1,0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.5,0.6-0.9,1.2-1.2,1.6
		c0,0-0.1,0.1-0.1,0.2c-0.5,0.6-0.8,2.3-0.8,2.3c-0.1,0.3,0,0.5,0.2,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0,0.6-0.2,1.1-0.5
		c-0.1,0.3-0.1,0.6,0,1.2c0,0.2,0.1,0.5,0.1,0.7c-1.5,0.1-8.3,0.1-9,0.1c-0.3,0-0.5,0.2-0.5,0.4c0,0.3,0.2,0.5,0.4,0.5
		c0,0,2.1,0,4.4,0c2.4,0,5,0,5.3-0.2c0.5-0.2,0.4-0.9,0.3-1.7c0-0.2-0.1-0.6-0.1-0.7c0.1-0.2,0-0.5-0.2-0.6c-0.1,0-0.2-0.1-0.3,0
		c0,0,0,0,0,0c0.3-0.2,0.6-0.4,0.7-0.4c0.1,0,0.2-0.1,0.3-0.2c0.3-0.2,0.9-1.1,3-3.9c0.1-0.1,0.2-0.3,0.3-0.4
		c0.4-0.4,0.8-0.9,0.7-1.4C19.8,9.1,19.6,8.8,19.2,8.5z M13,7.6c-0.1,0-0.1,0-0.2,0c0,0,0-0.1,0-0.1c0.1-0.2,0.2-0.4,0.2-0.7
		c0,0,0.1,0.1,0.1,0.1l0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3C13.7,7.6,13.4,7.6,13,7.6z M14,15.2c0.1-0.3,0.2-0.6,0.3-0.8
		c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0.1,0.1,0.1c-0.1,0.1-0.2,0.1-0.4,0.2C14.3,15,14.2,15.1,14,15.2z M18.5,10.2
		c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.2-1.9,2.6-2.6,3.4c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.3-0.2-0.4-0.4c0.3-0.4,0.6-0.8,1-1.3
		c0.6-0.8,1.3-1.7,1.7-2.2c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.2-0.3,0.3-0.4c0,0,0.1,0,0.5,0.3c0.2,0.1,0.2,0.2,0.2,0.2
		C18.9,9.7,18.6,10,18.5,10.2z"
        fill="currentColor"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M11.4,17.3c-0.5,0-0.6-0.5-0.7-0.8c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0.1c-0.1,0.2-0.3,0.5-0.7,0.6
		c-0.2,0.1-0.5,0-0.7,0c-0.3,0-0.5,0-0.7,0c-0.3,0.1-0.5-0.1-0.6-0.3c-0.1-0.3,0.1-0.5,0.3-0.6c0.4-0.1,0.8-0.1,1.1,0
		c0.1,0,0.4,0,0.4,0c0,0,0.1-0.1,0.1-0.2c0.2-0.2,0.5-0.8,1.1-0.6c0.5,0.1,0.6,0.5,0.8,0.9c0.1-0.1,0.1-0.1,0.2-0.2
		c0.4-0.5,0.9-1.1,1.4-0.9c0.5,0.2,0.5,0.9,0.5,1.2c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0,0,0c-0.3,0-0.5-0.2-0.5-0.5c0,0,0,0,0,0
		c-0.1,0.1-0.2,0.2-0.2,0.3C12.1,16.9,11.8,17.3,11.4,17.3C11.4,17.3,11.4,17.3,11.4,17.3z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default AgreementIcon;
