import { MouseEvent } from 'react';

import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';

import { GRID_VIEW, TABLE_VIEW, View } from 'src/interfaces/View';
import { useTranslation } from 'react-i18next';

interface Props {
  onSetView: (v: View) => void;
  view: View;
}

const ViewSwitch = ({ onSetView, view }: Props) => {
  const { t } = useTranslation();
  return (
    <ToggleButtonGroup
      size="small"
      color="primary"
      value={view}
      exclusive
      onChange={(_e: MouseEvent<HTMLElement>, newView: View) => {
        // TODO toggle
        console.log({ a: view !== newView, view, newView });
        if (newView) onSetView(newView);
      }}
      aria-label={t('viewSwitch.view')}
      sx={{
        borderRadius: 20,
      }}
    >
      <Tooltip title={t('viewSwitch.listView')}>
        <ToggleButton
          value={TABLE_VIEW}
          selected={view === TABLE_VIEW}
          aria-label={t('viewSwitch.list')}
          sx={{
            borderRadius: 20,
            pl: 2,
          }}
        >
          <ViewListRoundedIcon />
        </ToggleButton>
      </Tooltip>
      <Tooltip title={t('viewSwitch.gridView')}>
        <ToggleButton
          value={GRID_VIEW}
          selected={view === GRID_VIEW}
          aria-label={t('viewSwitch.grid')}
          sx={{
            borderRadius: 20,
            pr: 2,
          }}
        >
          <ViewModuleRoundedIcon />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};

export default ViewSwitch;
