import ClearIcon from '@mui/icons-material/Clear';
import TuneIcon from '@mui/icons-material/Tune';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip from '@mui/material/Tooltip';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';

import Searcher from 'src/common/components/Searcher';
import Sorter from 'src/common/components/Sorter';
import ViewSwitch from 'src/common/components/ViewSwitch';
import { ECheckinFilters } from 'src/interfaces/Checkin';
import { Order } from 'src/interfaces/Order';
import { View } from 'src/interfaces/View';
import { NAME, SER } from 'src/pages/Dashboard/Applicants/constants/applicantSortKeys';

interface Props {
  filter: string;
  onChange: (key: ECheckinFilters, value: string | null) => void;
  onSetView: (v: View) => void;
  order: Order;
  orderByKey: string;
  setFilter: (q: string) => void;
  setOrder: (o: Order) => void;
  setOrderByKey: (k: string) => void;
  timerFinishedFilter: string | null;
  timerStartedFilter: string | null;
  view: View;
}

const CompetitionFilterForm = ({
  filter,
  onChange,
  onSetView,
  order,
  orderByKey,
  setFilter,
  setOrder,
  setOrderByKey,
  timerFinishedFilter,
  timerStartedFilter,
  view,
}: Props) => {
  const { t } = useTranslation();
  const filterMenu = usePopupState({ variant: 'popover', popupId: 'filterMenu' });

  const sortOptions = [
    {
      label: t('form.ser'),
      value: SER,
    },
    {
      label: t('form.name'),
      value: NAME,
    },
  ];

  const checkedOptions = [
    {
      label: t('form.yes'),
      value: 'true',
    },
    {
      label: t('form.no'),
      value: 'false',
    },
  ];

  return (
    <Grid container spacing={2} sx={{ display: 'flex' }}>
      <Grid item xs={12} sm={6} md={5} display="flex" justifyContent="flex-end">
        <Searcher
          AddonIcon={
            <IconButton {...bindTrigger(filterMenu)} edge="end">
              <TuneIcon />
            </IconButton>
          }
          onChange={setFilter}
          query={filter}
          width={100}
        />
      </Grid>
      <Grid item xs={12} sm md={4}>
        <Sorter
          order={order}
          orderByKey={orderByKey}
          setOrder={setOrder}
          setOrderByKey={setOrderByKey}
          sortOptions={sortOptions}
        />
        <Popover
          {...bindMenu(filterMenu)}
          keepMounted
          slotProps={{
            paper: {
              style: {
                width: 400,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid
              item
              sm={6}
              component={FormControl}
              margin="dense"
              size="small"
              flexDirection="column"
            >
              <FormLabel
                id="timerStarted-radio-group"
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {t('timers.started')}
                {!!timerStartedFilter && (
                  <Tooltip title={t('form.clearFilter')}>
                    <IconButton
                      size="small"
                      onClick={() => onChange(ECheckinFilters.timerStarted, null)}
                      sx={{ padding: 0 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </FormLabel>
              <RadioGroup
                aria-labelledby="timerStarted-radio-group"
                value={timerStartedFilter}
                onChange={(_e, v) => onChange(ECheckinFilters.timerStarted, v || null)}
              >
                {checkedOptions.map(c => (
                  <FormControlLabel
                    key={c.value}
                    value={c.value}
                    control={<Radio size="small" />}
                    label={c.label}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid
              item
              sm={6}
              component={FormControl}
              margin="dense"
              size="small"
              flexDirection="column"
            >
              <FormLabel
                id="timerFinished-radio-group"
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {t('timers.stopped')}
                {!!timerFinishedFilter && (
                  <Tooltip title={t('form.clearFilter')}>
                    <IconButton
                      size="small"
                      onClick={() => onChange(ECheckinFilters.timerFinished, null)}
                      sx={{ padding: 0 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </FormLabel>
              <RadioGroup
                aria-labelledby="timerFinished-radio-group"
                value={timerFinishedFilter}
                onChange={(_e, v) => onChange(ECheckinFilters.timerFinished, v || null)}
              >
                {checkedOptions.map(c => (
                  <FormControlLabel
                    key={c.value}
                    value={c.value}
                    control={<Radio size="small" />}
                    label={c.label}
                  />
                ))}
              </RadioGroup>
            </Grid>
          </Grid>
        </Popover>
      </Grid>
      <Grid item xs={12} sm="auto">
        <ViewSwitch view={view} onSetView={onSetView} />
      </Grid>
    </Grid>
  );
};

export default CompetitionFilterForm;
