import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import moment from 'moment';
import { Fragment } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectSchema, array, boolean, date, number, object, string } from 'yup';

import 'moment/locale/hu';
import 'moment/locale/ro';

import { disableAutofillBg } from 'src/common/components/constants/disableAutofillBg';
import {
  ControlledSelectField,
  SelectOption,
} from 'src/common/components/form/controlled/ControlledSelectField';
import { LanguageKeys } from 'src/common/constants/languages';
import { IBaseEdition, IEdition, IEditionCompetition } from 'src/interfaces/Edition';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

export type IFormEdition = IBaseEdition & {};

interface EditionEditorFormProps {
  handleClose?: () => void;
  handleSave: (editedEdition: IFormEdition, id?: string) => void;
  editionToEdit?: IEdition;
}

const EditionEditorForm = (props: EditionEditorFormProps) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  const { handleSave, handleClose, editionToEdit } = props;

  const editionSchema: ObjectSchema<IFormEdition> = object()
    .shape({
      canShowResults: boolean(),
      isSignupOpen: boolean(),
      childPrice: number().required(t('form.yup.genericRequired')),
      competitions: array()
        .of(
          object().shape({
            competitionId: string().required(t('form.yup.genericRequired')),
            distances: array()
              .of(string().required(t('form.yup.genericRequired')))
              .required(t('form.yup.genericRequired')),
          }),
        )
        .required(t('form.yup.genericRequired')),
      date: date().defined().required(t('form.yup.genericRequired')),
      discountedPrice: number().required(t('form.yup.genericRequired')),
      fullPrice: number().required(t('form.yup.genericRequired')),
      signupDeadline: date().defined().required(t('form.yup.genericRequired')),
      year: number().required(t('form.yup.genericRequired')),
      youngPrice: number().required(t('form.yup.genericRequired')),
    })
    .required();

  const defaultValues = editionToEdit
    ? {
        canShowResults: editionToEdit.canShowResults,
        isSignupOpen: editionToEdit.isSignupOpen,
        childPrice: editionToEdit.childPrice,
        competitions: editionToEdit.competitions || [],
        date: editionToEdit.date,
        discountedPrice: editionToEdit.discountedPrice,
        fullPrice: editionToEdit.fullPrice,
        signupDeadline: editionToEdit.signupDeadline,
        year: editionToEdit.year,
        youngPrice: editionToEdit.youngPrice,
      }
    : {
        competitions: [] as IEditionCompetition[],
      };

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<IFormEdition>({
    resolver: yupResolver(editionSchema),
    defaultValues,
  });

  const {
    fields: competitionFields,
    append,
    remove,
  } = useFieldArray<IFormEdition>({
    control,
    name: 'competitions',
  });

  const { competitions, distances } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const systemOptions: SelectOption[] = [];
  const distanceOptions: SelectOption[] = [];

  competitions.forEach(comp =>
    systemOptions.push({
      label: comp.i18n[currentLang],
      value: comp.id,
    }),
  );

  distances.forEach(dist =>
    distanceOptions.push({
      label: dist.i18n[currentLang],
      value: dist.id,
    }),
  );

  const onSubmit: SubmitHandler<IFormEdition> = async formData => {
    handleSave(formData, editionToEdit?.id);
  };

  const dateValue = moment(watch('date')) || null;
  const signupDeadlineValue = moment(watch('signupDeadline')) || null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            sx={disableAutofillBg}
            type="number"
            label={t('editions.yearLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['year']}
            helperText={errors['year']?.message}
            {...register('year')}
          />
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={currentLang}>
            <MobileDateTimePicker
              label={t('editions.dateLabel')}
              value={dateValue}
              onChange={newValue => {
                setValue('date', newValue?.toDate() as Date);
              }}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={5}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={currentLang}>
            <MobileDateTimePicker
              label={t('editions.signupDeadlineLabel')}
              ampm={false}
              maxDateTime={moment(dateValue)}
              value={signupDeadlineValue}
              onChange={newValue => {
                setValue('signupDeadline', newValue?.toDate() as Date);
              }}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={3}>
          <TextField
            sx={disableAutofillBg}
            type="number"
            label={t('editions.childPriceLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['childPrice']}
            helperText={errors['childPrice']?.message}
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('base.currency')}</InputAdornment>,
            }}
            {...register('childPrice')}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            sx={disableAutofillBg}
            type="number"
            label={t('editions.youngPriceLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['youngPrice']}
            helperText={errors['youngPrice']?.message}
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('base.currency')}</InputAdornment>,
            }}
            {...register('youngPrice')}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            sx={disableAutofillBg}
            type="number"
            label={t('editions.discountedPriceLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['discountedPrice']}
            helperText={errors['discountedPrice']?.message}
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('base.currency')}</InputAdornment>,
            }}
            {...register('discountedPrice')}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            sx={disableAutofillBg}
            type="number"
            label={t('editions.fullPriceLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['fullPrice']}
            helperText={errors['fullPrice']?.message}
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('base.currency')}</InputAdornment>,
            }}
            {...register('fullPrice')}
          />
        </Grid>

        <>
          {competitionFields.map((field, index) => (
            <Fragment key={field.id}>
              <Grid item xs={4}>
                <ControlledSelectField<IFormEdition>
                  name={`competitions.${index}.competitionId`}
                  control={control}
                  fullWidth
                  label={t('editions.competitionLabel')}
                  margin="dense"
                  options={systemOptions}
                  withEmptyOption
                />
              </Grid>
              <Grid item xs={7}>
                <ControlledSelectField<IFormEdition>
                  name={`competitions.${index}.distances`}
                  control={control}
                  fullWidth
                  label={t('editions.distancesLabel')}
                  margin="dense"
                  options={distanceOptions}
                  withEmptyOption
                  multiple
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip title={t('editions.removeCompetition')}>
                  <IconButton onClick={() => remove(index)} color="error" sx={{ ml: 1 }}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Fragment>
          ))}
          <Grid item xs={12}>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => append({ competitionId: '', distances: [] })}
              startIcon={<AddIcon />}
            >
              {t('editions.appendCompetition')}
            </Button>
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={watch('canShowResults')}
                  onChange={event => setValue('canShowResults', event.target.checked)}
                  inputProps={{ 'aria-label': t('editions.showResults') }}
                />
              }
              label={t('editions.showResults')}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={watch('isSignupOpen')}
                  onChange={event => setValue('isSignupOpen', event.target.checked)}
                  inputProps={{ 'aria-label': t('editions.signupOpen') }}
                />
              }
              label={t('editions.signupOpen')}
            />
          </Grid>
        </>

        {!!errors ? (
          <Grid item xs={12} textAlign="right">
            {Object.keys(errors).map(key => (
              <FormHelperText key={key} error>
                {t('form.keysWithErrors', { key: t(`form.${key}`) })}
              </FormHelperText>
            ))}
          </Grid>
        ) : null}

        <Grid item xs={12} textAlign="right">
          {!!handleClose && (
            <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
              {t('form.cancel')}
            </Button>
          )}
          <Button type="submit" variant="contained" color="primary">
            {t('form.save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditionEditorForm;
