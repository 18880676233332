import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

export interface RadioOption {
  label: string;
  value: string | number | object;
  disabled?: boolean;
}

interface Props<T extends FieldValues, C> {
  control: Control<T, C>;
  disabled?: boolean;
  fullWidth?: boolean;
  label: string;
  margin?: 'dense' | 'normal' | 'none';
  name: FieldPath<T>;
  options: RadioOption[];
  row?: boolean;
}

export const ControlledRadioField = <T extends FieldValues, C extends unknown = any>({
  control,
  disabled,
  fullWidth,
  label,
  margin,
  name,
  options,
  row,
}: Props<T, C>) => {
  const generateRadioOptions = () => {
    return options.map(singleOption => (
      <FormControlLabel
        key={singleOption.value.toString()}
        disabled={singleOption.disabled}
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio />}
      />
    ));
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl key={field.value} disabled={disabled} margin={margin} error={fieldState.invalid} fullWidth={fullWidth}>
          <FormLabel id={`${name}-input-label`}>{label}</FormLabel>
          <RadioGroup
            row={row}
            aria-labelledby={`${name}-input-label`}
            {...field}
          >
            {generateRadioOptions()}
          </RadioGroup>
          {fieldState.invalid && <FormHelperText error>{fieldState.error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
