import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MegaphoneIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g opacity="0.5">
      <path
        d="M9.2,17.6c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.8-0.4-1.2-0.9c-0.2-0.3-0.4-1-0.5-1.8c0-0.1-0.1-0.2-0.1-0.3
		c-0.3-0.1-1.1-0.2-1.5-0.2c-0.8-0.1-1-0.1-1.1-0.4c-0.1-0.2-0.1-0.5,0-1.5c0-0.1,0-0.2,0-0.3c0-0.3,0-0.6,0.1-0.9
		c0.3-0.8,3.3-1.3,3.3-1.3c0.9-0.1,1.4,0,1.6,0.3c0.1,0.2,0.2,0.5,0.1,1.9c0,0.7,0,1.4,0,1.9c0.1,0.8,0.2,1.7,0.5,2.5
		c0.1,0.2,0,0.5-0.2,0.6c-0.1,0-0.1,0.1-0.2,0c0,0.1-0.1,0.2-0.2,0.3C9.6,17.5,9.4,17.6,9.2,17.6z M5.5,13.3c0.1,0,0.2,0,0.3,0
		c1.5,0.2,1.9,0.3,2.1,0.5c0.1,0.1,0.1,0.2,0.3,0.8C8.3,15,8.5,16,8.6,16.2c0.3,0.4,0.5,0.5,0.6,0.5c0.1,0,0.1-0.1,0.2-0.1
		c-0.3-0.8-0.4-1.7-0.5-2.4c0-0.5,0-1.3,0-2c0-0.4,0-1,0-1.3c-0.6-0.1-2.7,0.4-3.2,0.8c0,0.1,0,0.4,0,0.5c0,0.1,0,0.2,0,0.3
		C5.5,12.9,5.5,13.1,5.5,13.3z M5.6,11.7L5.6,11.7L5.6,11.7z"
        fill="currentColor"
      />
    </g>
    <path
      d="M15.2,16.1c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.4-0.3-0.5-0.4c-0.1-0.1-0.3-0.3-0.4-0.3c-1.4-0.7-2.7-1.1-4.1-1.3
	c-0.3,0-0.5-0.3-0.4-0.5c0-0.3,0.3-0.5,0.5-0.4c1.5,0.1,3,0.6,4.5,1.4c0.1,0.1,0.2,0.1,0.3,0.2c0-0.2,0-0.4,0.1-0.7
	c0-0.3,0.1-0.6,0.1-0.9c0-1.5-0.1-2.9-0.3-4.3c0,0,0-0.1,0-0.2c-0.5,0.5-1.2,1.1-1.9,1.5c-0.7,0.4-1.3,0.5-1.8,0.6
	c-0.3,0.1-0.5,0.1-0.8,0.2c-0.2,0.1-0.5,0-0.6-0.3c-0.1-0.3,0-0.5,0.3-0.6c0.3-0.1,0.6-0.2,0.9-0.3c0.5-0.1,1-0.3,1.6-0.6
	C12.8,9,13.5,8.4,14,8c0.5-0.5,0.7-0.6,0.9-0.6c0.5,0,0.5,0.4,0.7,1.4c0.2,1.4,0.3,2.8,0.3,4.4c0,0.3,0,0.6-0.1,0.9
	c0,0.3-0.1,0.6-0.1,0.8c0,0.2,0,0.3-0.1,0.4l0,0.1c0,0.2-0.1,0.4-0.2,0.5C15.4,16.1,15.3,16.1,15.2,16.1z"
      fill="currentColor"
    />
    <path
      d="M16.7,8c-0.3,0-0.5-0.2-0.5-0.5S16.4,7,16.7,7c0.1,0,0.3-0.1,0.5-0.2c0.2-0.1,0.4-0.3,0.6-0.3c0.3-0.1,0.5,0,0.6,0.3
	c0.1,0.3,0,0.5-0.3,0.6c-0.1,0-0.2,0.1-0.4,0.2C17.4,7.8,17.1,8,16.7,8z"
      fill="currentColor"
    />
    <path
      d="M17.1,10.7c-0.2,0-0.4-0.2-0.5-0.4c0-0.3,0.1-0.5,0.4-0.5l0.2,0c0.5-0.1,1.1-0.2,1.7,0c0.3,0.1,0.4,0.3,0.4,0.6
	c-0.1,0.3-0.3,0.4-0.6,0.4c-0.4-0.1-0.8,0-1.3,0L17.1,10.7C17.2,10.7,17.2,10.7,17.1,10.7z"
      fill="currentColor"
    />
    <path
      d="M18.9,13.9c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.5-0.2-0.8-0.2l-0.1,0c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5l0.1,0
	c0.4,0,0.9,0,1.4,0.3c0.2,0.1,0.3,0.4,0.1,0.7C19.2,13.8,19.1,13.9,18.9,13.9z"
      fill="currentColor"
    />
    <path
      d="M18.3,17.1c-0.1,0-0.2,0-0.3-0.1c-0.3-0.2-0.5-0.3-0.8-0.5L17,16.4c-0.2-0.1-0.3-0.4-0.2-0.7c0.1-0.2,0.4-0.3,0.7-0.2
	l0.2,0.1c0.3,0.2,0.6,0.3,0.9,0.5c0.2,0.1,0.3,0.4,0.1,0.7C18.6,17,18.4,17.1,18.3,17.1z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default MegaphoneIcon;
