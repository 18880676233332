import { MouseEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { find, map } from 'lodash-es';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useTitle from 'react-use/lib/useTitle';
import PageBox from 'src/common/components/PageBox';

import { useAuth } from 'src/auth/useAuth';
import ViewSwitch from 'src/common/components/ViewSwitch';
import AppBar from 'src/common/components/styled/AppBar';
import { PERSISTENT_KEY_VIEW } from 'src/common/constants/persistentStorageKeys';
import { IBaseTrackMap, ITrackData, ITrackMap } from 'src/interfaces/TrackMap';
import { GRID_VIEW, TABLE_VIEW, View } from 'src/interfaces/View';
import useTrackMapsStore from 'src/stores/trackMapsStore';
import TrackMapsCard from './components/TrackMapCard';
import TrackMapEditorModal from './modals/TrackMapEditorModal';
import TrackMapsTable from './tables/TrackMapsTable';

const TrackMaps = () => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  useTitle(`${t('system.trackMaps.pageTitle')} - ${t('base.defaultTitle')}`);

  const {
    trackMaps,
    deleteTrackMap: doDeleteTrackMap,
    deleteAllTrackMaps: doDeleteAllTrackMaps,
    fetchTrackMaps: doFetchTrackMaps,
    isLoading,
    saveTrackMap: doSaveTrackMap,
  } = useTrackMapsStore(trackMapsState => trackMapsState);
  const { isSuperAdmin } = useAuth();

  const lsView = localStorage.getItem(PERSISTENT_KEY_VIEW);
  const [view, setView] = useState<View>((lsView as View) || GRID_VIEW);
  const [trackMapEditorModalOpen, setTrackMapEditorModalOpen] = useState(false);
  const [selectedTrackMap, setSelectedTrackMap] = useState<ITrackMap | undefined>(undefined);

  useEffect(() => {
    if (view) {
      localStorage.setItem(PERSISTENT_KEY_VIEW, view);
    } else {
      localStorage.removeItem(PERSISTENT_KEY_VIEW);
    }
  }, [view]);

  const handleOpenTrackMapEditorModal = (id?: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    const thisTrack = find(trackMaps, { id });
    setSelectedTrackMap(thisTrack);
    setTrackMapEditorModalOpen(true);
  };

  const handleCloseTrackMapsEditorModal = () => {
    setSelectedTrackMap(undefined);
    setTrackMapEditorModalOpen(false);
  };

  const handleDeleteAllTrackMaps = async (e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('system.trackMaps.deleteAllConfirm'),
        description: t('system.trackMaps.deleteDescription'),
        confirmationText: t('system.trackMaps.confirmationText'),
        cancellationText: t('system.trackMaps.cancellationText'),
      });
      await doDeleteAllTrackMaps()
        .then(() => {
          enqueueSnackbar(t('system.trackMaps.deleteSuccessfulMessage'), {
            variant: 'success',
          });
        })
        .catch(
          ({
            response: {
              data: { errorCode, message },
            },
          }) => {
            enqueueSnackbar(errorCode ? t(`trackMaps.errors.${errorCode}`) : message, {
              variant: 'error',
            });
          },
        );
    } catch (error) {
      enqueueSnackbar(t('system.trackMaps.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const handleDeleteTrackMap = async (id: string, e?: MouseEvent<unknown>) => {
    e && e.stopPropagation();
    try {
      await confirm({
        title: t('system.trackMaps.deleteConfirm'),
        description: t('system.trackMaps.deleteDescription'),
        confirmationText: t('system.trackMaps.confirmationText'),
        cancellationText: t('system.trackMaps.cancellationText'),
      });
      await doDeleteTrackMap(id);

      enqueueSnackbar(t('system.trackMaps.deleteSuccessfulMessage'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('system.trackMaps.deleteErrorMessage'), {
        variant: 'error',
      });
    }
  };

  const saveTrackMap = async (trackMap: ITrackData & IBaseTrackMap, id?: string) => {
    await doSaveTrackMap(trackMap, id);
    doFetchTrackMaps();
    enqueueSnackbar(t('system.trackMaps.saveTrackMapSuccess'), {
      variant: 'success',
    });
    handleCloseTrackMapsEditorModal();
  };

  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar
          component={Paper}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(3px)',
            mb: 2,
            mt: 2,
            p: 1,
            justifyContent: 'space-between',
          }}
        >
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid item xs="auto">
              <Typography variant="h6" component="div" sx={{ mr: 1 }}>
                {t('system.trackMaps.pageTitle')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => handleOpenTrackMapEditorModal()}
                startIcon={<AddIcon />}
              >
                {t('system.trackMaps.new')}
              </Button>
              {isSuperAdmin ? (
                <Tooltip title={t('system.trackMaps.deleteAllTrackMaps')}>
                  <IconButton onClick={handleDeleteAllTrackMaps} color="error" sx={{ ml: 1 }}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <ViewSwitch view={view} onSetView={setView} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <PageBox isLoading={isLoading}>
        {!!trackMaps.length && (
          <>
            {view === GRID_VIEW && (
              <Grid container spacing={2}>
                {map(trackMaps, trackMap => (
                  <Grid item xs={3} key={trackMap.id}>
                    <TrackMapsCard
                      trackMap={trackMap}
                      onDeleteTrackMap={handleDeleteTrackMap}
                      onEditTrackMap={handleOpenTrackMapEditorModal}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {view === TABLE_VIEW && (
              <TrackMapsTable
                trackMaps={trackMaps}
                onDeleteTrackMap={handleDeleteTrackMap}
                onEditTrackMap={handleOpenTrackMapEditorModal}
              />
            )}
          </>
        )}
      </PageBox>

      {trackMapEditorModalOpen && (
        <TrackMapEditorModal
          id="track-map-editor"
          open={trackMapEditorModalOpen}
          handleClose={handleCloseTrackMapsEditorModal}
          handleSave={saveTrackMap}
          selectedTrackMap={selectedTrackMap}
        />
      )}
    </>
  );
};

export default TrackMaps;
