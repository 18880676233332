import { AxiosResponse } from 'axios';
import mapKeys from 'lodash-es/mapKeys';
import { StateCreator } from 'zustand';

import { SECURE_API } from 'src/api/api';
import { FormTechnicalType } from 'src/common/forms/TechnicalTypeEditorForm';
import { TechnicalType } from 'src/interfaces/TechnicalType';

export interface AccommodationSlice {
  accommodations: TechnicalType[];
  accommodationsById?: { [key: string]: TechnicalType };
  accommodationsByName?: { [key: string]: TechnicalType };

  isLoading: boolean;

  deleteAccommodation: (id: string) => Promise<void | AxiosResponse<null>>;
  deleteAllAccommodations: () => Promise<void | AxiosResponse<null>>;
  fetchAccommodations: () => Promise<void | AxiosResponse<null>>;

  resetAccommodations: () => void;

  saveAccommodation: (
    accommodation: FormTechnicalType,
    id?: string,
  ) => Promise<void | AxiosResponse<TechnicalType[]>>;
}

export const createAccommodationsSlice: StateCreator<
  AccommodationSlice,
  [],
  [],
  AccommodationSlice
> = set => {
  const getAccommodations = async () => {
    return SECURE_API.get<TechnicalType[]>('accommodations/')
      .then(({ data }) => {
        set({
          accommodations: data,
          accommodationsById: mapKeys(data, 'id'),
          accommodationsByName: mapKeys(data, 'name'),
          isLoading: false,
        });
      })
      .catch((error: string) => {
        set({
          accommodations: [],
          accommodationsById: undefined,
          accommodationsByName: undefined,
          isLoading: false,
        });
        console.log({ error });
      });
  };

  return {
    accommodations: [],

    isLoading: false,

    deleteAccommodation: async id => {
      set({ isLoading: true });
      return SECURE_API.delete<null>(`accommodations/delete/${id}`).finally(() => {
        getAccommodations();
      });
    },
    deleteAllAccommodations: async () => {
      set({ isLoading: true });
      return SECURE_API.delete<null>('accommodations/delete/bulk').finally(() => {
        getAccommodations();
      });
    },
    fetchAccommodations: async () => {
      set({ isLoading: true });
      getAccommodations();
    },
    resetAccommodations: () => set({ accommodations: [] }),
    saveAccommodation: async (accommodation: FormTechnicalType, id?: string) => {
      set({ isLoading: true });
      const saveAccommodationPromise = id
        ? SECURE_API.put<TechnicalType[]>(`accommodations/update/${id}`, accommodation)
        : SECURE_API.post<TechnicalType[]>('accommodations/save', accommodation);
      return saveAccommodationPromise
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getAccommodations();
        });
    },
  };
};
