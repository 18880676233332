import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import {
  CalendarIconWrapper,
  DateIcon,
  DateIconDay,
  DateIconMonth,
} from 'src/common/components/styled/CalendarIcon';
import { LanguageKeys } from 'src/common/constants/languages';

export default function CalendarIcon({ eventDate }: { eventDate: Moment }) {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;
  const reversed = currentLang === LanguageKeys.hu;

  return (
    <CalendarIconWrapper reversed={reversed}>
      {eventDate.format('dddd')}
      <DateIcon reversed={reversed}>
        <DateIconDay>{eventDate.format('DD')}</DateIconDay>
        <DateIconMonth>{eventDate.format('MMM')}</DateIconMonth>
      </DateIcon>
    </CalendarIconWrapper>
  );
}
