import styled, { css } from 'styled-components';

export const LoaderBackdrop = styled.div<{
  $isLoading?: boolean;
  blur?: string;
  height?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: ${({ height = '100%' }) => height};
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  overflow: hidden;
  backdrop-filter: ${({ blur = '5px' }) => `blur(${blur})`};
  border-radius: 4px;

  ${({ $isLoading }) =>
    !$isLoading &&
    css`
      opacity: 0;
      transition: opacity 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      visibility: hidden;
    `};
`;
