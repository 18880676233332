import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckCircleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M19.8,5.6c0.3-0.4,0.7-0.8,1.1-1.2c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0.1c-0.3,0.4-0.6,0.8-1,1.1
      c-0.7-0.7-1.4-1.3-2.4-1.8c-3.1-1.6-6.1-1.3-9.4,1C7.1,4.2,7.1,4.5,7.2,4.8C7.4,5,7.7,5,7.9,4.9c3-2.1,5.7-2.4,8.4-1
      c0.9,0.5,1.6,1,2.2,1.7c-2.9,3.4-4.7,6.1-6,7.9c-1,1.5-2,2.8-2.3,2.9c-0.3-0.1-0.8-1.5-1.4-3l-0.5-1.2c-0.1-0.2-0.4-0.4-0.6-0.3
      c-0.2,0.1-0.4,0.4-0.3,0.6l0.5,1.2c0.9,2.5,1.3,3.5,2.2,3.6c0,0,0.1,0,0.1,0c0.8,0,1.5-1,3.1-3.3c1.3-1.8,3.1-4.4,5.9-7.7
      c1.8,2.5,1.9,5.9,1,8.6c-1.1,3.2-4.1,6.8-9.3,6c-3.1-0.5-6.1-2.9-7.1-5.8c-0.6-1.9-0.8-4.7,2.1-7.8C6,7.2,6,6.9,5.8,6.7
      c-0.2-0.2-0.5-0.2-0.7,0c-2.5,2.7-3.3,5.8-2.3,8.7c1.1,3.3,4.4,6,7.9,6.5c0.5,0.1,1,0.1,1.5,0.1c4.8,0,7.7-3.5,8.8-6.8
      C22.1,12.2,21.9,8.4,19.8,5.6z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CheckCircleIcon;
