import Dialog, { DialogProps } from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

import DialogTitle from 'src/common/components/DialogTitle';
import Loader from 'src/common/components/Loader';
import { ICheckin } from 'src/interfaces/Checkin';
import { IApplicant } from 'src/interfaces/Applicant';
import CheckinForm, { IFormCheckin } from 'src/pages/Dashboard/Applicants/forms/CheckinForm';

interface Props extends DialogProps {
  handleClose: () => void;
  handleSave: (checkin: IFormCheckin, id?: string) => void;
  isLoading?: boolean;
  selectedApplicant?: IApplicant;
  selectedCheckin?: ICheckin;
}

const CheckinModal = ({
  id,
  isLoading,
  open,
  handleClose,
  handleSave,
  selectedApplicant,
  selectedCheckin,
}: Props) => {
  const { t } = useTranslation();
  const contestant = selectedCheckin ? selectedCheckin.contestant : selectedApplicant;
  return (
    <Dialog open={open} aria-labelledby={`${id}-modal-title`}>
      <Loader isLoading={isLoading} />
      <DialogTitle id={`${id}-modal-title`} onClose={handleClose}>
        {contestant
          ? t('checkins.checkinModalTitle', {
              name: `${contestant.lastName} ${contestant.firstName}`,
            })
          : '?'}
      </DialogTitle>
      <CheckinForm
        handleSave={handleSave}
        handleClose={handleClose}
        selectedApplicant={selectedApplicant}
        selectedCheckin={selectedCheckin}
      />
    </Dialog>
  );
};

export default CheckinModal;
