import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CaretDownIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M15,6.7c0.1,0,0.1,0,0.2,0c0.2,0.1,0.3,0.2,0.3,0.4c0,0.3-0.1,0.5-0.4,0.5c-0.2,0.1-1,0.7-2,1.5
		c-0.2,0.2-0.5,0.4-0.8,0.6c-0.4,0.3-0.8,0.6-1.1,0.9c-0.6,0.5-1.1,1-1.4,1.4c0.2,0.2,0.8,0.7,1.9,1.7c0.3,0.3,1.3,1,2,1.6
		c0.3,0.4,0.6,0.7,0.7,0.7c0.3,0.2,0.5,0.4,0.7,0.6c0.3,0.1,0.3,0.4,0.2,0.6c-0.1,0.2-0.4,0.3-0.7,0.2l-0.1-0.1
		c-0.2-0.1-0.4-0.3-0.7-0.5c-0.1-0.1-0.4-0.3-0.8-0.6c-0.7-0.6-1.7-1.3-2-1.6c-2.5-2.1-2.5-2.2-2.5-2.5c0-0.1,0-0.2,0.1-0.3
		c0.5-0.6,1.1-1.2,1.8-1.9c0.3-0.3,0.8-0.6,1.2-0.9c0.3-0.2,0.6-0.4,0.8-0.6C14.2,7,14.7,6.7,15,6.7z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CaretDownIcon;
