import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { LanguageKeys } from 'src/common/constants/languages';

import { TechnicalType } from 'src/interfaces/TechnicalType';

interface Props {
  item: TechnicalType & {
    icon?: string;
  };
  optionElement?: JSX.Element;
}

const TechnicalTypeTableRow = ({
  item: { id, name, i18n },
  optionElement,
}: Props) => {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={id}>
      <TableCell>{name}</TableCell>
      <TableCell>{i18n[currentLang]}</TableCell>
      {!!optionElement && <TableCell align="right">{optionElement}</TableCell>}
    </TableRow>
  );
};

export default TechnicalTypeTableRow;
