import { AxiosResponse } from 'axios';
import mapKeys from 'lodash-es/mapKeys';
import { StateCreator } from 'zustand';

import { SECURE_API } from 'src/api/api';
import { FormTechnicalType } from 'src/common/forms/TechnicalTypeEditorForm';
import { TechnicalType } from 'src/interfaces/TechnicalType';

export interface DistanceSlice {
  distances: TechnicalType[];
  distancesById?: { [key: string]: TechnicalType };
  distancesByName?: { [key: string]: TechnicalType };

  isLoading: boolean;

  deleteDistance: (id: string) => Promise<void | AxiosResponse<null>>;
  deleteAllDistances: () => Promise<void | AxiosResponse<null>>;
  fetchDistances: () => Promise<void | AxiosResponse<null>>;

  resetDistances: () => void;

  saveDistance: (
    distance: FormTechnicalType,
    id?: string,
  ) => Promise<void | AxiosResponse<TechnicalType[]>>;
}

export const createDistancesSlice: StateCreator<DistanceSlice, [], [], DistanceSlice> = set => {
  const getDistances = async () => {
    return SECURE_API.get<TechnicalType[]>('distances/')
      .then(({ data }) => {
        set({
          distances: data,
          distancesById: mapKeys(data, 'id'),
          distancesByName: mapKeys(data, 'name'),
          isLoading: false,
        });
      })
      .catch((error: string) => {
        set({
          distances: [],
          distancesById: undefined,
          distancesByName: undefined,
          isLoading: false,
        });
        console.log({ error });
      });
  };

  return {
    distances: [],

    isLoading: false,

    deleteDistance: async id => {
      set({ isLoading: true });
      return SECURE_API.delete<null>(`distances/delete/${id}`).finally(() => {
        getDistances();
      });
    },
    deleteAllDistances: async () => {
      set({ isLoading: true });
      return SECURE_API.delete<null>('distances/delete/bulk').finally(() => {
        getDistances();
      });
    },
    fetchDistances: async () => {
      set({ isLoading: true });
      getDistances();
    },
    resetDistances: () => set({ distances: [] }),
    saveDistance: async (distance: FormTechnicalType, id?: string) => {
      set({ isLoading: true });
      const saveDistancePromise = id
        ? SECURE_API.put<TechnicalType[]>(`distances/update/${id}`, distance)
        : SECURE_API.post<TechnicalType[]>('distances/save', distance);
      return saveDistancePromise
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getDistances();
        });
    },
  };
};
