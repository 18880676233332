import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import Resolver from 'src/common/Resolver';
import { IEditionCompetition } from 'src/interfaces/Edition';
import useApplicantsStore from 'src/stores/applicantsStore';
import useCheckinsStore from 'src/stores/checkinsStore';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import Competition, { Props as CheckinProps } from './Competition';

export interface CompetitionRouteParams {
  competition: string;
  year: string;
}

const CompetitionResolver = () => {
  const { fetchApplicants: doFetchApplicants } = useApplicantsStore(
    applicantsState => applicantsState,
  );

  const { activeEditionId, competitionsByName, editionsByYear } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const { competition: compName, year } = useParams<keyof CompetitionRouteParams>();

  const competition = !!compName && !!competitionsByName && competitionsByName[compName];

  const { fetchCheckinsByCompetitionId: doFetchCheckinsByCompetitionId } = useCheckinsStore(
    checkinsState => checkinsState,
  );

  if (!competition || !year || !editionsByYear || !activeEditionId) return null;

  const selectedEdition = editionsByYear[year];
  const selectedCompetition = selectedEdition.competitions.find(
    c => c.competitionId === competition.id,
  ) as IEditionCompetition;

  const loadDependencies = () => {
    const deps: Array<Promise<void | AxiosResponse<any, any>>> = [
      doFetchApplicants(),
      doFetchCheckinsByCompetitionId(selectedEdition.id, competition.id),
    ];

    return Promise.all(deps);
  };

  if (!competition) return null;

  return (
    <Resolver<CheckinProps>
      resolve={loadDependencies}
      successComponent={Competition}
      successProps={{
        competition,
        distances: selectedCompetition.distances,
        editionId: selectedEdition.id,
      }}
    />
  );
};

export default CompetitionResolver;
