import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FemaleCyclistIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g opacity="0.5">
      <path
        d="M11.6,16.6c0.5,1.7-3.3,0.7-3.3,0.5c-0.1-0.4,0.6-0.4,0.9-0.6c0.6-0.2,1.7-0.1,2,0.1"
        fill="currentColor"
      />
    </g>
    <g>
      <path
        d="M19.3,14.3c-1-0.8-2.5-1-3.8-0.3c-0.1-0.3-0.3-0.6-0.5-0.9c0.5-0.6,1-1.1,1.3-1c0.1,0.2,0.4,0.3,0.7,0.2
		c0.2-0.1,0.3-0.4,0.2-0.7C17,11.2,16.7,11,16.4,11c-0.4,0-0.8,0.2-1.2,0.5c0,0,0,0-0.1,0c-0.4-0.1-0.7-0.3-1.1-0.5
		c-0.5-0.3-1.1-0.5-1.7-0.6c0-0.3,0-0.5,0-0.8c0-0.5,0-1-0.1-1.5c0-0.2-0.1-0.3-0.3-0.3c0,0,0-0.1,0-0.1c-0.1-0.2-0.4-0.3-0.7-0.2
		c-0.7,0.4-1.7,1.2-3,2.6c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
		c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0.1,0.1c0.5,0.6,0.9,1.1,1.2,1.7c-0.3,0.4-0.6,0.9-0.9,1.4
		c-0.9-0.6-2.1-0.7-3.1-0.3c-1.3,0.6-2,1.8-2,2.3c0,0.1,0.1,0.3,0.2,0.4c-0.1,0.1-0.2,0.3-0.2,0.4c0.2,2,1.3,3.5,2.9,3.7
		c0.1,0,0.3,0,0.4,0c1.1,0,2.4-0.6,3.2-2.5c0.1-0.2,0.1-0.4,0.2-0.6l0,0c1.2,0.1,1.5,0,1.7-0.2c0.2-0.2,0.5-0.7,1.1-1.4
		c0.3-0.4,0.9-1.3,1.5-2c0.1,0.2,0.2,0.4,0.3,0.7c0,0-0.1,0.1-0.1,0.1c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.3,0.1,0.5,0.1
		c0.4,0.9,0.9,1.7,1.6,2.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.5,0-0.7c-0.6-0.5-1-1.3-1.4-2.2
		c0.9-0.5,2-0.3,2.8,0.2c0.8,0.6,1.3,1.7,0.5,3c-0.8,1.3-1.9,1.8-2.8,1.5c-0.9-0.3-1.6-1.5-1.3-3.3c0-0.3-0.1-0.5-0.4-0.6
		c-0.3,0-0.5,0.1-0.6,0.4c-0.4,2,0.4,3.8,1.9,4.4c0.3,0.1,0.6,0.2,0.9,0.2c1,0,2.1-0.5,3-2C21.1,16.8,20.5,15.2,19.3,14.3z
		 M11.3,8.6c0.1,0.3,0.1,0.7,0.1,1c0,0.4,0,0.8,0.1,1.3c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.5-0.1,1,0.2,1.6,0.5
		c0,0,0,0,0.1,0c-0.3,0-0.8,0-1.2-0.1c-0.8-0.1-1.7-0.2-2.3,0.1c-0.2-0.5-0.6-1-1-1.5C10.1,9.6,10.8,9,11.3,8.6z M8.5,15.9
		c0.1-0.1,0.2-0.2,0.2-0.4c0.2,0.3,0.4,0.7,0.4,1.1c-0.4,0-0.9,0-1.4,0c0.1-0.1,0.2-0.2,0.3-0.3C8.3,16.1,8.5,15.9,8.5,15.9z
		 M9.8,13.7c0.1,0.5,0.1,1,0,1.6c-0.1-0.3-0.3-0.5-0.5-0.7c0,0,0,0,0,0C9.5,14.3,9.6,14,9.8,13.7z M6.4,19.6c-1-0.2-2-1.1-2.1-2.9
		c0-0.1-0.1-0.2-0.2-0.3c0.1-0.1,0.2-0.2,0.2-0.4c0-0.2,0.5-1,1.4-1.4c0.7-0.3,1.6-0.2,2.3,0.2C8,15,7.9,15.1,7.8,15.3
		c0,0.1-0.2,0.2-0.4,0.4c-1.1,1.1-1.2,1.2-1.1,1.6c0.1,0.3,0.1,0.3,0.9,0.3c0.4,0,1,0,1.8-0.1c0,0.1,0,0.1,0,0.2
		C8.4,19,7.5,19.8,6.4,19.6z M12.1,15.3c-0.4,0.5-0.7,1-0.9,1.2c-0.1,0-0.4,0-0.7,0c0-0.1,0.1-0.1,0.1-0.2c0.3-1.3,0.2-2.5-0.1-3.6
		c0.2-0.1,0.4-0.1,0.6-0.1c0.4,0,0.8,0.1,1.2,0.1c0.6,0.1,1.1,0.1,1.5,0.1C13.4,13.6,12.8,14.4,12.1,15.3z"
        fill="currentColor"
      />
      <path
        d="M11.9,5.8c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.2-0.1,0.4c0.3,1.9,1.2,2.2,1.7,2.2c0,0,0.1,0,0.1,0c1.3-0.1,2.5-1.8,2.5-3.1
		c0-0.8-0.4-1.3-1.1-1.5c-0.9-0.6-2.1-0.7-3.1-0.5c-0.9,0.2-1.5,0.7-1.4,1.2C10.7,5.2,11.1,5.6,11.9,5.8z M13.7,7.6
		c-0.5,0.1-0.8-1-0.8-1.4c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0.2,0,0.4,0.1,0.6,0.1l0.3,0c0.4,0,0.7,0.1,1,0.2c0.1,0,0.1,0,0.2,0
		c0,0,0,0,0.1,0C14.7,7,14.1,7.6,13.7,7.6z M13.5,5c0.2-0.1,0.7-0.2,1.2-0.2C14.9,5,15,5.2,15.1,5.4c0,0,0,0,0,0.1c0,0,0,0-0.1,0
		c-0.4-0.2-0.9-0.2-1.3-0.3l-0.3,0c-0.1,0-0.1,0-0.1,0C13.4,5.1,13.5,5.1,13.5,5z M12.8,4.4c-0.2,0.1-0.3,0.3-0.5,0.5
		c-0.3-0.1-0.5-0.2-0.6-0.3C11.9,4.6,12.3,4.4,12.8,4.4z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default FemaleCyclistIcon;
