import { MouseEvent, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';
import TimerIcon from '@mui/icons-material/Timer';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { LanguageKeys } from 'src/common/constants/languages';
import { minutesToHours } from 'src/helpers/dateTimeFormatters';
import { ECheckinTimerType, ICheckin } from 'src/interfaces/Checkin';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

import 'moment/locale/hu';
import 'moment/locale/ro';

interface Props {
  checkin: ICheckin;
  onClickTimer: (id: string, type: ECheckinTimerType, timeStamp?: Date) => void;
  onDeleteCheckin: (checkinId: string, e?: MouseEvent<unknown>) => void;
  onEditCheckin: (checkinId: string, e?: MouseEvent<unknown>) => void;
}

const CheckinCard = ({
  checkin: {
    id,
    contestant: { firstName, lastName },
    ser,
    startTime: st,
    finishTime: ft,
    duration,
  },
  onClickTimer,
  onDeleteCheckin,
  onEditCheckin,
}: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;
  const { palette } = useTheme();

  const { dateTimeFormat } = useSystemOptionsStore(systemOptionsState => systemOptionsState);

  const [isEditStartTime, setEditStartTime] = useState(false);
  const [isEditFinishTime, setEditFinishTime] = useState(false);
  const [startTime, handleStartTimeChange] = useState<Date | undefined>(st);
  const [finishTime, handleFinishTimeChange] = useState<Date | undefined>(ft);

  const handleEditStartTime = () => {
    if (isEditStartTime && !!startTime) onClickTimer(id, ECheckinTimerType.start, startTime);
    setEditStartTime(!isEditStartTime);
  };

  const handleEditFinishTime = () => {
    if (isEditFinishTime && !!finishTime) onClickTimer(id, ECheckinTimerType.finish, finishTime);
    setEditFinishTime(!isEditFinishTime);
  };

  const startTimeValue = moment(startTime) || null;
  const finishTimeValue = moment(finishTime) || null;

  return (
    <Card
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(3px)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderTopLeftRadius: 40,
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{
              elevation: 1,
              background: `linear-gradient(135deg, ${palette.success.dark} 0%, ${palette.success.light} 100%)`,
              width: 56,
              height: 56,
              mt: 3.5,
              mb: -3.5,
            }}
            aria-label="recipe"
          >
            {ser}
          </Avatar>
        }
        action={
          <>
            <Tooltip title={t('checkins.deleteCheckin')}>
              <IconButton onClick={e => onDeleteCheckin(id, e)} color="error">
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('checkins.editCheckin')}>
              <IconButton onClick={e => onEditCheckin(id, e)} color="secondary">
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        }
        title={`${lastName} ${firstName}`}
        // subheader={}
        sx={{ pt: 1, pb: 0, borderBottom: `1px solid ${grey[600]}` }}
      />
      <CardContent sx={{ mt: 3, pt: 1, pb: 0, flexGrow: 1 }}>
        <Typography variant="body2" color={grey[600]}>
          {t('timers.start')}:
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {!!startTime ? (
            <>
              {!isEditStartTime ? (
                moment(startTime).format(dateTimeFormat)
              ) : (
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={currentLang}>
                  <MobileDateTimePicker
                    ampm={false}
                    openTo="hours"
                    maxDateTime={moment(finishTimeValue)}
                    value={startTimeValue}
                    onChange={newValue => {
                      handleStartTimeChange((newValue?.toDate() as Date) || undefined);
                    }}
                  />
                </LocalizationProvider>
              )}
              <Tooltip title={isEditStartTime ? t('timers.save') : t('timers.editTimer')}>
                <IconButton
                  aria-label={isEditStartTime ? t('timers.save') : t('timers.editTimer')}
                  onClick={handleEditStartTime}
                  edge="end"
                >
                  {isEditStartTime ? (
                    <CheckIcon sx={{ fontSize: 14 }} />
                  ) : (
                    <EditIcon sx={{ fontSize: 14 }} />
                  )}
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Tooltip title={t('timers.startTimer')}>
              <IconButton
                aria-label={t('timers.startTimer')}
                onClick={() => onClickTimer(id, ECheckinTimerType.start)}
              >
                <TimerIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </Typography>

        <Typography variant="body2" color={grey[600]}>
          {t('timers.finish')}:
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {!!startTime && !finishTime ? (
            <Tooltip title={t('timers.stopTimer')}>
              <IconButton
                aria-label={t('timers.stopTimer')}
                onClick={() => onClickTimer(id, ECheckinTimerType.finish)}
              >
                <ShutterSpeedIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          ) : !!finishTime ? (
            <>
              {!isEditFinishTime ? (
                moment(finishTime).format(dateTimeFormat)
              ) : (
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={currentLang}>
                  <MobileDateTimePicker
                    ampm={false}
                    openTo="hours"
                    minDateTime={startTimeValue}
                    value={finishTimeValue}
                    onChange={newValue => {
                      handleFinishTimeChange((newValue?.toDate() as Date) || undefined);
                    }}
                  />
                </LocalizationProvider>
              )}
              <Tooltip title={isEditFinishTime ? t('timers.save') : t('timers.editTimer')}>
                <IconButton
                  aria-label={isEditFinishTime ? t('timers.save') : t('timers.editTimer')}
                  onClick={handleEditFinishTime}
                  edge="end"
                >
                  {isEditFinishTime ? (
                    <CheckIcon sx={{ fontSize: 14 }} />
                  ) : (
                    <EditIcon sx={{ fontSize: 14 }} />
                  )}
                </IconButton>
              </Tooltip>
            </>
          ) : (
            '-'
          )}
        </Typography>

        <Typography variant="body2" color={grey[600]}>
          {t('timers.time')}:
        </Typography>
        <Typography variant="body2">{duration ? minutesToHours(duration, t) : '-'}</Typography>
      </CardContent>
    </Card>
  );
};

export default CheckinCard;
