import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import mapKeys from 'lodash-es/mapKeys';
import omit from 'lodash-es/omit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSetState from 'react-use/lib/useSetState';
import useTitle from 'react-use/lib/useTitle';

import { useAuth } from 'src/auth/useAuth';
import EditPageFab from 'src/common/components/EditPageFab';
import Editor from 'src/common/components/Editor';
import PageBox from 'src/common/components/PageBox';
import { LanguageKeys } from 'src/common/constants/languages';
import { parseTinyMCEContent } from 'src/helpers/parseTinyMCEContent';
import { PageContent } from 'src/interfaces/PageContent';
import usePageContentsStore from 'src/stores/pageContentsStore';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

export default function Details() {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const { isAdmin } = useAuth();
  const currentLang = resolvedLanguage as LanguageKeys;

  useTitle(`${t('race.details.pageTitle')} - ${t('base.defaultTitle')}`);

  const { activeEditionId, dateFormat, editionsById } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );
  const { isLoading, pageContentsByName, savePageContent } = usePageContentsStore(
    pageContentsStore => pageContentsStore,
  );

  const detailsPageContent: PageContent = pageContentsByName?.details as PageContent;
  const detailsPageContentSectionsByName = mapKeys(detailsPageContent.content, 'name');

  const [content, setContent] = useSetState(detailsPageContentSectionsByName.main.i18n);

  const [selectedEditorLang, setSelectedEditorLang] = useState(currentLang);
  const [isEditMode, setIsEditMode] = useState(false);

  const currentEdition =
    editionsById && activeEditionId ? editionsById[activeEditionId] : undefined;

  const toggleEditMode = () => {
    if (isEditMode) {
      setContent(detailsPageContentSectionsByName.main.i18n);
    }
    setIsEditMode(m => !m);
  };

  const updatePageContent = async () => {
    const newContent = {
      ...omit(detailsPageContent, ['createdAt', 'updatedAt']),
      content: [
        {
          ...detailsPageContentSectionsByName.main,
          i18n: { ...content },
        },
      ],
    };

    await savePageContent(newContent, detailsPageContent.id);

    setIsEditMode(false);
  };

  return (
    <PageBox sx={{ pt: 4, pb: 4 }} isLoading={isLoading}>
      <Grid container>
        <Grid item xs={false} sm={3} md={6} />
        <Grid
          item
          xs={12}
          sm={9}
          md={6}
          component={Paper}
          elevation={6}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
            backdropFilter: 'blur(3px)',
          }}
        >
          <Box
            sx={{
              my: 4,
              mx: 4,
            }}
          >
            <Typography component="h2" variant="h2" mb={4}>
              {t('race.details.pageTitle')}
            </Typography>
            {isEditMode ? (
              <Editor
                onChange={newValue => setContent({ [selectedEditorLang]: newValue })}
                value={content[selectedEditorLang]}
              />
            ) : (
              parseTinyMCEContent(content[currentLang], { currentEdition, dateFormat })
            )}
          </Box>
        </Grid>
      </Grid>

      {isAdmin ? (
        <EditPageFab
          isEditMode={isEditMode}
          toggleEditMode={toggleEditMode}
          save={updatePageContent}
          selectedLang={selectedEditorLang}
          switchLang={setSelectedEditorLang}
          position={{ position: 'fixed', bottom: 70, right: 16 }}
        />
      ) : null}
    </PageBox>
  );
}
