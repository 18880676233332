import { Gender } from 'src/common/constants/genders';
import { IApplicant } from './Applicant';
import { AgeGroups } from './Statistics';

export enum ECheckinTimerType {
  start = 'start',
  finish = 'finish',
  clear = 'clear',
};

export interface IBaseCheckin {
  id?: string;
  startTime?: Date;
  finishTime?: Date;
  duration?: number;
}

export interface ICheckin extends IBaseCheckin {
  competition: string;
  contestant: IApplicant;
  distance: string;
  edition: string;
  id: string;
  ser: number;
}

export interface IAnonymizedCheckin {
  age: AgeGroups;
  city: string;
  competition: string;
  distance: string;
  duration?: number;
  gender: Gender;
  name: string;
  price: number;
  ser: number;
  sportsGroupName?: string;
}

export interface IResetCheckin {
  competitionId: string;
  distanceId: string;
  editionId: string;
}

export interface ICheckinTimer {
  id: string;
  type: ECheckinTimerType;
  timeStamp?: Date;
}

export interface IBulkCheckinTimer extends ICheckinTimer {
  distanceId: string;
  editionId: string;
  checkinIds: string[];
}

export type CheckinSortKeys =
  | 'ser'
  | 'contestantName'
  | 'distance'
  | 'startTime'
  | 'finishTime'
  | 'duration';

export enum ECheckinFilters {
  timerStarted = 'timerStarted',
  timerFinished = 'timerFinished',
}