import { ReactNode } from 'react';

import Modal from '@mui/material/Modal';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from 'src/common/components/icons/Close';
import { ModalWrapper } from 'src/common/components/styled/BasicModal';
import DialogContent from '@mui/material/DialogContent';

export default function BasicModal({
  children,
  handleClose,
  id,
  open,
  title,
}: {
  children: ReactNode;
  handleClose: () => void;
  id: string;
  open: boolean;
  title?: string;
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby={`${id}-modal-title`}
    >
      <ModalWrapper sx={{ p: 4 }}>
        {title ? (
          <DialogTitle sx={{ m: 0, p: 2 }} id={`${id}-modal-title`}>
            {title}
            {handleClose ? (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: theme => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
        ) : null}
        <DialogContent>
          {children}
        </DialogContent>
      </ModalWrapper>
    </Modal>
  );
}
