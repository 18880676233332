import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';  
import { useTranslation } from 'react-i18next';

import Flag from 'src/common/components/flags/Flag';
import { LANGUAGES, LanguageKeys } from 'src/common/constants/languages';
import LightTooltip from './styled/LightTooltip';

interface Props {
  isEditMode: boolean;
  position?: { position: React.CSSProperties['position'] | undefined; bottom: number; right: number };
  save(): void;
  selectedLang: LanguageKeys;
  switchLang(lng: LanguageKeys): void;
  toggleEditMode(): void;
}

export default function EditPageFab({
  isEditMode,
  toggleEditMode,
  save,
  selectedLang,
  switchLang,
  position = { position: 'fixed', bottom: 16, right: 16 },
}: Props) {
  const { t } = useTranslation();
  return (
    <Box sx={{ '& > :not(style)': { m: 1 }, ...position }}>
      {isEditMode
        ? LANGUAGES.map(lng => (
            <LightTooltip key={lng.code} title={t(`base.i18n.${lng.code}`)} placement="top">
              <Fab
                onClick={() => {
                  switchLang(lng.code);
                }}
                color="secondary"
                disabled={selectedLang === lng.code}
              >
                <Flag code={lng.code} />
              </Fab>
            </LightTooltip>
          ))
        : null}

      {isEditMode ? (
        <LightTooltip title={t('base.save')} placement="top">
          <Fab color="secondary" aria-label={t('base.save')} onClick={save}>
            <SaveIcon />
          </Fab>
        </LightTooltip>
      ) : null}

      <LightTooltip title={!isEditMode ? t('base.edit') : t('base.cancel')} placement="top">
        <Fab
          aria-label={!isEditMode ? t('base.edit') : t('base.cancel')}
          color={isEditMode ? 'error' : 'secondary'}
          onClick={toggleEditMode}
        >
          {isEditMode ? <CloseIcon /> : <EditIcon />}
        </Fab>
      </LightTooltip>
    </Box>
  );
}
