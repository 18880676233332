import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TrophyIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M17.5,5.9c-0.5-0.5-1.2-0.7-1.9-0.5c-0.3,0.1-0.6,0.2-0.8,0.4c-0.3-0.4-0.6-0.5-0.8-0.5l-0.5,0c-0.7,0-2.1,0-2.8,0
    c-0.2,0-0.4,0.1-0.5,0.3c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.5,0-0.6,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.3-0.3-0.6-0.5-1-0.5
    c-0.7-0.1-1.5,0.2-2,1.3C5.1,9.4,7,10.9,8.5,12c0.5,0.4,0.9,0.7,1.2,1.1c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.2,0.6,0.4,0.9
    c0.1,0.1,0.2,0.1,0.3,0.1c0,0.6-0.2,1.1-0.5,1.5c-0.1,0.1-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1V18c0,0.2,0.1,0.3,0.2,0.5
    c0.2,0.2,0.5,0.2,0.9,0.2c0.1,0,0.3,0,0.5,0l0-0.5l0.1,0.5c1.2-0.1,1.3-0.1,1.5-0.4c0.1-0.2,0.1-0.4,0.1-1.2c0-0.2,0-0.4,0-0.4
    c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0-0.1c-0.2-0.5-0.4-1.1-0.4-1.8c0.1-0.1,0.2-0.2,0.3-0.3c0.2-1.1,1.2-1.8,2.3-2.5
    c1.1-0.7,2.4-1.6,2.8-3C18.3,7.5,18.1,6.5,17.5,5.9z M10.2,6C10.2,6,10.2,6,10.2,6c0.1,0.1,0.2,0.3,0.4,0.3c0.8,0.1,2.2,0,2.9,0
    l0.4,0c0,0,0.1,0.1,0.1,0.4c-0.2,0.3-0.3,0.6-0.4,0.9c-0.1,0.4,0,0.8,0.2,1.1c-0.4,2-1.4,4.4-1.9,4.8c-0.2,0.2-0.4,0.1-0.5,0
    C10.2,12.4,9.6,7.2,10.2,6z M7.1,7c0.2-0.5,0.6-0.8,1-0.7c0.4,0.1,0.8,0.4,0.9,1.2c0,0.3-0.2,0.7-0.4,0.8c0,0-0.1,0-0.2-0.1
    C8.2,8,7.8,7.9,7.7,8.1c-0.2,0.2-0.2,0.5,0,0.7C8,9.2,8.5,9.3,9,9.1C9,9.1,9,9.1,9.1,9c0.1,0.9,0.2,1.8,0.5,2.6
    c-0.2-0.1-0.3-0.2-0.5-0.4C7.6,10.2,6.3,9.1,7.1,7z M12.2,17.6c-0.1,0-0.4,0-0.7,0c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.5,0v-1.1
    c0,0,0,0,0-0.1c0.4-0.6,0.7-1.3,0.7-2.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0.8,0.2,1.5,0.5,2.2c0,0,0,0,0,0c0,0.1,0,0.3,0,0.4
    C12.2,17.3,12.2,17.5,12.2,17.6z M17.1,8.4c-0.3,1.1-1.3,1.8-2.4,2.5c-0.2,0.1-0.3,0.2-0.5,0.4c0.2-0.6,0.4-1.3,0.6-1.9
    c0.4,0.1,0.9,0,1.2-0.4c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0c-0.1,0.1-0.2,0.1-0.3,0.1C15,7.8,15,7.3,15,6.9
    c0.2-0.3,0.5-0.5,0.8-0.6c0.4-0.1,0.8,0,1,0.3C17.2,7,17.3,7.6,17.1,8.4z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default TrophyIcon;
