import { MouseEvent, useState } from 'react';

import AddchartIcon from '@mui/icons-material/Addchart';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import PaymentsIcon from '@mui/icons-material/Payments';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import CompetitionsList from 'src/common/components/CompetitionsList';
import Loader from 'src/common/components/Loader';
import { IEdition } from 'src/interfaces/Edition';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

interface Props {
  activeEditionId?: string;
  edition: IEdition;
  isLoading?: boolean;
  selectedEditionId?: string;
  onDeleteEdition: (editionId: string, e?: MouseEvent<unknown>) => void;
  onEditEdition: (editionId: string, e?: MouseEvent<unknown>) => void;
  onSaveEdition: (editedEdition: IEdition, id?: string) => void;
  onSaveStatistics: (editionId: string, e?: MouseEvent<unknown>) => void;
  onSelectEdition: (edition?: IEdition) => void;
  onToggleActiveEdition: (editionId: string, e?: MouseEvent<unknown>) => void;
}

const EditionCard = ({
  activeEditionId,
  edition,
  edition: { canShowResults, competitions, date, id, signupDeadline, isSignupOpen, year },
  isLoading,
  selectedEditionId,
  onDeleteEdition,
  onEditEdition,
  onSaveEdition,
  onSaveStatistics,
  onSelectEdition,
  onToggleActiveEdition,
}: Props) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { dateFormat, dateTimeFormat } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const [competitionsOpen, setCompetitionsOpen] = useState(false);
  const [totalsOpen, setTotalsOpen] = useState(false);

  const isActive = id === activeEditionId;
  const isSelected = id === selectedEditionId;

  const handleToggleCanShowResults = () =>
    onSaveEdition(
      {
        ...edition,
        canShowResults: !canShowResults,
      },
      id,
    );

  const handleToggleSignupsStatus = () =>
    onSaveEdition(
      {
        ...edition,
        isSignupOpen: !isSignupOpen,
      },
      id,
    );

  const toggleCompetitionsOpen = () => {
    setCompetitionsOpen(!competitionsOpen);
    totalsOpen && setTotalsOpen(!totalsOpen);
  };

  const toggleTotalsOpen = () => {
    setTotalsOpen(!totalsOpen);
    competitionsOpen && setCompetitionsOpen(!competitionsOpen);
  };

  return (
    <Card
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(3px)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderTopLeftRadius: 40,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: isSelected ? palette.primary.main : 'transparent',
      }}
    >
      {isLoading ? <Loader isLoading={isLoading} /> : null}
      <CardHeader
        avatar={
          <Avatar
            sx={{
              elevation: 1,
              background: `linear-gradient(135deg, ${
                isActive ? palette.success.dark : palette.warning.dark
              } 0%, ${isActive ? palette.success.light : palette.warning.light} 100%)`,
              width: 56,
              height: 56,
              mt: 1,
              mb: -1,
            }}
            aria-label="recipe"
          >
            {year}
          </Avatar>
        }
        action={
          <>
            <Tooltip title={t('editions.deleteEdition')}>
              <IconButton onClick={e => onDeleteEdition(id, e)} color="error">
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('editions.editEdition')}>
              <IconButton onClick={e => onEditEdition(id, e)} color="secondary">
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        }
        sx={{ pt: 1, pb: 0, borderBottom: `1px solid ${grey[600]}` }}
      />
      <CardContent sx={{ mt: 3, pt: 1, pb: 0, flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color={grey[600]}>
              {t('editions.eventDate')}:
            </Typography>
            <Typography variant="body2" component="div">
              {moment(date).format(dateFormat)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color={grey[600]}>
              {t('editions.signupDeadline')}:
            </Typography>
            <Typography variant="body2">{moment(signupDeadline).format(dateTimeFormat)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" color={grey[600]}>
                {t('editions.competitions')}:
              </Typography>
              <IconButton aria-label="expand row" size="small" onClick={toggleCompetitionsOpen}>
                {competitionsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {edition.checkinsByPrice ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" color={grey[600]}>
                  {t('editions.totals')}:
                </Typography>
                <IconButton aria-label="expand row" size="small" onClick={toggleTotalsOpen}>
                  {totalsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Collapse in={competitionsOpen} timeout="auto" unmountOnExit>
              <CompetitionsList
                competitions={competitions}
                isSignupOpen={moment().isBefore(signupDeadline) && isSignupOpen}
              />
            </Collapse>
            {edition.checkinsByPrice ? (
              <Collapse in={totalsOpen} timeout="auto" unmountOnExit>
                <List sx={{ width: '100%', pt: 1, pb: 0, flexGrow: 1 }}>
                  {Object.entries(edition.checkinsByPrice).map(([price, count]) => (
                    <ListItem key={price}>
                      <ListItemText
                        primary={t('checkins.checkinsCount', { count })}
                        secondary={t('base.currencyValue', { count: Number(price) })}
                      />
                    </ListItem>
                  ))}
                  <ListItem>
                    <ListItemIcon>
                      <PaymentsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t('checkins.total')}
                      secondary={t('base.currencyValue', {
                        count: edition.totalPrice,
                      })}
                    />
                  </ListItem>
                </List>
              </Collapse>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>

      <CardActions disableSpacing>
        <Tooltip title={isActive ? t('editions.deactivateEdition') : t('editions.activateEdition')}>
          <IconButton
            onClick={e => onToggleActiveEdition(id, e)}
            color={isActive ? 'primary' : 'warning'}
          >
            {isActive ? <ToggleOnIcon /> : <ToggleOffOutlinedIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title={isSelected ? t('editions.usedEdition') : t('editions.useEdition')}>
          <IconButton
            onClick={() => onSelectEdition(isSelected ? undefined : edition)}
            color={isSelected ? 'primary' : 'warning'}
          >
            {isSelected ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title={canShowResults ? t('editions.hideResults') : t('editions.showResults')}>
          <IconButton
            onClick={handleToggleCanShowResults}
            color={canShowResults ? 'primary' : 'warning'}
          >
            {canShowResults ? (
              <AnalyticsIcon color="primary" />
            ) : (
              <AnalyticsOutlinedIcon color="warning" />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={isSignupOpen ? t('editions.closeSignups') : t('editions.openSignups')}>
          <IconButton
            onClick={handleToggleSignupsStatus}
            color={isSignupOpen ? 'primary' : 'warning'}
          >
            {isSignupOpen ? <NoteAltOutlinedIcon color="primary" /> : <NoteAltOutlinedIcon color="warning" />}
          </IconButton>
        </Tooltip>
        <Tooltip title={t('editions.saveStatistics')}>
          <IconButton onClick={e => onSaveStatistics(id, e)} color={'primary'}>
            <AddchartIcon color="primary" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default EditionCard;
