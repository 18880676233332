import DownloadIcon from '@mui/icons-material/Download';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import parse, { DOMNode, Element, Text, attributesToProps, domToReact } from 'html-react-parser';
import moment from 'moment';
import CardMedia from '@mui/material/CardMedia';

import CompetitionsList from 'src/common/components/CompetitionsList';
import { IEdition } from 'src/interfaces/Edition';
import Icon, { IconProps } from 'src/common/components/icons/Icon';

export const parseTinyMCEContent = (
  content: string,
  extraArgs: {
    currentEdition?: IEdition;
    dateFormat?: string;
  } = {},
) => {
  const { currentEdition, dateFormat } = extraArgs;
  const options = {
    replace(domNode: DOMNode) {
      if (domNode instanceof Text && domNode.data && currentEdition && dateFormat) {
        domNode.data = domNode.data
          .replaceAll('{{CurrentEditionDate}}', moment(currentEdition.date).format(dateFormat))
          .replaceAll(
            '{{CurrentEditionSignupDeadline}}',
            moment(currentEdition.signupDeadline).format(dateFormat),
          );
        return domNode;
      } else if (domNode instanceof Element && domNode.attribs) {
        const renderedChildren = () => domToReact(domNode.children as DOMNode[], options);
        if (domNode.children[0] instanceof Text && domNode.children[0].data) {
          switch (domNode.children[0].data) {
            case '{{CompetitionsList}}':
              return currentEdition ? (
                <CompetitionsList
                  competitions={currentEdition.competitions}
                  isSignupOpen={
                    moment().isBefore(currentEdition.signupDeadline) && currentEdition.isSignupOpen
                  }
                />
              ) : null;
          }
        }

        switch (domNode.name) {
          case 'h1':
            return (
              <Typography variant="h1" component="h1" gutterBottom>
                {renderedChildren()}
              </Typography>
            );
          case 'h2':
            return (
              <Typography variant="h2" component="h2" gutterBottom>
                {renderedChildren()}
              </Typography>
            );
          case 'h3':
            return (
              <Typography variant="h3" component="h3" gutterBottom>
                {renderedChildren()}
              </Typography>
            );
          case 'h4':
            return (
              <Typography variant="h4" component="h4" gutterBottom>
                {renderedChildren()}
              </Typography>
            );
          case 'h5':
            return (
              <Typography variant="h5" component="h5" gutterBottom>
                {renderedChildren()}
              </Typography>
            );
          case 'h6':
            return (
              <Typography variant="h6" component="h6" gutterBottom>
                {renderedChildren()}
              </Typography>
            );
          case 'p':
            return (
              <Typography variant="body1" component="p" gutterBottom>
                {renderedChildren()}
              </Typography>
            );

          case 'hr':
            return <Divider variant="middle" />;

          case 'icon': {
            switch (domNode.attribs.class) {
              case 'DownloadIcon':
                return <DownloadIcon {...attributesToProps(domNode.attribs)} />;
              case 'Icon':
                return <Icon {...(attributesToProps(domNode.attribs) as unknown as IconProps)} />;
              default:
                return domNode;
            }
          }

          case 'img':
            return (
              <CardMedia
                component="img"
                src={domNode.attribs.src}
                sx={{
                  height: Number(domNode.attribs.height),
                  width: Number(domNode.attribs.width),
                  float: domNode.attribs.float as React.CSSProperties['float'],
                  marginLeft: domNode.attribs.marginleft,
                  marginRight: domNode.attribs.marginright,
                  objectFit: domNode.attribs.fit as React.CSSProperties['objectFit'],
                }}
                alt={domNode.attribs.alt}
              />
            );

          default:
            return domNode;
        }
      }
    },
  };

  return parse(content, options);
};
