import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CertificateIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g opacity="0.5">
      <path
        d="M11.7,14.9c-0.3,0-0.5-0.3-0.9-0.8c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.1-0.4,0.2-0.6,0.2
        c-0.5,0-0.6-0.5-0.6-1.2c-0.5,0.2-1,0.4-1.3-0.1c-0.1-0.3-0.1-0.6,0.1-1.2C7.6,11.7,7,11.6,7,11.1c0-0.3,0.2-0.6,0.5-0.9
        c-0.4-0.2-0.8-0.6-0.8-1c0-0.6,0.6-0.8,1.1-1c-0.1-0.3-0.3-0.6-0.5-1c-0.1-0.2-0.1-0.3,0-0.5c0.1-0.1,0.3-0.2,0.4-0.2
        c0.3,0,0.7,0.1,1,0.1c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.6-0.4-1-0.2-1.3c0.3-0.4,0.8-0.2,1.3,0c0,0,0.1,0,0.1,0.1c0-0.1,0-0.2-0.1-0.3
        C9.7,4.3,9.6,4,9.8,3.7c0.1-0.1,0.3-0.2,0.4-0.2h0c0.3,0,0.5,0.2,1.1,0.8c0.4-0.8,0.6-1.1,0.9-1.1c0.1,0,0.4,0,0.5,0.1
        c0.1,0,0.2,0.2,0.5,0.8c0.6-0.5,0.8-0.5,0.9-0.5c0.1,0,0.3,0.1,0.4,0.2c0.2,0.2,0.2,0.5,0.1,0.9c0,0,0.1,0,0.1-0.1
        c0.3-0.1,0.6-0.3,0.9-0.1c0.5,0.3,0.3,0.9,0.2,1.5c0.5-0.1,0.7-0.1,0.9,0.1c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0,0.2-0.2,0.6
        c0,0.1-0.1,0.2-0.2,0.4c0.2,0.1,0.4,0.3,0.8,0.6c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.5,0.3-0.6,0.4
        c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.3,0.7,0.6,0.5,1c-0.1,0.3-0.5,0.4-1.1,0.5c0.4,0.6,0.7,1.1,0.4,1.5c-0.3,0.3-0.6,0.2-1,0.2
        c0.2,0.4,0.3,0.7,0.1,1c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.5-0.1-0.9-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.5-0.2,1-0.7,1
        c-0.1,0-0.3,0-1.1-0.5C12,14.9,11.8,14.9,11.7,14.9z M10.6,13.8C10.6,13.8,10.6,13.8,10.6,13.8C10.6,13.8,10.6,13.8,10.6,13.8z
        M10.9,13c0.2,0,0.3,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.3,0.4c0,0.1,0.1,0.1,0.1,0.2c0.2-0.1,0.4-0.3,0.5-0.5c0.2-0.2,0.4-0.2,0.6-0.1
        c0.2,0.1,0.4,0.3,0.6,0.4c0,0,0-0.1,0-0.1c0.1-0.5,0.2-0.9,0.6-0.9c0.1,0,0.2,0,0.6,0.1c0,0,0,0,0,0c-0.1-0.4-0.2-0.7-0.1-0.9
        c0.2-0.3,0.6-0.3,1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.3-0.3-0.5-0.3-0.7c0-0.4,0.4-0.5,1-0.7c-0.3-0.3-0.6-0.6-0.6-0.9
        c0-0.1,0-0.4,0.6-0.8c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.3-0.1-0.3-0.2c-0.2-0.2-0.1-0.5,0.1-1c-0.5,0-0.7,0-0.9-0.1
        c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0-0.2,0.1-0.5c0-0.1,0.1-0.2,0.1-0.3c-0.3,0.1-0.6,0.2-0.9,0c-0.2-0.1-0.3-0.4-0.3-0.6
        c-0.1,0.1-0.2,0.1-0.2,0.2c-0.1,0.1-0.3,0.2-0.4,0.1c-0.2,0-0.3-0.1-0.3-0.3c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1,0.1-0.2,0.4-0.3,0.7
        c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0.2-0.5,0.2-0.7,0c0,0,0,0,0,0c0,0.4,0,0.6-0.3,0.8C10.3,6.3,9.9,6.2,9.5,6c0,0,0,0,0,0
        c0.2,0.6,0.4,1,0.2,1.3C9.6,7.4,9.4,7.6,8.4,7.6c0.4,0.7,0.4,0.8,0.4,0.9c0,0.4-0.4,0.5-0.7,0.6C8,9.1,7.8,9.2,7.7,9.2
        C7.8,9.3,8,9.4,8.1,9.4c0.3,0.2,0.5,0.3,0.5,0.6c0,0.2-0.1,0.4-0.4,0.6c0,0-0.1,0.1-0.1,0.1c0.1,0,0.2,0,0.2,0
        c0.4,0.1,0.8,0.2,0.8,0.6c0,0.1,0,0.2-0.1,0.4C9,12,9,12.2,8.9,12.3c0,0,0,0,0,0c0.4-0.2,0.8-0.3,1,0c0.1,0.2,0.1,0.4,0.2,0.8
        c0,0,0,0,0,0.1C10.4,13.1,10.6,13,10.9,13z M7.9,8.6L7.9,8.6L7.9,8.6z"
        fill="currentColor"
      />
    </g>
    <g>
      <path
        d="M13.5,20.8c-0.3,0-0.5-0.2-1.2-1.2c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1,0.2-0.3,0.3-0.4,0.5c-0.8,1-1,1.2-1.4,1.1
		c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0-2.1-0.1-5.7l0-0.3c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0,0c0.3,0,0.5,0.2,0.5,0.5l0,0.3
		c0,2.1,0.1,3.6,0.1,4.5c0,0,0,0,0.1-0.1c0.7-0.9,0.9-1.1,1.2-1.1c0.3,0,0.5,0.2,1.1,1.1c0-0.2,0-0.4,0-0.6c-0.1-1.6-0.1-2.5,0-2.9
		c0.1-0.3,0.3-0.4,0.6-0.3c0.3,0.1,0.4,0.3,0.3,0.6c-0.1,0.3,0,1.7,0,2.6c0.1,2,0.1,2.2-0.1,2.4C13.7,20.8,13.6,20.8,13.5,20.8z"
        fill="currentColor"
      />
      <path
        d="M12.2,12.4c-0.6,0-1.2-0.2-1.8-0.7C9.4,10.9,9,9.4,9.6,8.2C9.7,8,9.9,7.9,10.1,7.9c0,0,0,0,0,0
		C9.9,7.8,9.8,7.5,10,7.2c0.6-0.9,1.5-1.3,2.4-1.2c1.2,0.2,2.3,1.4,2.6,2.8c0.3,1.2-0.1,2.3-1.1,3C13.4,12.2,12.8,12.4,12.2,12.4z
		 M10.3,8c0.2,0.1,0.3,0.4,0.2,0.6c-0.4,1,0,1.9,0.6,2.4c0.7,0.6,1.6,0.6,2.3,0c0.9-0.6,0.8-1.5,0.7-2c-0.2-1-1-1.9-1.8-2
		c-0.6-0.1-1.1,0.1-1.5,0.8C10.6,7.9,10.4,8,10.3,8z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default CertificateIcon;
