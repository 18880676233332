import { Editor as TinyMCEditor } from '@tinymce/tinymce-react';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: string;
  onChange(v: string): void;
  height?: number;
}

export default function Editor({ value = '', onChange, height = 300 }: Props) {
  const { t } = useTranslation();

  return (
    <TinyMCEditor
      apiKey={process.env.REACT_APP_TINYMCE_KEY}
      onEditorChange={onChange}
      value={value}
      init={{
        icons: 'material',
        inline: true,
        height,
        extended_valid_elements: 'icon[*],img[*]',
        valid_children: '+a[icon]',
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'help',
          'wordcount',
        ],
        toolbar: `undo redo | blocks | bold italic forecolor | link image customInsert |
        alignleft aligncenter  alignright alignjustify |
        bullist numlist outdent indent | removeformat | help`,
        setup: editor => {
          /* example, adding a toolbar menu button */
          editor.ui.registry.addMenuButton('customInsert', {
            text: 'Beszúrás',
            fetch: callback => {
              const items = [
                {
                  type: 'menuitem' as 'menuitem',
                  text: t('editor.competitionsList'),
                  onAction: () => editor.insertContent(`\n{{CompetitionsList}}\n`),
                },
                {
                  type: 'menuitem' as 'menuitem',
                  text: t('editor.currentEditionDate'),
                  onAction: () => editor.insertContent('{{CurrentEditionDate}}'),
                },
                {
                  type: 'menuitem' as 'menuitem',
                  text: t('editor.currentEditionSignupDeadline'),
                  onAction: () => editor.insertContent('{{CurrentEditionSignupDeadline}}'),
                },
              ];
              callback(items);
            },
          });
        },
        content_style: 'body { font-family: Roboto, sans-serif; font-size: 1rem; }',
      }}
    />
  );
}
