import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckStopWatchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path
        d="M13.8,7.2c-1.7-0.8-3.4-0.6-4.9,0.5C8.7,7.9,8.6,8.2,8.8,8.4c0.2,0.2,0.5,0.3,0.7,0.1c1.2-0.9,2.6-1,3.9-0.4
		c1.7,0.8,3,2.7,3.1,4.6c0.1,1.1-0.3,2.6-2.1,3.8c-2,1.2-3.7,1.2-5,0c-1.4-1.4-2-4.5-0.5-7.1C9,9,8.9,8.7,8.7,8.5
		C8.5,8.4,8.2,8.5,8,8.7c-1.6,2.8-1.3,6.4,0.6,8.3c0.8,0.8,1.8,1.2,2.9,1.2c1,0,2.1-0.3,3.2-1c1.8-1.1,2.7-2.7,2.6-4.7
		C17.3,10.3,15.8,8.1,13.8,7.2z"
        fill="currentColor"
      />
      <path
        d="M10.8,6.5c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.3,0,0.5-0.3,0.4-0.5c0-0.3-0.3-0.5-0.5-0.4
		c-0.5,0-1,0-1.5,0c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5,0.2-0.5,0.5S10.6,6.5,10.8,6.5z"
        fill="currentColor"
      />
      <path
        d="M12.4,9.1c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5c0.1,1.7,0.1,2.8,0,3.4c0,0.6,0,0.9,0.2,1.1
		c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0c0.2-0.1,0.4-0.4,0.3-0.6c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.4
		C12.5,12,12.5,10.9,12.4,9.1z"
        fill="currentColor"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M8,18.9c0,0-0.1,0-0.1,0c-0.8-0.1-1.2-1.1-2.2-3.6L5.3,14c-0.1-0.2,0-0.5,0.3-0.6s0.5,0,0.6,0.3l0.5,1.2
		c0.6,1.5,1.1,2.9,1.4,3c0.3,0,1.3-1.4,2.3-2.9c1.5-2.2,3.8-5.4,7.6-9.8c0.2-0.2,0.5-0.2,0.7-0.1c0.2,0.2,0.2,0.5,0,0.7
		c-3.7,4.3-6,7.6-7.6,9.8C9.5,17.9,8.8,18.9,8,18.9z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default CheckStopWatchIcon;
