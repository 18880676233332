import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BearIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <g>
      <path
        d="M57.5,27.6c-0.1-0.2-0.3-0.3-0.4-0.5c0.4-0.4,0.6-1,0.3-1.5c-0.1-0.3-0.4-0.5-0.6-0.6c0-0.2,0-0.5-0.1-0.7
	c-0.3-0.6-1.1-0.9-1.7-0.6c-2.4,1.1-6.6,0.4-9.7-0.1c-0.4-0.1-0.8-0.1-1.1-0.2c-0.5-3.8-1.1-7.6-1.7-11.3l-0.3-1.5
	c0-0.2-0.1-0.4-0.3-0.6c2.2-1.1,3-3,2.6-4.4c-0.4-1.5-2.1-2.6-4.9-1.9c-0.4,0.1-0.6,0.4-0.8,0.7c-0.1-0.1-0.2-0.2-0.4-0.3
	C37.6,3.6,36.2,3,33.7,3c-1.8,0-2.6,0.2-3.9,0.5c-0.4,0.1-0.8,0.2-1.3,0.3C28.3,3.9,28.2,4,28,4c-1-1.1-2.4-1.4-3.8-1
	c-1.6,0.5-2.9,2-2.9,3.6c0,0.7,0.1,2.1,2,3.2c-0.8,3.6-1.4,6.9-1.9,10.4c-0.2,1.1-0.4,2.3-0.6,3.5c-3.4,1.9-7.5,3-12,3
	c-0.7,0-1.3,0.6-1.3,1.3c0,0.4,0.2,0.7,0.4,0.9c-0.4,0.1-0.7,0.3-0.9,0.5c-0.6,0.4-0.8,1.2-0.4,1.8c0.2,0.3,0.4,0.5,0.7,0.5
	c-0.6,0.4-0.8,1.1-0.4,1.7c0.2,0.4,0.7,0.7,1.1,0.7c0.2,0,0.4-0.1,0.6-0.2l0.2-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0,0,0,0,0,0.1
	c0,0.7,0.6,1.3,1.3,1.3c0.8,0,1.6,0,2.4,0.1c0.9,0,1.8,0.1,2.6,0.1c0.7,0,1.4,0,2.2-0.1c-0.9,3.2-1.6,6.3-2.1,10
	c-0.3,2.6-0.3,5.4,0,8.3c0.9,8.1,8.1,8,12.9,7.8c1.1,0,2.1,0,3,0l0.5,0c1.1,0.1,2.5,0.2,4,0.2c4.3,0,9.2-0.8,10.6-5.3
	c1.6-5.2,2-10.6,1.1-15.5c-0.4-2-1-4-1.6-5.9c-0.2-0.5-0.3-1-0.4-1.5c3.6-0.2,6.8-1,9.7-1.8c0.7-0.2,1.1-0.9,0.9-1.6
	c0-0.1-0.1-0.1-0.1-0.1c0.2-0.1,0.4-0.1,0.6-0.2C57.5,29,57.8,28.2,57.5,27.6z M32.3,13.8c0.1-0.1,0.3-0.1,0.4-0.2
	c0.2,0.1,0.3,0.1,0.4,0.2c0,0,0.1,0,0.1,0c-0.4,0.1-0.8,0.1-1.2,0.1C32.1,13.8,32.2,13.8,32.3,13.8z M34,11.3c0-0.2,0-0.3,0-0.5
	c0,0,0,0,0,0c0.1-0.7-0.4-1.3-1.1-1.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.4,0c-0.7,0.2-1.1,0.9-0.9,1.5c0,0,0,0,0,0
	c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.1-0.1,0.2c-0.7,0.3-1,1-0.7,1.7c0.1,0.2,0.2,0.4,0.4,0.5c-0.1,0-0.2-0.1-0.2-0.1
	c-0.5-0.3-0.6-0.7-0.6-1c0-0.2,0-0.4-0.1-0.6c0.1-0.2,0.1-0.3,0.1-0.5c0-0.4,0-0.7,0.1-1c0.5-0.2,0.8-0.6,0.8-1.2c0-0.2,0-0.4,0-0.7
	l0-0.3c0-0.1,0-0.1,0-0.2c0.2-0.3,0.5-0.4,0.8-0.4c0.5,0,1.2,0.3,1.8,0.8v0.5c0,0.7,0.5,1.2,1.2,1.3c0.2,0.4,0.3,0.8,0.3,1.3
	c0.1,0.7-0.2,1.3-0.7,1.7c0.1-0.1,0.1-0.3,0.2-0.4C34.8,12.2,34.5,11.6,34,11.3z M22.1,55c0.2-0.1,0.3-0.3,0.4-0.5
	c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.3,0,0.5-0.1c0.1,0.2,0.2,0.5,0.4,0.6c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.3,0.2,0.5,0.4,0.7
	c0.1,0.2,0.3,1,0.1,1.9c0,0.2,0,0.3,0,0.5c-2-0.1-3.5-0.6-4.6-1.5c0.2-0.5,0.5-1.2,0.6-1.4c0.1-0.1,0.1-0.3,0.1-0.4
	c0.1,0,0.3,0.1,0.4,0.1C21.6,55.2,21.8,55.1,22.1,55z M37.6,57c0.1-0.7,0.2-1.4,0.4-1.8c0.2-0.4,0.2-0.8,0-1.1
	c0.1,0,0.2,0.1,0.4,0.1c0.2,0,0.4,0,0.6-0.1c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.3,0.1,0.5,0.1c0.1,0,0.1,0,0.2,0
	c0.1,0.4,0.3,0.8,0.7,1c0.2,0.1,0.3,0.1,0.5,0.1c0,0,0,0,0.1,0c0,0.1,0,0.1,0,0.2c0.2,0.5,0.4,1.3,0.5,1.9c0,0.2,0.1,0.3,0.1,0.4
	c-1.3,0.7-3,0.9-4.8,1C37.4,58.1,37.6,57.6,37.6,57z M44.3,55.2c0,0.1-0.1,0.1-0.1,0.2c-0.1-0.4-0.2-0.8-0.4-1.1
	c-0.2-0.5-0.6-0.7-1.1-0.8c0-0.2,0-0.5,0-0.6l0-0.2c0-0.7-0.6-1.3-1.3-1.3c-0.2,0-0.3,0-0.4,0.1c-0.2-0.5-0.8-0.8-1.4-0.7
	c-0.3,0.1-0.6,0.2-0.8,0.5c-0.2-0.1-0.5-0.2-0.7-0.1c-0.7,0.1-1.2,0.8-1.1,1.5c0,0,0,0.1,0,0.2c0,0.2,0,0.4,0.1,0.6
	c-0.6-0.2-1.2,0.1-1.5,0.6c-0.4,0.7-0.5,1.7-0.7,2.6c-0.1,0.5-0.2,1.1-0.3,1.6c0,0.2-0.1,0.3,0,0.5c-0.9,0-1.7-0.1-2.4-0.1l-0.5,0
	c-1-0.1-2,0-3.2,0c-0.5,0-0.9,0-1.4,0c0-0.1,0.1-0.1,0.1-0.2c0.1-0.7,0.3-3.1-1-4.2c-0.1,0-0.1-0.1-0.2-0.1c0,0,0-0.1,0-0.1l0.1-0.2
	c0.2-0.7-0.1-1.4-0.8-1.6c-0.4-0.1-0.9,0-1.2,0.3c-0.2-0.5-0.6-0.9-1.2-0.9c-0.5,0-0.9,0.3-1.1,0.7c-0.3-0.2-0.6-0.4-1-0.3
	c-0.7,0.1-1.2,0.7-1.1,1.4l0,0.1c0,0.1,0,0.2,0,0.3c-0.5-0.1-0.9,0.1-1.2,0.5c-0.1-0.4-0.2-0.8-0.2-1.2c-0.3-2.7-0.3-5.3,0-7.7
	c0.5-4.2,1.3-7.6,2.5-11.3c0.2-0.7-0.2-1.4-0.8-1.6c-0.3-0.1-0.5-0.1-0.8,0c-0.2-0.1-0.4-0.1-0.7-0.1c-1.7,0.4-3.6,0.3-5.4,0.2
	c-0.8,0-1.7-0.1-2.5-0.1c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.3,0-0.4c-0.1-0.5-0.5-0.8-0.9-0.9c0.3-0.3,0.5-0.7,0.5-1.2
	c0-0.2-0.1-0.4-0.2-0.5c4.8-0.2,9.2-1.4,12.8-3.6c0,0,0,0,0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1
	c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0.2-1.4,0.4-2.8,0.7-4.1c0.6-3.6,1.1-7.1,2-10.8c0-0.2,0-0.4,0-0.6
	c0.1-0.6-0.2-1.1-0.8-1.4c-0.4-0.2-1.3-0.6-1.3-1.2c0-0.5,0.5-1,1.1-1.2c0.8-0.3,1.2,0.3,1.3,0.5c0.4,0.6,1.2,0.7,1.8,0.3
	c0,0,0,0,0,0c0.3,0.2,0.6,0.2,1,0.1c0,0,0,0,0,0c-0.8,0.9-1.5,2.4-1.5,5c0,0.2,0,0.4,0.1,0.5c-0.1,0.1-0.1,0.3-0.1,0.4
	c-0.1,1.4,0.5,2.6,1.8,3.4c0.8,0.5,1.9,0.8,2.9,0.8c1.2,0,2.3-0.3,3.3-0.9c1.5-0.9,2.2-2.5,2.1-4.3c-0.2-2.5-1.8-4.7-3.8-5.7
	c1.7,0,2.7,0.4,3.2,0.8c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.9-0.2,1.1-0.6c0,0,0-0.1,0-0.1c0.3,0.4,0.8,0.6,1.3,0.5
	C41.4,5.9,42,6.1,42,6.3c0.1,0.3-0.3,1.1-1.9,1.7c-0.7,0.2-1,0.9-0.8,1.6c0.1,0.3,0.2,0.5,0.4,0.6c-0.1,0.2-0.2,0.5-0.1,0.8l0.3,1.5
	c0.7,4,1.4,8.2,1.8,12.3c0.1,0.7,0.6,1.1,1.3,1.1c0,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0c0.4,0.1,0.8,0.1,1.3,0.2
	c2,0.3,4.4,0.7,6.6,0.7c0.8,0,1.7-0.1,2.5-0.2c0,0.2,0,0.4,0.1,0.5c0.1,0.2,0.2,0.3,0.3,0.4c-0.3,0.2-0.5,0.6-0.5,0.9
	c0,0.2,0.1,0.4,0.1,0.6c-3.3,0.9-6.4,1.5-9.9,1.5c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1
	c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2-0.1,0.3
	c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0.3,1.1,0.6,2.1,0.9,3.2c0.6,1.9,1.1,3.7,1.5,5.6
	C46.1,45.4,45.8,50.4,44.3,55.2z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default BearIcon;
