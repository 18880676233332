import { useState } from 'react';

import AvTimerIcon from '@mui/icons-material/AvTimer';
import DownloadIcon from '@mui/icons-material/Download';
import PlaceIcon from '@mui/icons-material/Place';
import RouteIcon from '@mui/icons-material/Route';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StraightenIcon from '@mui/icons-material/Straighten';
import { Box, ListItemButton, Paper } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  Plugin,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import * as L from 'leaflet';
import find from 'lodash-es/find';
import { Line as LineChart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Marker, MarkerLayer } from 'react-leaflet-marker';

import LeafletMap from 'src/common/components/LeafletMap';
import Icon from 'src/common/components/icons/Icon';
import { LanguageKeys } from 'src/common/constants/languages';
import { TechnicalType } from 'src/interfaces/TechnicalType';
import { ITrackMap } from 'src/interfaces/TrackMap';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

interface Props {
  // icon: ReactNode;
  trackMap: ITrackMap;
}

export default function TrackMap({
  trackMap: {
    bounds,
    dataLabels,
    distance: distanceId,
    elevations,
    fileName,
    levelDiff,
    route,
    time,
    totalDistance,
    trackPositions,
  },
}: // icon: Icon = <PlaceIcon />,
Props) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  const { activeEditionId, editionsById, competitionsById, distances } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );
  const currentEdition =
    editionsById && activeEditionId ? editionsById[activeEditionId] : undefined;

  const [highlightedPoint, setHighlightedPoint] = useState<L.LatLng>();

  const options: ChartOptions<'line'> = {
    color: '#000',
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        position: 'average',
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (context) {
            return context.map(c => {
              setHighlightedPoint(trackPositions[c.dataIndex]);
              return t('gpx.distance', { label: c.label });
            });
          },
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += t('gpx.elevationValue', { value: context.parsed.y });
            }
            return label;
          },
        },
      },
      corsair: {
        color: 'black',
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#000',
          callback: function (val, index) {
            return index % 2 === 0 ? t('gpx.tickLabel', { value: dataLabels[index] }) : '';
          },
        },
      },
      y: {
        ticks: {
          color: '#000',
        },
      },
    },
  };

  const plugins: Plugin<'line'>[] = [
    {
      id: 'corsair',
      defaults: {
        width: 1,
        color: '#FF4949',
        dash: [3, 3],
      },
      afterInit: chart => {
        chart.corsair = {
          x: 0,
          y: 0,
        };
      },
      afterEvent: (chart, args) => {
        const { inChartArea } = args;
        const { x, y } = args.event;

        chart.corsair = { x, y, draw: inChartArea };
        chart.draw();
      },
      beforeDatasetsDraw: (chart, _A, opts) => {
        const { ctx } = chart;
        const {
          chartArea: { top, bottom },
          corsair,
        } = chart;

        if (!corsair) return;

        const { x, draw } = corsair;

        if (!draw || !x) return;

        ctx.save();

        ctx.beginPath();
        ctx.lineWidth = opts.width;
        ctx.strokeStyle = opts.color;
        ctx.setLineDash(opts.dash);
        ctx.moveTo(x, bottom);
        ctx.lineTo(x, top);
        ctx.stroke();

        ctx.restore();
      },
    },
  ];

  const data: ChartData<'line', number[], number> = {
    labels: dataLabels,
    datasets: [
      {
        fill: true,
        label: t('gpx.elevationLabel'),
        data: elevations,
        borderColor: 'rgb(53, 162, 235)',
        borderWidth: 0.5,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        pointBorderWidth: 0.5,
        pointRadius: 0.5,
        pointHoverBorderColor: 'rgb(53, 255, 255)',
      },
    ],
  };

  const distance = find(distances, d => d.id === distanceId) as TechnicalType;
  const distanceName = distance.i18n[currentLang];

  const mapIcon =
    currentEdition && competitionsById
      ? competitionsById[
          currentEdition.competitions.find(c => c.distances.includes(distanceId))
            ?.competitionId as string
        ].icon
      : null;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4">{distanceName}:</Typography>
        <div>
          {currentEdition?.competitions.map(c =>
            c.distances.includes(distanceId) && competitionsById ? (
              <Icon key={c.competitionId} icon={competitionsById[c.competitionId].icon} fontSize="large" />
            ) : null,
          )}
        </div>
      </Box>
      <LeafletMap
        height="300px"
        bounds={[bounds.northEast, bounds.southWest]}
        polyLine={trackPositions}
      >
        {!!highlightedPoint && (
          <MarkerLayer>
            <Marker
              position={highlightedPoint}
              size={[35, 35]}
              placement={mapIcon ? 'bottom' : 'top'}
            >
              {mapIcon ? <Icon icon={mapIcon} /> : <PlaceIcon />}
            </Marker>
          </MarkerLayer>
        )}
      </LeafletMap>
      <Paper elevation={0} square sx={{ p: 1 }}>
        <LineChart height={100} options={options} plugins={plugins} data={data} />
      </Paper>
      <List sx={{ width: '100%', bgcolor: 'background.paper', pt: 1, pb: 0, flexGrow: 1 }}>
        <ListItem>
          <ListItemIcon>
            <RouteIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('system.trackMaps.routeLabel')}
            secondary={route.i18n[currentLang]}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <StraightenIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('system.trackMaps.routeLengthLabel')}
            secondary={t('system.trackMaps.totalDistance', {
              dist: totalDistance,
            })}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ShowChartIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('system.trackMaps.levelLabel')}
            secondary={t('system.trackMaps.level', { pos: levelDiff.pos, neg: levelDiff.neg })}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <AvTimerIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('system.trackMaps.timeLabel')}
            secondary={t('timers.times.hour', { count: time })}
          />
        </ListItem>
        <ListItemButton href={`/uploads/gpxmaps/${fileName}`} download>
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          <ListItemText primary={t('system.trackMaps.downloadGPX')} secondary={fileName || '-'} />
        </ListItemButton>
      </List>
    </>
  );
}
