import { Outlet, Navigate } from 'react-router-dom'

import { useAuth } from "../auth/useAuth";

export default function PrivateRoute() {
  const auth = useAuth();
  return (
    <>
      {auth.user ? <Outlet /> : <Navigate to="/signin" />}
    </>
  );
}
