import { AxiosResponse } from 'axios';
import { create } from 'zustand';

import { SECURE_API } from 'src/api/api';
import { IBaseTrackMap, ITrackData, ITrackMap } from 'src/interfaces/TrackMap';

export interface TrackMapsState {
  deleteAllTrackMaps: () => Promise<void | AxiosResponse<null>>;
  deleteTrackMap: (id: string) => Promise<void | AxiosResponse<null>>;
  fetchTrackMaps: () => Promise<void | AxiosResponse<null>>;
  isLoading: boolean;
  resetTrackMaps: () => void;
  saveTrackMap: (
    trackMap: ITrackData & IBaseTrackMap,
    id?: string,
  ) => Promise<void | AxiosResponse<ITrackMap[]>>;
  trackMaps: ITrackMap[];
}

const useTrackMapsStore = create<TrackMapsState>(set => {
  const getTrackMaps = async () => {
    return SECURE_API.get<ITrackMap[]>('trackMaps/')
      .then(({ data }) => {
        set({ trackMaps: data, isLoading: false });
      })
      .catch((error: string) => {
        set({ trackMaps: [], isLoading: false });
        console.log({ error });
      });
  };

  return {
    accommodations: [],
    trackMaps: [],
    distances: [],
    isLoading: false,
    fetchTrackMaps: async () => {
      set({ isLoading: true });
      return getTrackMaps();
    },
    saveTrackMap: async (trackMap: ITrackData & IBaseTrackMap, id?: string) => {
      set({ isLoading: true });
      const saveTrackMapPromise = id
        ? SECURE_API.put<ITrackMap[]>(
            `trackMaps/${id}`,
            trackMap,
          )
        : SECURE_API.post<ITrackMap[]>(
            `trackMaps`,
            trackMap,
          );
      return saveTrackMapPromise
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getTrackMaps();
        });
    },
    deleteTrackMap: async id => {
      set({ isLoading: true });
      return SECURE_API.delete<null>(`trackMaps/${id}`)
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getTrackMaps();
        });
    },
    deleteAllTrackMaps: async () => {
      set({ isLoading: true });
      return SECURE_API.delete<null>(`trackMaps/bulk`)
        .catch((error: string) => {
          console.log({ error });
        })
        .finally(() => {
          getTrackMaps();
        });
    },
    resetTrackMaps: () => set({ trackMaps: [] }),
  };
});

export default useTrackMapsStore;
