import { yupResolver } from '@hookform/resolvers/yup';
import MapIcon from '@mui/icons-material/Map';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { XMLParser } from 'fast-xml-parser';
import getDistance from 'geolib/es/getDistance';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ObjectSchema, number, object, string } from 'yup';

import { disableAutofillBg } from 'src/common/components/constants/disableAutofillBg';
import Flag from 'src/common/components/flags/Flag';
import UploadField from 'src/common/components/form/UploadField';
import {
  ControlledSelectField,
  SelectOption,
} from 'src/common/components/form/controlled/ControlledSelectField';
import { LanguageKeys } from 'src/common/constants/languages';
import {
  IBaseTrackMap,
  ITrackData,
  ITrackMap,
  LatLngArr,
  LatLngBounds,
} from 'src/interfaces/TrackMap';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

interface TrackPoint {
  ele: number;
  '@_lat': string;
  '@_lon': string;
}

const parseGPXFile = (gpxFile: string) => {
  const parser = new XMLParser({
    ignoreAttributes: false,
  });
  const {
    gpx: {
      metadata: { bounds: b },
      trk: {
        trkseg: { trkpt },
      },
    },
  } = parser.parse(gpxFile);
  const latLngBounds: LatLngBounds = {
    southWest: [Number(b['@_minlat']), Number(b['@_minlon'])],
    northEast: [Number(b['@_maxlat']), Number(b['@_maxlon'])],
  };

  const trkPos: LatLngArr[] = [];
  const lbls: number[] = [];
  const elev: number[] = [];
  let dist: number = 0;
  let posLevel: number = 0;
  let negLevel: number = 0;

  trkpt.forEach((p: TrackPoint, i: number) => {
    if (i > 0) {
      const d = getDistance(
        {
          latitude: parseFloat(trkpt[i - 1]['@_lat']),
          longitude: parseFloat(trkpt[i - 1]['@_lon']),
        },
        { latitude: parseFloat(p['@_lat']), longitude: parseFloat(p['@_lon']) },
      );
      dist += d;
      const levelDiff = p.ele - trkpt[i - 1].ele;
      if (levelDiff >= 0) {
        posLevel += levelDiff;
      } else {
        negLevel += levelDiff;
      }
    }
    trkPos.push([parseFloat(p['@_lat']), parseFloat(p['@_lon'])]);
    elev.push(parseFloat(p.ele.toFixed(2)));
    lbls.push(parseFloat((dist / 1000).toFixed(2)));
  });

  return {
    bounds: latLngBounds,
    trackPositions: trkPos,
    dataLabels: lbls,
    elevations: elev,
    levelDiff: {
      pos: parseFloat(posLevel.toFixed(2)),
      neg: parseFloat(negLevel.toFixed(2)),
    },
    totalDistance: parseFloat((dist / 1000).toFixed(2)),
  };
};

export type FormTrackMap = IBaseTrackMap & {};

interface TrackMapEditorFormProps {
  handleClose?: () => void;
  handleSave: (editedTrackMap: ITrackData & IBaseTrackMap, id?: string) => void;
  selectedTrackMap?: ITrackMap;
}

const TrackMapEditorForm = (props: TrackMapEditorFormProps) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  const { handleSave, handleClose, selectedTrackMap } = props;

  const { distances } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );
  const { enqueueSnackbar } = useSnackbar();

  const [trackData, setTrackData] = useState<ITrackData>({
    bounds: selectedTrackMap?.bounds || {
      northEast: [0, 0],
      southWest: [0, 0],
    },
    trackPositions: selectedTrackMap?.trackPositions || [],
    dataLabels: selectedTrackMap?.dataLabels || [],
    elevations: selectedTrackMap?.elevations || [],
    levelDiff: selectedTrackMap?.levelDiff || {
      pos: 0,
      neg: 0,
    },
    totalDistance: selectedTrackMap?.totalDistance || 0,
  });

  const trackMapSchema: ObjectSchema<FormTrackMap> = object()
    .shape({
      distance: string().required(t('form.yup.genericRequired')),
      fileName: string().required(t('form.yup.genericRequired')),
      name: string().required(t('form.yup.genericRequired')),
      route: object()
        .shape({
          i18n: object()
            .shape({
              [LanguageKeys.en]: string().required(t('form.yup.genericRequired')),
              [LanguageKeys.ro]: string().required(t('form.yup.genericRequired')),
              [LanguageKeys.hu]: string().required(t('form.yup.genericRequired')),
            })
            .required(t('form.yup.genericRequired')),
        })
        .required(t('form.yup.genericRequired')),
      time: number().required(t('form.yup.genericRequired')),
    })
    .required();

  const defaultValues = selectedTrackMap
    ? {
        distance: selectedTrackMap.distance,
        name: selectedTrackMap.name,
        fileName: selectedTrackMap.fileName,
        route: selectedTrackMap.route,
        time: selectedTrackMap.time,
      }
    : {};

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<FormTrackMap>({
    resolver: yupResolver(trackMapSchema),
    defaultValues,
  });

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setValue('fileName', file.name)
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const parsedFile = reader.result as string;
        if (parsedFile) {
          setTrackData(parseGPXFile(parsedFile));
        }
      };
      reader.onerror = e => {
        console.error(e);
        enqueueSnackbar(t('system.trackMaps.errors.fileUploadError'), {
          variant: 'error',
        });
      };
    }
  };

  const onSubmit: SubmitHandler<FormTrackMap> = async formData => {
    const editedTrackMap: ITrackData & IBaseTrackMap = selectedTrackMap
      ? {
          ...selectedTrackMap,
          ...formData,
        }
      : {
          ...trackData,
          ...formData,
        };
    handleSave(editedTrackMap, selectedTrackMap?.id);
  };

  const distanceOptions: SelectOption[] = [];

  distances.forEach(dist =>
    distanceOptions.push({
      label: dist.i18n[currentLang],
      value: dist.id,
    }),
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <UploadField
            name="gpxFile"
            accept=".gpx"
            onChange={onChangeFile}
            icon={<MapIcon />}
            label={t('system.trackMaps.selectGPXFile')}
          />
        </Grid>
        <Grid item xs={4}>
          {!!trackData && (
            <>
              <Typography variant="body2" color={grey[600]}>
                {t('system.trackMaps.routeLengthLabel')}:
              </Typography>
              <Typography variant="body2">
                {t('system.trackMaps.totalDistance', {
                  dist: trackData.totalDistance,
                })}
              </Typography>

              <Typography variant="body2" color={grey[600]}>
                {t('system.trackMaps.levelLabel')}:
              </Typography>
              <Typography variant="body2">
                {t('system.trackMaps.level', {
                  pos: trackData.levelDiff.pos,
                  neg: trackData.levelDiff.neg,
                })}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={4}>
          {!!trackData && (
            <>
              <Typography variant="body2" color={grey[600]}>
                {t('system.trackMaps.trackPointsNrLabel')}:
              </Typography>
              <Typography variant="body2">{trackData.trackPositions.length}</Typography>
            </>
          )}

          <Typography variant="body2" color={grey[600]}>
            {t('system.trackMaps.fileName')}:
          </Typography>
          <Typography variant="body2">
            {watch('fileName') || '-'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={disableAutofillBg}
            label={t('system.trackMaps.nameLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['name']}
            helperText={errors['name']?.message}
            {...register('name')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={disableAutofillBg}
            label={t('system.trackMaps.routeLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['route']?.['i18n']?.[LanguageKeys.hu]}
            helperText={errors['route']?.['i18n']?.[LanguageKeys.hu]?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Flag code={LanguageKeys.hu} width={15} height={15} />
                </InputAdornment>
              ),
            }}
            {...register('route.i18n.hu')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={disableAutofillBg}
            label={t('system.trackMaps.timeLabel')}
            variant="outlined"
            type="number"
            fullWidth
            margin="dense"
            error={!!errors['time']}
            helperText={errors['time']?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{t('system.trackMaps.timeUOM')}</InputAdornment>
              ),
            }}
            {...register('time')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={disableAutofillBg}
            label={t('system.trackMaps.routeLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['route']?.['i18n']?.[LanguageKeys.ro]}
            helperText={errors['route']?.['i18n']?.[LanguageKeys.ro]?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Flag code={LanguageKeys.ro} width={15} height={15} />
                </InputAdornment>
              ),
            }}
            {...register('route.i18n.ro')}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledSelectField<FormTrackMap>
            control={control}
            fullWidth
            label={t('form.distance')}
            margin="dense"
            options={distanceOptions}
            withEmptyOption
            name="distance"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={disableAutofillBg}
            label={t('system.trackMaps.routeLabel')}
            variant="outlined"
            fullWidth
            margin="dense"
            error={!!errors['route']?.['i18n']?.[LanguageKeys.en]}
            helperText={errors['route']?.['i18n']?.[LanguageKeys.en]?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Flag code={LanguageKeys.en} width={15} height={15} />
                </InputAdornment>
              ),
            }}
            {...register('route.i18n.en')}
          />
        </Grid>

        {!!errors ? (
          <Grid item xs={12} textAlign="right">
            {Object.keys(errors).map(key => (
              <FormHelperText key={key} error>
                {t('form.keysWithErrors', { key: t(`form.${key}`) })}
              </FormHelperText>
            ))}
          </Grid>
        ) : null}

        <Grid item xs={12} textAlign="right">
          {!!handleClose && (
            <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>
              {t('form.cancel')}
            </Button>
          )}
          <Button type="submit" variant="contained" color="primary">
            {t('form.save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default TrackMapEditorForm;
