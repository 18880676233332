import SortIcon from '@mui/icons-material/Sort';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

import { ASC, DESC, Order } from 'src/interfaces/Order';

interface Props {
  defaultOrderByKey?: Order;
  label?: string;
  order: Order;
  orderByKey: string;
  setOrder: (o: Order) => void;
  setOrderByKey: (k: string) => void;
  sortOptions: { label: string; value: string }[];
  width?: number;
}

const Sorter = (props: Props) => {
  const { t } = useTranslation();
  const {
    defaultOrderByKey,
    label = t('sorter.order'),
    order,
    orderByKey,
    setOrder,
    setOrderByKey,
    sortOptions,
    width = "100%",
  } = props;
  return (
    <FormControl
      margin="none"
      size="small"
      fullWidth
    >
      <InputLabel id="sort-by-label">{label}</InputLabel>
      <Select
        labelId="sort-by-label"
        id="sort-by"
        value={orderByKey}
        defaultValue={defaultOrderByKey}
        label={label}
        onChange={e => setOrderByKey(e.target.value as string)}
        startAdornment={
          <InputAdornment position="start">
            <Tooltip title={label}>
              <IconButton
                aria-label={label}
                onClick={() => setOrder(order === ASC ? DESC : ASC)}
                edge="start"
                sx={{
                  ...(order === ASC && {
                    transform: 'scale(1, -1)',
                  }),
                }}
              >
                <SortIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
        sx={{
          borderRadius: 20,
          width,
        }}
      >
        {sortOptions.map(o => (
          <MenuItem key={o.label} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Sorter;
