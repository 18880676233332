import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WinnersPodiumIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M11.8,9.6c-0.3,0-0.5,0.2-0.5,0.5v1.4c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-1.4C12.3,9.8,12.1,9.6,11.8,9.6z"
      fill="currentColor"
    />
    <path
      d="M20.4,12.3c0-0.2,0-0.3,0-0.4c0-0.1-0.1-0.3-0.2-0.4c-0.3-0.3-1.1-0.4-4.7-0.1c0-0.4-0.1-0.8-0.1-1.3c0-0.4,0-0.9-0.1-1.3
		c0-0.3-0.3-0.5-0.5-0.4c-0.1,0-0.2,0-0.2,0.1c0.1-0.1,0.2-0.3,0.2-0.4c0-0.3-0.3-0.4-0.5-0.4c-0.2,0-0.8,0-1.3,0
		c-2.4-0.1-3.5,0-4,0.5C8.7,8.3,8.7,8.7,8.8,9.2c0,0.1,0,0.3,0,0.4C8.4,9.6,7.2,9.7,6.2,9.9c-2.4,0.3-2.4,0.3-2.5,0.4
		c-0.4,0.4-0.4,1.4-0.3,3.4c0,0.9,0.1,1.8,0,2.1c-0.1,0.3,0.1,0.5,0.3,0.6c0,0,0.1,0,0.1,0c0.2,0,0.4-0.1,0.5-0.3
		c0.1-0.5,0.1-1.4,0.1-2.5c0-0.8-0.1-2.1,0-2.6c0.4,0,1.2-0.1,1.9-0.2c1.6-0.2,2.6-0.3,2.9-0.3c0,0,0,0,0,0c0,0,0,0,0,0
		c0.1,0,0.3,0,0.3-0.1C9.8,10,9.8,9.5,9.7,9.1c0-0.1,0-0.3,0-0.4c0.5-0.2,2.3-0.1,3.1-0.1c0.7,0,1.2,0,1.5,0c0.1,0,0.1,0,0.2-0.1
		c-0.1,0.1-0.2,0.2-0.1,0.4c0,0.4,0.1,0.8,0.1,1.2c0,0.5,0.1,1,0.1,1.6c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0.3,0.3,0.5,0.5,0.4
		c0.9-0.1,3.6-0.3,4.3-0.2c0,0.1,0,0.2,0,0.2c0.1,0.6,0.2,1.8,0.2,3.2c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5
		C20.6,14.1,20.5,12.9,20.4,12.3z"
      fill="currentColor"
    />
    <path
      d="M8.2,13.2c-0.4,0-0.6,0-0.8,0c0.3-0.4,0.2-0.8-0.1-1.2c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7
		c0.1,0.1,0.1,0.1,0.1,0.1c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.4,0.4-0.3,0.8c0.2,0.4,0.6,0.5,2.1,0.5c0.3,0,0.5-0.2,0.5-0.5
		S8.5,13.2,8.2,13.2z"
      fill="currentColor"
    />
    <path
      d="M17.5,14.8C17.5,14.8,17.5,14.8,17.5,14.8c0.2-0.2,0.4-0.4,0.3-0.8c-0.1-0.4-0.5-0.5-0.9-0.6c-0.3-0.1-0.5,0.1-0.6,0.3
		c-0.1,0.2,0.1,0.5,0.3,0.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.4,0.1,0.7,0.3,0.8c0,0,0,0-0.1,0c-0.3,0.1-0.4,0.3-0.3,0.6
		c0.1,0.2,0.3,0.4,0.5,0.4c0,0,0.1,0,0.1,0c0.4-0.1,0.9-0.3,1-0.7C18,15.2,17.7,15,17.5,14.8z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default WinnersPodiumIcon;
