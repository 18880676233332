import { MouseEvent, useState } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ConstructionIcon from '@mui/icons-material/Construction';
import InfoIcon from '@mui/icons-material/Info';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/system';
import find from 'lodash-es/find';
import { useTranslation } from 'react-i18next';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import Icon from 'src/common/components/icons/Icon';
import { GENDERS_BY_NAME } from 'src/common/constants/genders';
import { LanguageKeys } from 'src/common/constants/languages';
import { IApplicant } from 'src/interfaces/Applicant';
import { Competition } from 'src/interfaces/Competition';
import { TechnicalType } from 'src/interfaces/TechnicalType';
import useCheckinsStore from 'src/stores/checkinsStore';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

interface Props {
  checkin(id?: string, e?: MouseEvent<unknown>): void;
  applicant: IApplicant;
  deleteApplicant(id: string, e?: MouseEvent<unknown>): void;
  editApplicant(id?: string, e?: MouseEvent<unknown>): void;
}

const ApplicantCard = ({
  checkin,
  applicant: {
    age,
    city,
    checkedIn,
    competition: competitionId,
    distance: distanceId,
    email,
    firstName,
    gender,
    id,
    lastName,
    sportsGroupName,
    phoneNumber,
    price,
  },
  deleteApplicant,
  editApplicant,
}: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;
  const { palette } = useTheme();
  const { competitions, distances } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );
  const { checkinsById } = useCheckinsStore(checkinsState => checkinsState);

  const toolsMenu = usePopupState({ variant: 'popover', popupId: 'toolsMenu' });

  const [infoPopoverAnchorEl, setInfoPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleInfoButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setInfoPopoverAnchorEl(event.currentTarget);
  };

  const handleInfoPopoverClose = () => {
    setInfoPopoverAnchorEl(null);
  };

  const open = Boolean(infoPopoverAnchorEl);
  const moreInfoId = open ? 'more-info' : undefined;

  const competition = find(competitions, c => c.id === competitionId) as Competition;
  const competitionName = competition.i18n[currentLang];

  const distance = find(distances, d => d.id === distanceId) as TechnicalType;
  const distanceName = distance.i18n[currentLang];

  const handleCheckin = () => {
    checkin(id);
  };

  const avatarOffset = 1.5;

  return (
    <Card
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        backdropFilter: 'blur(3px)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderTopLeftRadius: 40,
      }}
    >
      <CardHeader
        avatar={
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <Avatar
                sx={{
                  elevation: 1,
                  background: `linear-gradient(135deg, ${palette.primary.dark} 0%, ${palette.primary.light} 100%)`,
                  mt: avatarOffset,
                  mb: -avatarOffset,
                  p: 1,
                  width: 24,
                  height: 24,
                  border: theme => `1px solid ${theme.palette.background.paper}`,
                  fontSize: 9,
                  textAlign: 'center',
                }}
              >
                {distanceName}
              </Avatar>
            }
          >
            <Avatar
              sx={{
                elevation: 1,
                background: `linear-gradient(135deg, ${palette.success.dark} 0%, ${palette.success.light} 100%)`,
                width: 56,
                height: 56,
                mt: avatarOffset,
                mb: -avatarOffset,
              }}
              aria-label="recipe"
            >
              <Icon icon={competition.icon} />
            </Avatar>
          </Badge>
        }
        action={
          <>
            {checkedIn && checkinsById ? (
              <Tooltip title={t('applicants.registeredApplicant')}>
                <Badge
                  color="secondary"
                  badgeContent={checkinsById[checkedIn].ser}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <TurnedInIcon color="success" />
                </Badge>
              </Tooltip>
            ) : (
              <Tooltip title={t('applicants.registerApplicant')}>
                <IconButton onClick={handleCheckin} color="primary">
                  <TurnedInNotIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
        title={competitionName}
        subheader={distanceName}
        sx={{ pt: 1, pb: 0, borderBottom: `1px solid ${grey[300]}` }}
      />
      <CardContent sx={{ mt: 3, pt: 1, pb: 0, flexGrow: 1 }}>
        <Typography variant="h5" component="div">
          {`${lastName} ${firstName} (${age})`}
        </Typography>
        <Typography
          fontSize={12}
          sx={{
            mb: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          color="text.secondary"
        >
          {phoneNumber}
          <IconButton aria-describedby={moreInfoId} onClick={handleInfoButtonClick}>
            <InfoIcon color="info" />
          </IconButton>
        </Typography>
        <Popover
          id={moreInfoId}
          open={open}
          anchorEl={infoPopoverAnchorEl}
          onClose={handleInfoPopoverClose}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          slotProps={{
            paper: {
              style: {
                width: 350,
                padding: 16,
              },
            },
          }}
        >
          <Grid container spacing={1} columnSpacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" color={grey[300]}>
                {t('applicants.email')}:
              </Typography>
              <Typography variant="body2">{email}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color={grey[300]}>
                {t('applicants.city')}:
              </Typography>
              <Typography variant="body2">{city}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" color={grey[300]}>
                {t('applicants.gender')}:
              </Typography>
              <Typography variant="body2">
                {t(`base.gender.${GENDERS_BY_NAME[gender].name}`)}
              </Typography>
            </Grid>
            {checkedIn && checkinsById ? (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2" color={grey[300]}>
                    {t('checkins.ser')}:
                  </Typography>
                  <Typography variant="body2">{checkinsById[checkedIn].ser}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color={grey[300]}>
                    {t('checkins.price')}:
                  </Typography>
                  <Typography variant="body2">
                    {t('base.currencyValue', { count: price })}
                  </Typography>
                </Grid>
              </>
            ) : null}
            <Grid item xs={12}>
              {!!sportsGroupName && (
                <>
                  <Typography variant="body2" color={grey[300]}>
                    {t('applicants.sportsGroupName')}:
                  </Typography>
                  <Typography variant="body2">{sportsGroupName}</Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Popover>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <IconButton {...bindTrigger(toolsMenu)} edge="end">
          <ConstructionIcon />
        </IconButton>
        <Tooltip title={t('applicants.editApplicant')}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<EditIcon />}
            onClick={e => editApplicant(id, e)}
          >
            {t('applicants.edit')}
          </Button>
        </Tooltip>
        <Menu {...bindMenu(toolsMenu)}>
          <Tooltip title={t('applicants.deleteApplicant')}>
            <MenuItem
              onClick={e => {
                deleteApplicant(id, e);
                toolsMenu.close();
              }}
            >
              <ListItemIcon>
                <DeleteForeverIcon color="error" fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('applicants.delete')}</ListItemText>
            </MenuItem>
          </Tooltip>
        </Menu>
      </CardActions>
    </Card>
  );
};

export default ApplicantCard;
