import { MouseEvent } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { grey } from '@mui/material/colors';
import Icon from 'src/common/components/icons/Icon';
import { LanguageKeys } from 'src/common/constants/languages';
import { TechnicalType } from 'src/interfaces/TechnicalType';

interface Props {
  item: TechnicalType & {
    icon?: string;
  };
  defaultIcon?: string;
  onDelete: (id: string, e?: MouseEvent<unknown>) => void;
  onEdit: (id: string, e?: MouseEvent<unknown>) => void;
}

const TechnicalTypeCard = ({
  item: { i18n, icon, id, name },
  defaultIcon,
  onDelete,
  onEdit,
}: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;
  const { palette } = useTheme();

  const avatarOffset = 1.5;

  return (
    <Card
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        backdropFilter: 'blur(3px)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderTopLeftRadius: 40,
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{
              elevation: 1,
              background: `linear-gradient(135deg, ${palette.success.dark} 0%, ${palette.success.light} 100%)`,
              width: 56,
              height: 56,
              mt: avatarOffset,
              mb: -avatarOffset,
            }}
            aria-label="recipe"
          >
            {icon ? (
              <Icon icon={icon} fontSize="large" />
            ) : defaultIcon ? (
              <Icon icon={defaultIcon} fontSize="large" />
            ) : (
              name.charAt(0)
            )}
          </Avatar>
        }
        action={
          <>
            <Tooltip title={t('technicalTypes.delete')}>
              <IconButton onClick={e => onDelete(id, e)} color="error">
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('technicalTypes.edit')}>
              <IconButton onClick={e => onEdit(id, e)} color="secondary">
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        }
        title={name}
        sx={{ pt: 1, pb: 0, borderBottom: `1px solid ${grey[600]}` }}
      />
      <CardContent sx={{ mt: 3, pt: 1, pb: 0, flexGrow: 1 }}>
        <Typography variant="body2" color={grey[600]}>
          {t('technicalTypes.translatedName')}:
        </Typography>
        <Typography variant="body2" component="div">
          {i18n[currentLang]}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TechnicalTypeCard;
