import { MainMenuItem } from 'src/interfaces/MenuItem';
import { Competition } from 'src/interfaces/Competition';
import { LanguageKeys } from 'src/common/constants/languages';
import { IEditionCompetition } from 'src/interfaces/Edition';

export const getMenuLayout = ({
  competitions,
  competitionsById,
  year,
  lang,
}: {
  competitions?: IEditionCompetition[];
  competitionsById?: { [key: string]: Competition };
  year?: number;
  lang: LanguageKeys;
}) => {
  const menuLayout: MainMenuItem[] = [
    {
      id: 'home',
      pageUrl: '/home',
      icon: 'home',
    },
    {
      id: 'race',
      pageUrl: '/race',
      icon: 'finishFlag',
      subPages: [
        {
          id: 'details',
          pageUrl: '/race/details',
          icon: 'book',
        },
        {
          id: 'trails',
          pageUrl: '/race/trails',
          icon: 'map',
        },
        {
          id: 'signUp',
          pageUrl: '/race/signup',
          icon: 'signUp',
        },
        ...(competitions && competitionsById && year
          ? [
              {
                id: 'results',
                pageUrl: '/race/results',
                icon: 'winnersPodium',
                subPages: competitions.map(c => {
                  const { icon, name, i18n } = competitionsById[c.competitionId] as Competition;
                  return {
                    pageName: i18n[lang],
                    id: name,
                    icon: icon,
                    pageUrl: `/race/results/${year}/${name}`,
                  };
                }),
              },
            ]
          : []),
      ],
    },
    {
      id: 'usefulInfo',
      pageUrl: '/useful-info',
      icon: 'info',
      subPages: [
        {
          id: 'rules',
          pageUrl: '/useful-info/rules',
          icon: 'agreement',
        },
        {
          id: 'directions',
          pageUrl: '/useful-info/directions',
          icon: 'directions',
        },
        {
          id: 'sovata',
          pageUrl: '/useful-info/sovata',
          icon: 'bear',
        },
        {
          id: 'accommodations',
          pageUrl: '/useful-info/accommodation',
          icon: 'tent',
        },
        {
          id: 'countTelekiSamu',
          pageUrl: '/useful-info/count-teleki-samu',
          icon: 'teleki',
        },
      ],
    },
    {
      id: 'sponsors',
      pageUrl: '/sponsors',
      icon: 'puzzle',
    },
    {
      id: 'contact',
      pageUrl: '/contact',
      icon: 'thought',
    },
  ];

  return menuLayout;
};
