import { ComponentType, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import useMountedState from 'react-use/lib/useMountedState';
import Loader, { Props as LoaderProps } from './components/Loader';

interface Props<T, C> {
  resolve?: () => Promise<any>;
  onSuccess?: (result: any) => void;
  onError?: (error: any) => void;
  redirectOnError?: string;
  successComponent: ComponentType<T>;
  loaderComponent?: ComponentType<C>;
  successProps?: T;
  loaderProps?: C;
}

// Use: <Resolver<SuccessComponentProps, LoaderComponentProps> />
const Resolver = <T extends object, C extends object = LoaderProps>({
  loaderComponent: LoaderComponent = Loader,
  loaderProps = { isLoading: true } as C,
  onError,
  onSuccess,
  redirectOnError,
  resolve,
  successComponent: SuccessComponent,
  successProps = {} as T,
}: Props<T, C>) => {
  const navigate = useNavigate();
  const isMounted = useMountedState();

  const [resolved, setResolved] = useState(false);

  useEffectOnce(() => {
    if (resolve) {
      resolve()
        .then(result => {
          if (!isMounted()) return;
          if (onSuccess) onSuccess(result);
          setResolved(true);
        })
        .catch(error => {
          if (!isMounted()) return;
          if (onError) onError(error);
          if (redirectOnError) navigate(redirectOnError);
        });
    } else {
      setResolved(true);
    }
  });

  return resolved ? <SuccessComponent {...successProps} /> : <LoaderComponent {...loaderProps} />;
};

export default Resolver;
