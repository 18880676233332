import { AxiosResponse } from 'axios';

import Resolver from 'src/common/Resolver';
import useApplicantsStore from 'src/stores/applicantsStore';
import Applicants from './Applicants';
import useCheckinsStore from 'src/stores/checkinsStore';

const ApplicantsResolver = () => {
  const {
    fetchApplicants: doFetchApplicants,
  } = useApplicantsStore(applicantsState => applicantsState);
  const {
    fetchCheckins: doFetchCheckins,
  } = useCheckinsStore(checkinsState => checkinsState);

  const loadDependencies = () => {
    const deps: Array<Promise<void | AxiosResponse<any, any>>> = [
      doFetchApplicants(),
      doFetchCheckins(),
    ];

    return Promise.all(deps);
  };

  return <Resolver resolve={loadDependencies} successComponent={Applicants} />;
};

export default ApplicantsResolver;
