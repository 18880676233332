import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import useTitle from 'react-use/lib/useTitle';

import { Fragment } from 'react';
import PageBox from 'src/common/components/PageBox';
import { LanguageKeys } from 'src/common/constants/languages';
import { Competition as CompetitionType } from 'src/interfaces/Competition';
import StatisticsComponent from 'src/pages/Dashboard/Statistics/Parts/StatisticsComponent';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';

export interface Props {
  competition: CompetitionType;
  distances: string[];
}

export default function Results({ competition, distances }: Props) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;

  useTitle(`${t('race.results.pageTitle')} - ${t('base.defaultTitle')}`);

  const { activeEditionId, distancesById, editionsById } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );
  const currentEdition =
    editionsById && activeEditionId ? editionsById[activeEditionId] : undefined;

  if (!currentEdition || !distancesById) return null;

  return (
    <PageBox sx={{ pt: 4, pb: 4 }}>
      <Grid container>
        <Grid
          item
          xs={12}
          component={Paper}
          elevation={6}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
            backdropFilter: 'blur(3px)',
          }}
        >
          <Box
            sx={{
              my: 4,
              mx: 4,
            }}
          >
            {currentEdition.canShowResults && currentEdition.statistics
              ? distances.map(dist => {
                  const distance = distancesById[dist];
                  const statistic =
                    currentEdition.statistics &&
                    currentEdition.statistics[`${competition.id}:${dist}`];
                  return (
                    <Fragment key={dist}>
                      <Typography variant="h2" component="h2">
                        {t('statistics.distanceTitle', {
                          competition: competition.i18n[currentLang],
                          distance: distance.i18n[currentLang],
                        })}
                      </Typography>
                      <Grid container spacing={2}>
                        {!!statistic ? (
                          <StatisticsComponent statistic={statistic} />
                        ) : (
                          <Grid item xs={12}>
                            <Typography variant="body1" component="p" my={2}>
                              {t('statistics.notEnoughData')}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Fragment>
                  );
                })
              : t('editions.cantShowResults')}
          </Box>
        </Grid>
      </Grid>
    </PageBox>
  );
}
