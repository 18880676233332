import { Fragment, useState } from 'react';

import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TranslateIcon from '@mui/icons-material/Translate';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from 'src/auth/useAuth';
import Flag from 'src/common/components/flags/Flag';
import Icon from 'src/common/components/icons/Icon';
import PieChartIcon from 'src/common/components/icons/PieChart';
import Drawer from 'src/common/components/styled/Drawer';
import DrawerListItemButton from 'src/common/components/styled/DrawerListItemButton';
import { LANGUAGES, LanguageKeys } from 'src/common/constants/languages';
import { Competition } from 'src/interfaces/Competition';
import useSystemOptionsStore from 'src/stores/systemOptionsStore';
import UserMenu from './UserMenu';
import FinishFlagIcon from './icons/FinishFlag';

const AdminDrawer = () => {
  const {
    t,
    i18n: { resolvedLanguage, changeLanguage },
  } = useTranslation();
  const currentLang = resolvedLanguage as LanguageKeys;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  const { competitionsById, editions, selectedEdition, setSelectedEdition } = useSystemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const userMenu = usePopupState({ variant: 'popover', popupId: 'user-menu' });

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [editionMenuOpen, setEditionMenuOpen] = useState(false);
  const [competitionsOpen, setCompetitionsOpen] = useState(true);
  const [systemOptionsOpen, setSystemOptionsOpen] = useState(true);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);

  const toggleEditionMenu = () => {
    setEditionMenuOpen(prevValue => !prevValue);
  };

  const toggleCompetitionsMenu = () => {
    setCompetitionsOpen(!competitionsOpen);
  };

  const toggleSystemOptionsMenu = () => {
    setSystemOptionsOpen(prevValue => !prevValue);
  };

  const toggleLanguageMenu = () => {
    setLanguageMenuOpen(prevValue => !prevValue);
  };

  return (
    <Drawer
      variant="permanent"
      $open={drawerOpen}
      PaperProps={{ sx: { backgroundColor: grey[900] } }}
    >
      <Toolbar
        variant="dense"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
          {drawerOpen ? (
            <Icon icon="close" sx={{ color: grey[100] }} />
          ) : (
            <Icon icon="caretRight" sx={{ color: grey[100] }} />
          )}
        </IconButton>
      </Toolbar>
      <Typography
        variant="h5"
        component="h1"
        sx={{
          color: '#fff',
          textAlign: 'center',
          mb: 2,
        }}
        aria-label={t('base.pageTitle')}
      >
        <IconButton sx={{ color: 'inherit' }} onClick={() => navigate('/')}>
          <Icon
            icon="ttt"
            sx={{
              fontSize: 100,
              transition: theme =>
                theme.transitions.create('fontSize', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                  delay: theme.transitions.duration.leavingScreen,
                }),
              ...(!drawerOpen && {
                overflowX: 'hidden',
                transition: theme =>
                  theme.transitions.create('fontSize', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                    delay: theme.transitions.duration.leavingScreen,
                  }),
                fontSize: 40,
              }),
            }}
          />
        </IconButton>
      </Typography>
      <Divider sx={{ my: 1 }} light />
      <List component="nav" sx={{ flexGrow: 1 }}>
        <DrawerListItemButton
          href="/dashboard/editions"
          selected={pathname.includes('/dashboard/editions')}
        >
          <ListItemIcon sx={{ minWidth: 45 }}>
            <Icon icon="book" fontSize="large" />
          </ListItemIcon>
          <ListItemText primary={t('drawer.editions')} />
        </DrawerListItemButton>
        {editions.length ? (
          <>
            <DrawerListItemButton
              onClick={toggleEditionMenu}
              sx={drawerOpen ? { borderLeft: '2px solid #ccc', ml: 3 } : undefined}
            >
              <ListItemIcon sx={{ pl: 1, minWidth: 45 }}>
                <ConfirmationNumberIcon />
              </ListItemIcon>
              <ListItemText primary={selectedEdition?.year || '-'} />
            </DrawerListItemButton>
            <Collapse
              in={editionMenuOpen}
              timeout="auto"
              unmountOnExit
              sx={drawerOpen ? { borderLeft: '2px solid #ccc', ml: 3 } : undefined}
            >
              <List component="div" disablePadding>
                <DrawerListItemButton
                  onClick={async () => {
                    setSelectedEdition();
                    toggleEditionMenu();
                  }}
                  selected={!selectedEdition}
                  disabled={!selectedEdition}
                >
                  <ListItemText primary={'-'} />
                </DrawerListItemButton>
                {editions.map(edition => (
                  <DrawerListItemButton
                    key={edition.id}
                    onClick={async () => {
                      setSelectedEdition(edition);
                      toggleEditionMenu();
                    }}
                    selected={selectedEdition?.id === edition.id}
                    disabled={selectedEdition?.id === edition.id}
                  >
                    <ListItemText primary={edition.year} />
                  </DrawerListItemButton>
                ))}
              </List>
            </Collapse>
            <Divider sx={{ my: 1 }} light />
          </>
        ) : null}
        <DrawerListItemButton
          href="/dashboard/applicants"
          selected={pathname.includes('/dashboard/applicants')}
        >
          <ListItemIcon sx={{ minWidth: 45 }}>
            <Icon icon="agreement" fontSize="large" />
          </ListItemIcon>
          <ListItemText primary={t('drawer.applicants')} />
        </DrawerListItemButton>
        <Divider sx={{ my: 1 }} light />
        {selectedEdition && competitionsById ? (
          <>
            <DrawerListItemButton
              onClick={toggleCompetitionsMenu}
              selected={pathname.includes('/dashboard/competition/')}
            >
              <ListItemIcon sx={{ minWidth: 45 }}>
                <FinishFlagIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary={t('drawer.checkins')} />
              {competitionsOpen ? <ExpandLess /> : <ExpandMore />}
            </DrawerListItemButton>
            <Collapse
              in={competitionsOpen}
              timeout="auto"
              unmountOnExit
              sx={drawerOpen ? { borderLeft: '2px solid #ccc', ml: 3 } : undefined}
            >
              <List component="div" disablePadding>
                {selectedEdition.competitions.map(comp => {
                  const { icon, id, name, i18n } = competitionsById[
                    comp.competitionId
                  ] as Competition;
                  return (
                    <Fragment key={id}>
                      <DrawerListItemButton
                        href={`/dashboard/competition/${selectedEdition.year}/${name}`}
                        selected={pathname.includes(`/competition/${selectedEdition.year}/${name}`)}
                      >
                        <ListItemIcon sx={{ minWidth: 45 }}>
                          <Icon icon={icon} fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary={i18n[currentLang]} />
                      </DrawerListItemButton>
                      <DrawerListItemButton
                        href={`/dashboard/competition/${selectedEdition.year}/${name}/statistics`}
                        selected={pathname.includes(
                          `/dashboard/competition/${selectedEdition.year}/${name}/statistics`,
                        )}
                        sx={drawerOpen ? { ml: 2 } : { ml: 1 }}
                      >
                        <ListItemIcon sx={{ minWidth: 45 }}>
                          <PieChartIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary={t('drawer.statistics')} />
                      </DrawerListItemButton>
                    </Fragment>
                  );
                })}
              </List>
            </Collapse>
            <Divider sx={{ my: 1 }} light />
          </>
        ) : null}

        <DrawerListItemButton
          onClick={toggleSystemOptionsMenu}
          selected={pathname.includes('/dashboard/system/')}
        >
          <ListItemIcon sx={{ minWidth: 45 }}>
            <FinishFlagIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary={t('drawer.systemOptions')} />
          {systemOptionsOpen ? <ExpandLess /> : <ExpandMore />}
        </DrawerListItemButton>
        <Collapse
          in={systemOptionsOpen}
          timeout="auto"
          unmountOnExit
          sx={drawerOpen ? { borderLeft: '2px solid #ccc', ml: 3 } : undefined}
        >
          <List component="div" disablePadding>
            <DrawerListItemButton
              href="/dashboard/system/accommodations"
              selected={pathname.includes('/dashboard/system/accommodations')}
              sx={drawerOpen ? { ml: 2 } : { ml: 1 }}
            >
              <ListItemIcon sx={{ minWidth: 45 }}>
                <Icon icon="tent" fontSize="large" />
              </ListItemIcon>
              <ListItemText primary={t('drawer.accommodations')} />
            </DrawerListItemButton>
            <Divider sx={{ my: 1 }} light />
            <DrawerListItemButton
              href="/dashboard/system/competitions"
              selected={pathname.includes('/dashboard/system/competitions')}
              sx={drawerOpen ? { ml: 2 } : { ml: 1 }}
            >
              <ListItemIcon sx={{ minWidth: 45 }}>
                <Icon icon="trophy" fontSize="large" />
              </ListItemIcon>
              <ListItemText primary={t('drawer.competitions')} />
            </DrawerListItemButton>
            <Divider sx={{ my: 1 }} light />
            <DrawerListItemButton
              href="/dashboard/system/distances"
              selected={pathname.includes('/dashboard/system/distances')}
              sx={drawerOpen ? { ml: 2 } : { ml: 1 }}
            >
              <ListItemIcon sx={{ minWidth: 45 }}>
                <Icon icon="location" fontSize="large" />
              </ListItemIcon>
              <ListItemText primary={t('drawer.distances')} />
            </DrawerListItemButton>
            <Divider sx={{ my: 1 }} light />
            <DrawerListItemButton
              href="/dashboard/system/trackMaps"
              selected={pathname.includes('/dashboard/system/trackMaps')}
              sx={drawerOpen ? { ml: 2 } : { ml: 1 }}
            >
              <ListItemIcon sx={{ minWidth: 45 }}>
                <Icon icon="map" fontSize="large" />
              </ListItemIcon>
              <ListItemText primary={t('drawer.trackMaps')} />
            </DrawerListItemButton>
            <Divider sx={{ my: 1 }} light />
          </List>
        </Collapse>
        <Divider sx={{ my: 1 }} light />

        <DrawerListItemButton onClick={toggleLanguageMenu}>
          <ListItemIcon sx={{ pl: 1, minWidth: 45 }}>
            <TranslateIcon />
          </ListItemIcon>
          <ListItemText primary={t(`base.i18n.${currentLang}`)} />
        </DrawerListItemButton>
        <Collapse
          in={languageMenuOpen}
          timeout="auto"
          unmountOnExit
          sx={drawerOpen ? { borderLeft: '2px solid #ccc', ml: 3 } : undefined}
        >
          <List component="div" disablePadding>
            {LANGUAGES.map(lng => (
              <DrawerListItemButton
                key={lng.code}
                onClick={async () => {
                  await changeLanguage(lng.code);
                  toggleLanguageMenu();
                }}
                selected={currentLang === lng.code}
                disabled={currentLang === lng.code}
              >
                <IconButton
                  aria-label="Switch language"
                  sx={{
                    padding: 0,
                    mr: 1,
                    overflow: 'hidden',
                    minWidth: 45,
                  }}
                >
                  <Flag code={lng.code} />
                </IconButton>
                <ListItemText primary={t(`base.i18n.${lng.code}`)} />
              </DrawerListItemButton>
            ))}
          </List>
        </Collapse>
        <Divider sx={{ my: 1 }} light />
      </List>
      <Divider light />

      {auth.user && (
        <>
          <DrawerListItemButton
            {...bindTrigger(userMenu)}
            selected={pathname.includes('/dashboard/profile')}
            sx={{ flexGrow: 0 }}
          >
            <ListItemIcon sx={{ minWidth: 45 }}>
              <Icon icon="wrench" fontSize="large" />
            </ListItemIcon>
            <ListItemText primary={auth.user.name} />
          </DrawerListItemButton>
          <UserMenu popupState={userMenu} />
        </>
      )}
    </Drawer>
  );
};
export default AdminDrawer;
