import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';
import { ChangeEvent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Input = styled('input')({
  display: 'none',
});

type Props = {
  accept?: string;
  color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined;
  error?: string;
  icon?: ReactNode;
  id?: string;
  label?: string;
  multiple?: boolean;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const UploadField = (props: Props) => {
  const { t } = useTranslation();
  const {
    accept = 'image/*',
    color = 'secondary',
    error,
    icon: Icon,
    id = 'file-upload-button',
    label = t('form.upload'),
    multiple,
    name,
    onChange,
  } = props;

  const [fileName, setFileName] = useState<string>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) setFileName(file.name);

    onChange && onChange(event);
  };

  return (
    <label htmlFor={id}>
      <Input
        name={name}
        multiple={multiple}
        accept={accept}
        id={id}
        onChange={handleChange}
        type="file"
      />
      <Button
        size="large"
        startIcon={Icon ? Icon : <PhotoCameraIcon />}
        color={!!error ? 'error' : color}
        aria-label={label}
        component="span"
      >
        {fileName || label}
      </Button>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </label>
  );
};

export default UploadField;
