import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ShieldIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12.5,21.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.1-0.5,0.2-0.7c1.2-0.7,4-3.1,5.1-5.6c1-2.3,1.3-5.7,1.3-6.6
	c-0.1,0-0.3-0.1-0.5-0.1c-0.7-0.1-1.7-0.2-2.8-0.6c-1.3-0.4-2.4-1.4-3.2-3c-0.8,1-1.7,1.9-2.8,2.7C8.1,8,6.8,8.4,5.4,8.3
	c0,0.1,0,0.1,0,0.2c0,0.4,0.4,4.3,1.4,7.1c0.6,1.8,1.9,3.2,3.9,4.3c0.2,0.1,0.3,0.4,0.2,0.7c-0.1,0.2-0.4,0.3-0.7,0.2
	c-2.1-1.2-3.6-2.8-4.3-4.9c-1-2.9-1.5-6.9-1.5-7.4c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.3-0.4,0.5-0.3c1.3,0.2,2.5-0.1,3.6-1
	c1.2-0.9,2.2-2,3.1-3.1c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.3c0.7,1.7,1.7,2.8,2.9,3.2c1.1,0.4,2,0.5,2.7,0.6
	c0.7,0.1,1.2,0.2,1.3,0.6c0.1,0.5-0.1,4.7-1.3,7.4c-1.3,2.8-4.3,5.3-5.5,6C12.7,21,12.6,21.1,12.5,21.1z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ShieldIcon;
