import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const KeyIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g opacity="0.5">
      <path
        d="M7.9,16.2c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.1-0.5,0.1-0.7c0.4-0.3,1.1-0.9,1.7-1.6c0.6-0.6,1.3-1.2,1.8-1.7
		c0.2-0.2,0.5-0.1,0.7,0.1s0.1,0.5-0.1,0.7c-0.5,0.4-1.2,1-1.8,1.6c-0.7,0.7-1.4,1.3-1.8,1.7C8.1,16.2,8,16.2,7.9,16.2z"
        fill="currentColor"
      />
    </g>
    <g>
      <path
        d="M17.3,7.9c-1.2-1.9-3.4-2.2-4.9-1.4c-1.2,0.6-2.3,2.1-1.4,4.3c0,0,0,0-0.1,0c-1.4,1.6-3,3.2-4.4,4.5
		c-0.2,0.2-0.2,0.5,0,0.7c-0.2,0.5-0.5,1.1-0.1,1.5c0.2,0.3,0.5,0.4,0.9,0.4c0.4,0,1-0.1,1.5-0.3L9,17.6c0,0,0,0,0.1,0
		c0.2,0,0.4-0.2,0.4-0.5c0-0.1,0-0.3,0.1-0.4l0.7,0.1c0.3,0,0.5-0.1,0.5-0.4l0.2-0.8h0.4c0.2,0,0.4-0.2,0.5-0.4l0.1-0.6l0.3,0.1
		c0.1,0,0.3,0,0.4,0c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.7,0.4-1,0.6-1.3c0,0,0,0,0,0c0.4,0.2,0.7,0.2,1.1,0.2c0.8,0,1.6-0.4,2.3-1.1
		C17.7,11.3,18.2,9.4,17.3,7.9z M16,11.7c-0.2,0.3-1.2,1.2-2.2,0.6c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.2-0.2-0.5-0.2-0.7,0
		c-0.3,0.3-0.6,0.7-0.9,1.4l-0.4-0.2c-0.1-0.1-0.3,0-0.4,0c-0.1,0.1-0.2,0.2-0.2,0.3l-0.1,0.7h-0.4c-0.2,0-0.4,0.2-0.5,0.4l-0.1,0.8
		l-0.6-0.1c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.3-0.2,0.5-0.2,0.8l0,0C8.1,16.8,7.4,17,7.2,17c0-0.1,0.1-0.3,0.2-0.5
		c0.1-0.2,0.1-0.4,0-0.5c1.3-1.3,2.9-2.8,4.3-4.4c0.1-0.1,0.1-0.3,0.1-0.4c0.2-0.1,0.2-0.3,0.1-0.6c-0.7-1.6,0.1-2.7,1-3.2
		c1.1-0.6,2.8-0.4,3.7,1.1C17.2,9.5,16.7,10.9,16,11.7z"
        fill="currentColor"
      />
      <path
        d="M15.1,7.8c-0.3,0-0.6,0.1-0.8,0.3c-0.1,0-0.1,0.1-0.1,0.1c-0.7,1-0.3,1.6-0.1,1.7c0.2,0.2,0.5,0.3,0.8,0.3
		c0.3,0,0.6-0.1,0.8-0.2c0.4-0.3,0.7-0.9,0.2-1.6C15.7,8,15.5,7.8,15.1,7.8z M15.1,9.2c-0.1,0.1-0.3,0.1-0.4,0c0,0,0-0.1,0.2-0.4
		c0,0,0,0,0.1-0.1C15,8.8,15,8.7,15,8.8C15.2,9,15.2,9.2,15.1,9.2z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default KeyIcon;
