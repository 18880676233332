import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import Copyright from 'src/common/components/Copyright';

const Footer = () => (
  <Box
    component="footer"
    sx={{
      mt: 'auto',
      py: 2,
    }}
  >
    <Paper
      sx={{
        py: 2,
        px: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        backdropFilter: 'blur(3px)',
      }}
      elevation={4}
    >
      <Copyright />
    </Paper>
  </Box>
);

export default Footer;
