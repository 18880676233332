import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const RunningIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M15.3,9.2c0.2,0,0.4,0.1,0.5,0.1c0.9,0,1.7-0.5,2.2-1.3c0.4-0.7,0.4-1.6-0.1-2.4c-0.6-1-1.7-1.6-2.6-1.5
		c-0.8,0-1.5,0.5-1.9,1.4c-0.1,0.2,0,0.5,0.2,0.6c0.2,0.1,0.5,0,0.6-0.2c0.3-0.5,0.6-0.8,1.1-0.8c0.6,0,1.4,0.4,1.8,1.1
		c0.2,0.3,0.4,0.9,0.1,1.5c-0.4,0.7-1.1,1-1.8,0.8c-0.8-0.2-1.4-0.8-1.5-1.6c0-0.3-0.3-0.4-0.5-0.4c-0.3,0-0.4,0.3-0.4,0.5
		C13.2,8,14.1,8.9,15.3,9.2z"
      fill="currentColor"
    />
    <path
      d="M18.4,12.7c-0.1-0.2-0.4-0.4-0.6-0.2C16.4,13,16,13,15.8,12.9c-0.2-0.1-0.3-0.5-0.3-0.9
		c-0.1-0.8-0.3-2-1.6-2.4c-0.3-0.1-3.6,0.7-3.6,0.7c-0.4,0.3-0.6,0.7-0.9,1.2c-0.3,0.5-0.5,1-0.9,1c-0.3,0.1-0.4,0.3-0.4,0.6
		s0.3,0.4,0.6,0.4c0.8-0.2,1.2-0.9,1.5-1.5c0.2-0.3,0.3-0.6,0.5-0.8c0.3-0.1,1.2-0.3,2-0.4c-0.7,1.2-1.5,3.1-2.2,5
		c-0.2,0.5-0.4,1-0.4,1.1c-0.2,0.1-0.5,0.1-0.9,0.2c-1,0.2-2.3,0.5-3.1,1c0,0-0.1,0.1-0.1,0.1l-0.5,0.6c-0.2,0.2-0.1,0.5,0.1,0.7
		c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.4-0.2l0.4-0.5c0.7-0.4,1.8-0.7,2.7-0.8c0.5-0.1,0.9-0.2,1.1-0.3c0.1,0,0.1-0.1,0.2-0.1
		c0.1-0.1,0.2-0.3,0.7-1.6c0.1-0.3,0.2-0.6,0.3-0.9c0,0,0,0,0,0c0,0,1.2,0.7,1.7,2c0.3,0.6,0.4,1.4,0.6,2c0.2,1,0.4,1.6,0.9,1.6
		c0.3,0,0.5-0.2,0.5-0.5c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.2-0.2-0.6-0.2-0.9c-0.1-0.7-0.3-1.5-0.6-2.2c-0.7-1.6-2.1-2.4-2.2-2.4
		c0,0-0.1,0-0.1,0c0.5-1.4,1.2-2.9,1.8-3.6c0.4,0.3,0.5,0.7,0.6,1.4c0.1,0.6,0.2,1.3,0.8,1.6c0.2,0.1,0.4,0.2,0.7,0.2
		c0.5,0,1.2-0.2,2-0.6C18.4,13.2,18.5,12.9,18.4,12.7z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default RunningIcon;
