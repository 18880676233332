import { AxiosResponse } from 'axios';

import Resolver from 'src/common/Resolver';
import systemOptionsStore from 'src/stores/systemOptionsStore';
import Competitions from './Competitions';

const CompetitionsResolver = () => {
  const { fetchCompetitions: doFetchCompetitions } = systemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const loadDependencies = () => {
    const deps: Array<Promise<void | AxiosResponse<any, any>>> = [doFetchCompetitions()];

    return Promise.all(deps);
  };

  return <Resolver resolve={loadDependencies} successComponent={Competitions} />;
};

export default CompetitionsResolver;
