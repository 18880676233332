import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WrenchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M18.7,7.3c-0.2-0.4-0.4-0.5-0.6-0.5c-0.4-0.1-0.7,0.2-1,0.5c-0.1,0.1-0.3,0.3-0.5,0.4c0,0-0.1-0.1-0.1-0.1
    c0.1-0.2,0.4-0.5,0.5-0.6c0.2-0.1,0.4-0.4,0.3-0.6c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0.1-0.2,0-0.5-0.2-0.6c-0.7-0.4-1.3-0.4-1.9-0.2
    c-1.1,0.5-1.4,2-1.6,2.8c0,0.1,0,0.2,0,0.2c-0.6,0.6-1.3,1.2-2.1,1.9c-0.8,0.7-1.7,1.5-2.5,2.3c-0.2,0.2-0.2,0.5,0,0.7
    c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.3-0.2c0.7-0.8,1.6-1.5,2.4-2.2c0.8-0.7,1.6-1.4,2.2-2c0.1-0.1,0.2-0.3,0.2-0.6
    c0.1-0.6,0.4-1.9,1-2.2c0.2-0.1,0.4-0.1,0.7,0c-0.4,0.3-0.8,0.8-0.9,1.4c0,0.3,0.1,0.6,0.4,0.8c0.1,0.1,0.2,0.2,0.4,0.3
    C17,8.8,17.5,8.4,17.8,8c0,0,0-0.1,0.1-0.1c0,0.1,0,0.2,0,0.3c-0.2,0.5-0.8,0.9-1.3,1.2c-0.1,0-0.3,0-0.4-0.1
    c-0.3-0.1-0.5-0.1-0.8,0c-0.2,0.1-5.5,5.1-5.7,5.3c-0.1,0.1-0.1,0.2,0,0.4c0.2,1.1,0.1,1.4,0.1,1.5c-0.2,0.5-0.9,0.9-1.4,1.1
    c0-0.1,0.1-0.2,0.1-0.2C8.9,16.8,9.3,16,9,15.4c-0.1-0.2-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-0.5,0-1,0.4-1.5,0.9
    c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1-0.3,0-0.8,0.2-1.1c0.1-0.2,0.6-1,1.4-0.9c0.3,0,0.5-0.1,0.5-0.4c0-0.3-0.1-0.5-0.4-0.5
    c-0.9-0.1-1.8,0.4-2.4,1.4c-0.5,0.9-0.5,2,0,2.5c0.5,0.5,1.1,0,1.6-0.5c0.2-0.2,0.5-0.5,0.7-0.6c0,0,0.1,0,0.1,0.1
    c0,0,0.1,0.1,0.1,0.1c0,0.2-0.3,0.9-0.5,1.2c-0.3,0.6-0.6,1-0.4,1.4c0.1,0.1,0.3,0.3,0.4,0.3c0.1,0,0.2,0,0.4,0c1,0,2.2-0.8,2.6-1.7
    c0.2-0.5,0.1-1.2,0-2c0.8-0.8,4.5-4.2,5.2-4.8c0,0,0.1,0,0.1,0c0.3,0.1,0.7,0.2,1,0c0.5-0.2,1.4-0.8,1.8-1.7
    C18.9,8.2,18.9,7.8,18.7,7.3z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default WrenchIcon;
