import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CyclistIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g>
      <path
        d="M20.9,16.1c-1-1.3-2.8-2.2-4.6-1.5c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.7-0.4-1.4-0.7-2.1c0.3-0.1,0.7-0.4,0.7-0.8
		c0-0.1-0.1-0.5-0.6-0.7c-0.5-0.2-1.4-0.1-1.9,0.3c-1.7-0.6-3.2-1.9-3.8-3.4c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1
		c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0
		c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c-0.7,0.8-1.9,3-1.9,4.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1
		c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.4,0.4,0.9,0.7,1.4,0.9
		c0,0.1,0,0.1,0,0.2c0.1,0.6,0.3,1,0.4,1.5c-0.2,0.1-0.3,0.3-0.5,0.4c-0.9-0.9-2.3-1.1-3.7-0.6c-1.2,0.4-2,1.4-2.4,2.1
		c-0.1,0.2-0.1,0.5,0.1,0.7c0.2,0.1,0.5,0.1,0.7-0.1c0.4-0.6,1-1.3,2-1.7c1-0.4,2-0.2,2.7,0.4c-0.2,0.2-0.3,0.3-0.5,0.5
		c-0.5,0.5-0.9,1-1.4,1.3C5.9,18,5.9,18.3,6,18.5c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1c0.5-0.4,1-0.9,1.5-1.4
		c0.1-0.1,0.2-0.2,0.4-0.4c0.5,1,0.4,2.3-0.3,3.4c-1,1.3-2.3,1.4-3.1,1.1c-1-0.4-1.9-1.4-1.8-3c0-0.3-0.2-0.5-0.4-0.5
		c-0.3,0-0.5,0.2-0.5,0.4c-0.2,1.8,0.8,3.4,2.4,4c0.4,0.2,0.8,0.2,1.3,0.2c1.1,0,2.2-0.6,3-1.6c1-1.4,1.1-3.2,0.3-4.6
		c0.1-0.1,0.2-0.1,0.2-0.2c0.2,0.5,0.4,1,0.7,1.5l0.7,1.5c0,0.1,0.1,0.1,0.2,0.2c0,0.1-0.1,0.2-0.1,0.3l-0.1,0.3c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1,0
		c0,0,0,0,0.1,0c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.4-0.2,0.6-0.2c0.2-0.1,0.5-0.2,0.8-0.3
		c0.2-0.1,0.3-0.4,0.2-0.7s-0.4-0.3-0.7-0.2c0,0-0.1,0-0.2,0.1c-0.2,0-0.5,0-0.7,0c0.1-0.4,0.2-0.9,0.3-1.3c0.1,0,0.1-0.1,0.1-0.1
		c0.6-0.6,1-1.2,1.5-1.7c0.4-0.5,0.9-1.1,1.4-1.6c0,0.1,0.1,0.2,0.1,0.3c-0.6,0.4-1.1,0.8-1.3,1.6c-0.1,0.3,0.1,0.5,0.3,0.6
		c0.3,0.1,0.5-0.1,0.6-0.3c0.1-0.4,0.4-0.7,0.7-0.9c0.1,0.3,0.2,0.7,0.3,1c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0c0.3,0.3,0.5,0.6,0.8,1
		c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.3-0.5,0.1-0.7c-0.1-0.2-0.2-0.3-0.4-0.5
		c-0.3-0.4-0.5-0.7-0.8-1.1c0,0,0,0,0,0c-0.1-0.4-0.2-0.7-0.3-1.1c1.4-0.7,2.8,0.1,3.6,1.1c0.7,0.9,1.1,2.4,0,3.6
		c-1.1,1.2-2.8,1.3-4,0.7c-1.2-0.6-1.7-1.7-1.3-3c0.1-0.3-0.1-0.5-0.3-0.6c-0.3-0.1-0.5,0.1-0.6,0.3c-0.4,1.7,0.3,3.3,1.8,4.1
		c0.6,0.3,1.3,0.5,2.1,0.5c1.1,0,2.2-0.4,3.1-1.4C22,19.5,22.1,17.7,20.9,16.1z M11,17c-0.6-1.3-1-2.2-1.3-3.4
		c0.5,0.2,1,0.3,1.4,0.4c0.1,0,0.3,0.1,0.4,0.1c0.2,1.1,0,2.3-0.3,3.5L11,17z M12.3,13.5C12.3,13.4,12.3,13.4,12.3,13.5
		c-0.1-0.1-0.1-0.1-0.1-0.1c-0.2-0.1-0.4-0.1-0.6-0.2c0.2,0,0.5,0.1,0.7,0.1C12.3,13.4,12.3,13.4,12.3,13.5z M14.9,13.8
		c-0.6,0.6-1.2,1.3-1.8,2c-0.2,0.2-0.4,0.4-0.6,0.7l0-0.1c0.2-1,0.7-2.6,0.7-3.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1
		c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
		c0,0,0,0,0,0l-0.3,0c-1.4-0.1-3.2-0.3-4.5-0.8c0.1-0.8,0.7-2,1.2-2.9c0.9,1.5,2.5,2.7,4.2,3.2c0,0,0,0,0,0c0.2,0.1,0.4,0,0.6-0.2
		c0.1-0.1,0.1-0.1,0.2-0.2c0,0.1,0,0.2,0,0.3C14.6,12.5,14.8,13.1,14.9,13.8C15,13.7,14.9,13.7,14.9,13.8z"
        fill="currentColor"
      />
      <path
        d="M9.4,7c0.1,0.1,0.3,0.1,0.4,0c0,0,0.1,0,0.1,0c0.3,0,0.5,0.1,0.7,0.1c1.4,0,2.1-0.9,2.5-1.4c0.6-0.7,0.6-1.8-0.1-2.8
		c-0.6-0.9-1.6-1.6-2.7-1.3C9.1,2.1,8.1,3.1,7.9,4.2C7.8,4.8,7.9,6,9.4,7z M8.9,4.4C9,3.6,9.8,2.9,10.6,2.6c0.6-0.2,1.3,0.3,1.7,0.9
		c0.3,0.5,0.5,1.2,0.1,1.7c-0.5,0.6-1,1.2-2.3,1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0C9.3,5.8,8.8,5.2,8.9,4.4z"
        fill="currentColor"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M12.7,18.4c-0.1-0.5-0.6-1-1.1-1.1c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-1.9-0.1-3.5,0-5.1,0.2
		c-0.2,0-0.4,0.2-0.4,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.3,0,0.6,0.2,0.8c0.3,0.4,0.7,0.4,1.1,0.5l0.2,0c1.6,0.3,2.6,0.7,3.3,0.9
		c0.3,0.1,0.6,0.2,0.7,0.2c0.1,0,0.3,0,0.4-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0.2-0.1,0.5-0.2,0.7-0.3C12.5,19.5,12.8,19,12.7,18.4z
		 M6.8,18.6c-0.1,0-0.4-0.1-0.5-0.1c0,0,0,0,0-0.1c1.2-0.1,2.3-0.2,3.5-0.2c0,0,0,0-0.1,0.1c-0.2,0.4-0.3,0.7-0.3,1
		C8.9,19,8,18.8,7,18.6L6.8,18.6z M11.4,19.2c-0.4,0.3-0.8,0.3-0.9,0.1c-0.1-0.1,0-0.3,0.1-0.5c0,0,0-0.1,0.1-0.1
		c0,0,0.1-0.1,0.1-0.1c0.2-0.3,0.5-0.3,0.6-0.3c0.2,0,0.4,0.2,0.4,0.3C11.8,18.8,11.6,19.1,11.4,19.2z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);

export default CyclistIcon;
