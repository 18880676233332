import { AxiosResponse } from 'axios';

import Resolver from 'src/common/Resolver';
import systemOptionsStore from 'src/stores/systemOptionsStore';
import Distances from './Distances';

const DistancesResolver = () => {
  const { fetchDistances: doFetchDistances } = systemOptionsStore(
    systemOptionsState => systemOptionsState,
  );

  const loadDependencies = () => {
    const deps: Array<Promise<void | AxiosResponse<any, any>>> = [doFetchDistances()];

    return Promise.all(deps);
  };

  return <Resolver resolve={loadDependencies} successComponent={Distances} />;
};

export default DistancesResolver;
