import * as L from 'leaflet';
import { LatLngBoundsExpression, LatLngExpression } from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import { ReactNode } from 'react';
import { Marker, Polyline, Popup, TileLayer } from 'react-leaflet';

import { MapContainer } from './styled/LeafletMap';

interface Props {
  bounds?: LatLngBoundsExpression;
  center?: LatLngExpression;
  children?: ReactNode;
  height?: string;
  markers?: {
    position: LatLngExpression;
    popupContent?: string;
  }[];
  polyLine?: LatLngExpression[] | LatLngExpression[][];
  scrollWheelZoom?: boolean;
  width?: string;
  zoom?: number;
}

// https://leaflet-extras.github.io/leaflet-providers/preview/index.html

export default function LeafletMap({
  bounds,
  center,
  children,
  height,
  markers,
  polyLine,
  scrollWheelZoom,
  width,
  zoom,
}: Props) {
  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });
  L.Marker.prototype.options.icon = DefaultIcon;
  return (
    <MapContainer
      center={center}
      bounds={bounds}
      height={height}
      scrollWheelZoom={scrollWheelZoom}
      width={width}
      zoom={zoom}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxZoom={19}
      />
      {markers?.map((marker, i) => (
        <Marker key={i} position={marker.position}>
          {marker.popupContent && <Popup>marker.popupContent</Popup>}
        </Marker>
      ))}
      {polyLine?.length && (
        <Polyline pathOptions={{ fillColor: 'red', color: 'blue' }} positions={polyLine} />
      )}
      {children}
    </MapContainer>
  );
}
